import colorVariables from './colorVariables.json';
import { InlineComponentPaddingType, V1Sizes, V1ThemeType } from './emotion';

const sizes: Record<V1Sizes, number> = {
  '0': 0,
  '0.5': 2,
  '1': 4,
  '1.5': 6,
  '2': 8,
  '3': 12,
  '4': 16,
  '5': 20,
  '6': 24,
  '8': 32,
  '10': 40,
  '12': 48,
  '16': 64,
  '20': 80,
  '24': 96,
  '32': 128,
  '40': 160,
  '48': 192,
  '56': 224,
  '64': 256,
  '80': 320,
  '96': 384,
  '120': 480,
  '140': 560,
  '160': 640,
  '180': 720,
  '192': 768,
  '256': 1024,
  '320': 1280,
  '360': 1440,
  '400': 1600,
  '480': 1920,
};

const InlineComponentPadding: Record<InlineComponentPaddingType, string> = {
  xs: `${sizes['0.5']}px ${sizes['1.5']}px`,
  s: `${sizes['0.5']}px ${sizes['2']}px`,
  m: `${sizes['1']}px ${sizes['3']}px`,
  l: `${sizes['1.5']}px ${sizes['4']}px`,
};

const InlineComponentGaps: Record<InlineComponentPaddingType, string> = {
  xs: '0.5',
  s: '0.5',
  m: '1',
  l: '1.5',
};

type NavigationSize = 's' | 'l';
const NavigationBreakpoints: Record<NavigationSize, number> = {
  s: sizes['16'],
  l: sizes['64'],
};

export const themeV1: (theme: 'light' | 'dark') => { v1: V1ThemeType } = (theme) => {
  return {
    v1: {
      font: {
        size: {
          xxs: '9px',
          xs: '12px',
          s: '14px',
          m: '16px',
          l: '20px',
        },
        weight: {
          regular: '400',
          medium: '500',
          bold: '600',
        },
        lineHeight: {
          xxs: '14px',
          xs: '16px',
          s: '20px',
          m: '20px',
          l: '20px',
        },
      },
      mode: theme,
      layers: {
        dropdown: 500,
        flyout: 1000,
        modal: 1300,
        tooltip: 1500,
        popover: 2000,
        toast: 2500,
        fullScreenModal: 3000,
      },
      inlineSizing: {
        gap: InlineComponentGaps,
        padding: InlineComponentPadding,
      },
      viewports: {
        narrow: 768,
        regular: 1400,
        wide: 1401,
      },
      navigationBreakpoints: NavigationBreakpoints,
      motion: {
        duration: {
          instantaneous: '0s',
          xfast: '0.07s',
          fast: '0.1s',
          normal: '0.150s',
          long: '0.250s',
          xlong: '0.4s',
          xxlong: '0.8s',
          xxxlong: '1.6s',
        },
        easing: {
          enter: 'cubic-bezier(0,0,0.35,1)',
          exit: 'cubic-bezier(0.4,0,1,1)',
          transition: 'cubic-bezier(0.4,0.14,0.3,1)',
          emphasize: 'cubic-bezier(0,0,0.2,1.4)',
          linear: 'linear',
        },
      },
      size: sizes,
      radius: {
        '0': '0px',
        '2': '2px',
        '4': '4px',
        '6': '6px',
        '8': '8px',
        '10': '10px',
        '12': '12px',
        '16': '16px',
        '20': '20px',
        '24': '24px',
        '9999': '9999px',
      },
      shadow: {
        xs: `0px 2px 4px 0px ${colorVariables.color[theme]['core-background-shadow-dark']}, 0px 1px 4px 0px ${colorVariables.color[theme]['core-background-shadow-dark']}`,
        s: `0px 4px 4px 0px ${colorVariables.color[theme]['core-background-shadow-dark']}, 0px 4px 8px 0px ${colorVariables.color[theme]['core-background-shadow-dark']}`,
        m: `0px 4px 4px 0px ${colorVariables.color[theme]['core-background-shadow-dark']}, 0px 8px 14px -2px ${colorVariables.color[theme]['core-background-shadow-dark']}`,
        l: `0px 4px 4px 0px ${colorVariables.color[theme]['core-background-shadow-dark']}, 0px 16px 24px -4px ${colorVariables.color[theme]['core-background-shadow-dark']}`,
        xl: `0px 4px 4px 0px ${colorVariables.color[theme]['core-background-shadow-dark']}, 0px 16px 44px -8px ${colorVariables.color[theme]['core-background-shadow-dark']}`,
        flat: `0px 0px 0.8px 0px ${colorVariables.color[theme]['core-background-shadow-dark']}, 0px 0px 2px 0px #0D0E0F28, 0px 0px 5px 0px #0D0E0F28, 0px 0px 17px 0px ${colorVariables.color[theme]['core-background-shadow-dark']}`,
      },
      colors: {
        text: {
          title: colorVariables.color[theme]['base-gray-950'],
          text: colorVariables.color[theme]['base-gray-900'],
          subduedText: colorVariables.color[theme]['base-gray-800'],
          disabledText: colorVariables.color[theme]['base-gray-600'],
          shortcutTextPrimary: colorVariables.color[theme]['base-gray-25'],
          invertedText: colorVariables.color[theme]['base-absolute-0'],
          disabledInvertedText: colorVariables.color[theme]['base-alpha-500'],
          successText: colorVariables.color[theme]['base-green-900'],
          warningText: colorVariables.color[theme]['base-yellow-900'],
          accentText: colorVariables.color[theme]['base-purple-900'],
          dangerText: colorVariables.color[theme]['base-red-900'],
          dangerDisabledText: colorVariables.color[theme]['base-red-700'],
          link: colorVariables.color[theme]['base-absolute-1000'],
        },
        background: {
          empty: colorVariables.color[theme]['base-absolute-0'],
          default: colorVariables.color[theme]['base-gray-25'],
          hover: colorVariables.color[theme]['base-gray-50'],
          disabled: colorVariables.color[theme]['base-gray-100'],
          active: colorVariables.color[theme]['base-gray-200'],
          activeFocus: colorVariables.color[theme]['base-gray-300'],
          success: colorVariables.color[theme]['base-green-100'],
          accent: colorVariables.color[theme]['base-purple-25'],
          warning: colorVariables.color[theme]['base-yellow-50'],
          dangerSecondary: colorVariables.color[theme]['base-red-25'],
          dangerSecondaryActive: colorVariables.color[theme]['base-red-200'],
          dangerPrimary: colorVariables.color[theme]['base-red-800'],
          dangerPrimaryHover: colorVariables.color[theme]['base-red-900'],
          secondary: colorVariables.color[theme]['base-gray-900'],
          primary: colorVariables.color[theme]['base-absolute-1000'],
          mask: colorVariables.color[theme]['base-alpha-inverse-600'],
          shadowDark: colorVariables.color[theme]['base-alpha-inverse-25'],
          shadowLight: colorVariables.color[theme]['base-alpha-inverse-200'],
          transparent: colorVariables.color[theme]['base-absolute-transparent'],
        },
        icon: {
          primary: colorVariables.color[theme]['base-gray-950'],
          secondary: colorVariables.color[theme]['base-gray-800'],
          disabled: colorVariables.color[theme]['base-gray-600'],
          warning: colorVariables.color[theme]['base-yellow-900'],
          danger: colorVariables.color[theme]['base-red-900'],
          success: colorVariables.color[theme]['base-green-800'],
          accent: colorVariables.color[theme]['base-blue-800'],
          blueMist: colorVariables.color[theme]['base-blue-mist-800'],
          rose: colorVariables.color[theme]['base-rose-800'],
          violet: colorVariables.color[theme]['base-violet-800'],
          pink: colorVariables.color[theme]['base-pink-800'],
          orange: colorVariables.color[theme]['base-orange-800'],
        },
        border: {
          plain: colorVariables.color[theme]['base-absolute-0'],
          default: colorVariables.color[theme]['base-gray-200'],
          focus: colorVariables.color[theme]['base-gray-400'],
          warning: colorVariables.color[theme]['base-yellow-100'],
          danger: colorVariables.color[theme]['base-red-50'],
          dangerFocus: colorVariables.color[theme]['base-red-800'],
          secondary: colorVariables.color[theme]['base-gray-900'],
          primary: colorVariables.color[theme]['base-absolute-1000'],
        },
        viz: {
          '1-blueMist': {
            default: colorVariables.color[theme]['base-blue-mist-500'],
            text: colorVariables.color[theme]['base-blue-mist-900'],
            behindText: colorVariables.color[theme]['base-blue-mist-100'],
          },
          '2-rose': {
            default: colorVariables.color[theme]['base-rose-500'],
            text: colorVariables.color[theme]['base-rose-900'],
            behindText: colorVariables.color[theme]['base-rose-100'],
          },
          '3-violet': {
            default: colorVariables.color[theme]['base-violet-500'],
            text: colorVariables.color[theme]['base-violet-900'],
            behindText: colorVariables.color[theme]['base-violet-100'],
          },
          '4-pink': {
            default: colorVariables.color[theme]['base-pink-500'],
            text: colorVariables.color[theme]['base-pink-900'],
            behindText: colorVariables.color[theme]['base-pink-100'],
          },
          '5-yellow': {
            default: colorVariables.color[theme]['base-yellow-500'],
            text: colorVariables.color[theme]['base-yellow-900'],
            behindText: colorVariables.color[theme]['base-yellow-100'],
          },
          '6-orange': {
            default: colorVariables.color[theme]['base-orange-500'],
            text: colorVariables.color[theme]['base-orange-900'],
            behindText: colorVariables.color[theme]['base-orange-100'],
          },
          '7-blue': {
            default: colorVariables.color[theme]['base-blue-500'],
            text: colorVariables.color[theme]['base-blue-900'],
            behindText: colorVariables.color[theme]['base-blue-100'],
          },
          '8-red': {
            default: colorVariables.color[theme]['base-red-500'],
            text: colorVariables.color[theme]['base-red-900'],
            behindText: colorVariables.color[theme]['base-red-100'],
          },
          '9-purple': {
            default: colorVariables.color[theme]['base-purple-500'],
            text: colorVariables.color[theme]['base-purple-900'],
            behindText: colorVariables.color[theme]['base-purple-100'],
          },
          '10-green': {
            default: colorVariables.color[theme]['base-green-500'],
            text: colorVariables.color[theme]['base-green-900'],
            behindText: colorVariables.color[theme]['base-green-100'],
          },
        },
      },
    },
  };
};
