import { FilterOptionType } from '@tecton/CategoricalSelector';

import moment, { Moment } from 'moment-timezone';
import { NavigateOptions } from 'react-router-dom';
import { GetJobsRequest, SortDirection } from '../../api/gql/graphql';
import { MaterializationStatusState } from '../../types/tecton_proto/data/materialization_status';
import { TectonManagedStage } from '../../types/tecton_proto/materialization/job_metadata';
import { CountRange, DateTimeRange, DurationRange } from '../../types/tecton_proto/metadataservice/metadata_service';
import {
  DurationQuery,
  JobStatusInput,
  JobStatusValue,
  JobTaskTypeInput,
  JobsDefaultProp,
  JobsDurationOptionTypeValue,
  JobsFilterStateProps,
  JobsSelectorProps,
  JobsTaskTypeValue,
  MomentDateTimeKeys,
  StageEntryInterface,
  StageTypes,
  TaskTypeForDisplayOmitted,
  TaskTypeOmitted,
  UpdateDefaultsProps,
} from './types';

// Utility function to deep freeze objects and arrays
export const deepFreeze = <T>(obj: T): Readonly<T> => {
  if (Array.isArray(obj)) {
    return Object.freeze((obj as unknown[]).map((el) => deepFreeze(el))) as unknown as Readonly<T>;
  } else if (typeof obj === 'object' && obj !== null) {
    Object.getOwnPropertyNames(obj).forEach((prop) => {
      const value = (obj as any)[prop];
      if (typeof value === 'object' && value !== null && !Object.isFrozen(value)) {
        (obj as any)[prop] = deepFreeze(value);
      }
    });
    return Object.freeze(obj);
  }
  return obj;
};

export const resetFilterDefaults = (state?: JobsFilterStateProps, isFeatureView?: boolean): JobsFilterStateProps => {
  // FV MATERIALIZATION TAB DEFAULTS
  const saved_perPage = state?.queryState.pagination?.perPage;
  const saved_featureViews = state?.queryState?.featureViews;
  const saved_workspaces = state?.queryState?.workspaces;

  const workspaces = state?.selectorState?.workspaces.length ? state?.selectorState?.workspaces : [];
  workspaces.forEach((w) => delete w.checked);
  const featureViews = state?.selectorState?.featureViews.length ? state?.selectorState?.featureViews : [];
  featureViews.forEach((fv) => delete fv.checked);

  return {
    selectorState: {
      workspaces,
      featureViews,
      taskType: createMutableCopy(immutableTaskTypes),
      taskTypeForDisplays: createMutableCopy(immutableTaskTypeForDisplays),
      manuallyTriggered: createMutableCopy(immutableTriggerTypes),
      statuses: createMutableCopy(immutableJobStatusObjects),
      lastTaskStateChange: {
        start: defaultStartDate,
        end: defaultEndDate,
      },
      featureViewStart: {
        start: defaultStartDate,
        end: defaultEndDate,
      },
      featureViewEnd: {
        start: defaultStartDate,
        end: defaultEndDate,
      },
      numAttempts: [minNumOfAttempts, maxNumOfAttempts],
      duration: createMutableCopy(durationOptions),
      writesOnline: createMutableCopy(immutableEnabledTypes),
      writesOffline: createMutableCopy(immutableEnabledTypes),
    },
    queryState: {
      workspaces: isFeatureView ? saved_workspaces : [],
      featureViews: isFeatureView ? saved_featureViews : [],
      statuses: [],
      includeUpdateMaterializationFlags: true,
      taskType: [],
      taskTypeForDisplays: [],
      lastTaskStateChange: {
        start: defaultStartDateStr,
        end: defaultEndDateStr,
      },
      pagination: {
        perPage: isFeatureView ? saved_perPage : 50,
        sortDirection: 'SORT_DESC' as SortDirection,
        pageToken: '',
        sortKey: 'last_task_state_change',
      },
    },
  };
};

export const dayLevelDataFormat = 'YYYY-MM-DD';
export const hourLevelDateFormat = 'YYYY-MM-DD HH:mm';
export const MomentDateStringFormat = 'YYYY-MM-DDTHH:mm:ssZ';
export const minNumOfAttempts = 1;
export const maxNumOfAttempts = 10;
export const maxDuration = 600;
export let defaultEndDate = moment().utc();
export const defaultStartDate = moment().utc().subtract(28, 'd');
export let defaultEndDateStr = defaultEndDate.format(MomentDateStringFormat);
export const defaultStartDateStr = defaultStartDate.format(MomentDateStringFormat);
export const updateDefaultEndDate = (endDate: Moment) => {
  defaultEndDate = endDate.utc();
  defaultEndDateStr = defaultEndDate.format(MomentDateStringFormat);
};
export const defaultDuration: [number, number] = [1, maxDuration];
export const MomentToString = (date: Moment) => date.format(MomentDateStringFormat);
const FilterTypeMap = {
  List: 'list',
  EmptyList: 'EmptyList',
  DateRange: 'DateRange',
  CountRange: 'CountRange',
  String: 'string',
  Number: 'number',
  Pagination: 'pagination',
  Sort: 'sort',
};

export const QueryParamTypeMap = {
  workspaces: { name: 'workspaces', type: FilterTypeMap.List },
  featureViews: { name: 'featureViews', type: FilterTypeMap.List },
  manuallyTriggered: { name: 'manuallyTriggered', type: FilterTypeMap.List },
  statuses: { name: 'statuses', type: FilterTypeMap.List },
  taskType: { name: 'taskType', type: FilterTypeMap.List },
  taskTypeForDisplays: { name: 'taskTypeForDisplays', type: FilterTypeMap.List },
  numAttempts: { name: 'numAttempts', type: FilterTypeMap.CountRange },
  lastTaskStateChange: { name: 'lastTaskStateChange', type: FilterTypeMap.DateRange },
  featureViewStart: { name: 'featureViewStart', type: FilterTypeMap.DateRange },
  featureViewEnd: { name: 'featureViewEnd', type: FilterTypeMap.DateRange },
  writesOnline: { name: 'writesOnline', type: FilterTypeMap.List },
  writesOffline: { name: 'writesOnline', type: FilterTypeMap.List },
  duration: { name: 'duration', type: FilterTypeMap.List },
  pagination: { name: 'pagination', type: FilterTypeMap.Pagination },
  sortKey: { name: 'sort', type: FilterTypeMap.Sort },
  sortDirection: { name: 'direction', type: FilterTypeMap.Sort },
};
export type QueryParamTypeMapProp = keyof typeof QueryParamTypeMap;

export const getJobStatus = (
  input: JobStatusInput,
  key?: keyof JobStatusValue
): JobStatusValue | undefined | string => {
  let status = undefined;
  if (input && Object.values(MaterializationStatusState).includes(input as MaterializationStatusState)) {
    status = jobStatusMap[input as MaterializationStatusState];
  } else if (input) {
    status = Object.values(jobStatusMap).find((value) => value.label === input);
  } else {
    status = jobStatusMap[MaterializationStatusState.MATERIALIZATION_STATUS_STATE_UNSPECIFIED];
  }

  if (key) {
    return status?.[key as keyof JobStatusValue];
  }

  return status;
};

export const getTaskType = (
  input: JobTaskTypeInput,
  key?: keyof JobsTaskTypeValue
): JobsTaskTypeValue | undefined | string => {
  let taskType = undefined;
  if (input && Object.values(TaskTypeOmitted).includes(input as unknown as TaskTypeOmitted)) {
    taskType = jobsTaskTypeMap[input as unknown as TaskTypeOmitted];
  } else if (input) {
    taskType = Object.values(jobStatusMap).find((value) => value.label === input);
  } else {
    taskType = jobsTaskTypeMap[TaskTypeOmitted.BATCH];
  }

  if (key) {
    return taskType?.[key as keyof JobsTaskTypeValue];
  }

  return taskType;
};

export const jobStatusMap: { [key in MaterializationStatusState]: JobStatusValue } = {
  [MaterializationStatusState.MATERIALIZATION_STATUS_STATE_SCHEDULED]: {
    label: `scheduled`,
    color: '#343741',
    backgroundColor: '#E0E5EE',
    value: MaterializationStatusState.MATERIALIZATION_STATUS_STATE_SCHEDULED.toString(),
  },
  [MaterializationStatusState.MATERIALIZATION_STATUS_STATE_PENDING]: {
    label: `pending`,
    color: '#1E277C',
    backgroundColor: '#CBCAFF',
    value: MaterializationStatusState.MATERIALIZATION_STATUS_STATE_PENDING.toString(),
  },
  [MaterializationStatusState.MATERIALIZATION_STATUS_STATE_RUNNING]: {
    label: `running`,
    color: '#063F60',
    backgroundColor: '#A2DDFF',
    value: MaterializationStatusState.MATERIALIZATION_STATUS_STATE_RUNNING.toString(),
  },
  [MaterializationStatusState.MATERIALIZATION_STATUS_STATE_SUCCESS]: {
    label: `success`,
    color: '#1D4F4F',
    backgroundColor: '#BBF3E1',
    value: MaterializationStatusState.MATERIALIZATION_STATUS_STATE_SUCCESS.toString(),
  },
  [MaterializationStatusState.MATERIALIZATION_STATUS_STATE_MANUAL_CANCELLATION_REQUESTED]: {
    label: `cancelling`,
    color: '#430162',
    backgroundColor: '#FCD8FF',
    value: MaterializationStatusState.MATERIALIZATION_STATUS_STATE_MANUAL_CANCELLATION_REQUESTED.toString(),
  },
  [MaterializationStatusState.MATERIALIZATION_STATUS_STATE_MANUALLY_CANCELLED]: {
    label: `cancelled`,
    color: '#662408',
    backgroundColor: '#FFDDAA',
    value: MaterializationStatusState.MATERIALIZATION_STATUS_STATE_MANUALLY_CANCELLED.toString(),
  },
  [MaterializationStatusState.MATERIALIZATION_STATUS_STATE_ERROR]: {
    label: `error`,
    color: '#7D0D28',
    backgroundColor: '#FFBDC9',
    value: MaterializationStatusState.MATERIALIZATION_STATUS_STATE_ERROR.toString(),
  },
  [MaterializationStatusState.MATERIALIZATION_STATUS_STATE_DRAINED]: {
    label: `drained`,
    color: '#0C2B99',
    backgroundColor: '#D1D9FF',
    value: MaterializationStatusState.MATERIALIZATION_STATUS_STATE_DRAINED.toString(),
  },
  [MaterializationStatusState.MATERIALIZATION_STATUS_STATE_UNSPECIFIED]: {
    label: `unknown`,
    color: '#073171',
    backgroundColor: '#BADEFF',
    value: MaterializationStatusState.MATERIALIZATION_STATUS_STATE_UNSPECIFIED.toString(),
  },
};

export const jobsTaskTypeMap: { [key in TaskTypeOmitted]: JobsTaskTypeValue } = {
  [TaskTypeOmitted.STREAMING]: {
    label: `Stream`,
    color: '#11031D',
    backgroundColor: '#EFDAFF',
    value: TaskTypeOmitted.STREAMING.toString(),
  },
  [TaskTypeOmitted.BATCH]: {
    label: `Batch`,
    color: '#001B43',
    backgroundColor: '#BADEFF',
    value: TaskTypeOmitted.BATCH.toString(),
  },
  [TaskTypeOmitted.DELETION]: {
    label: `Deletion`,
    color: '#4B1802',
    backgroundColor: '#FFCABE',
    value: TaskTypeOmitted.DELETION.toString(),
  },
  [TaskTypeOmitted.INGEST]: {
    label: `Ingest`,
    color: '#012E34',
    backgroundColor: '#C4F2F9',
    value: TaskTypeOmitted.INGEST.toString(),
  },
  [TaskTypeOmitted.FEATURE_EXPORT]: {
    label: `Feature Publish`,
    color: '#0D1509',
    backgroundColor: '#D0F8BD',
    value: TaskTypeOmitted.FEATURE_EXPORT.toString(),
  },
  [TaskTypeOmitted.PLAN_INTEGRATION_TEST_BATCH]: {
    label: `Integration Test (Batch)`,
    color: '#2A0A5E',
    backgroundColor: '#E0CFFF',
    value: TaskTypeOmitted.PLAN_INTEGRATION_TEST_BATCH.toString(),
  },
  [TaskTypeOmitted.PLAN_INTEGRATION_TEST_STREAM]: {
    label: `Integration Test (Batch)`,
    color: '#3D2806',
    backgroundColor: '#FFEDCC',
    value: TaskTypeOmitted.PLAN_INTEGRATION_TEST_STREAM.toString(),
  },
  [TaskTypeOmitted.DATASET_GENERATION]: {
    label: `Dataset Generation`,
    color: '#0D1509',
    backgroundColor: '#D0F8BD',
    value: TaskTypeOmitted.DATASET_GENERATION.toString(),
  },
};

export const jobsTaskTypeForDisplaysMap: { [key in TaskTypeForDisplayOmitted]: JobsTaskTypeValue } = {
  [TaskTypeForDisplayOmitted.STREAMING_JOB]: {
    label: `Materialization (stream)`,
    color: '#11031D',
    backgroundColor: '#EFDAFF',
    value: TaskTypeForDisplayOmitted.STREAMING_JOB.toString(),
  },
  [TaskTypeForDisplayOmitted.BATCH_JOB]: {
    label: `Materialization (batch)`,
    color: '#001B43',
    backgroundColor: '#BADEFF',
    value: TaskTypeForDisplayOmitted.BATCH_JOB.toString(),
  },
  [TaskTypeForDisplayOmitted.DELETION_JOB]: {
    label: `Deletion`,
    color: '#4B1802',
    backgroundColor: '#FFCABE',
    value: TaskTypeForDisplayOmitted.DELETION_JOB.toString(),
  },
  [TaskTypeForDisplayOmitted.DELTA_MAINTENANCE_JOB]: {
    label: `Delta Maintenance`,
    color: '#D1D9FF',
    backgroundColor: '#D1D9FF',
    value: TaskTypeForDisplayOmitted.DELTA_MAINTENANCE_JOB.toString(),
  },
  [TaskTypeForDisplayOmitted.INGEST_JOB]: {
    label: `Ingest`,
    color: '#012E34',
    backgroundColor: '#C4F2F9',
    value: TaskTypeForDisplayOmitted.INGEST_JOB.toString(),
  },
  [TaskTypeForDisplayOmitted.FEATURE_EXPORT_JOB]: {
    label: `Feature Publish`,
    color: '#0D1509',
    backgroundColor: '#D0F8BD',
    value: TaskTypeForDisplayOmitted.FEATURE_EXPORT_JOB.toString(),
  },
  [TaskTypeForDisplayOmitted.DATASET_GENERATION_JOB]: {
    label: `Dataset Generation`,
    color: '#FFE9B0',
    backgroundColor: '#FFE9B0',
    value: TaskTypeForDisplayOmitted.DATASET_GENERATION_JOB.toString(),
  },
  [TaskTypeForDisplayOmitted.PLAN_INTEGRATION_TEST_BATCH_JOB]: {
    label: `Integration Test (batch)`,
    color: '#2A0A5E',
    backgroundColor: '#E0CFFF',
    value: TaskTypeForDisplayOmitted.PLAN_INTEGRATION_TEST_BATCH_JOB.toString(),
  },
  [TaskTypeForDisplayOmitted.PLAN_INTEGRATION_TEST_STREAM_JOB]: {
    label: `Integration Test (stream)`,
    color: '#3D2806',
    backgroundColor: '#FFEDCC',
    value: TaskTypeForDisplayOmitted.PLAN_INTEGRATION_TEST_STREAM_JOB.toString(),
  },
  [TaskTypeForDisplayOmitted.COMPACTION_JOB]: {
    label: `Compaction`,
    color: '#FFE9B0',
    backgroundColor: '#FFE9B0',
    value: TaskTypeForDisplayOmitted.COMPACTION_JOB.toString(),
  },
};

const jobsTableColumnKeys = [
  'latestStatus',
  'workspace',
  'featureView',
  'fvType',
  'fvTaskTypeForDisplay',
  'featureStartTime',
  'featureEndTime',
  'numAttempts',
  'lastTaskStateChange',
  'latest_duration',
  'manuallyTriggered',
  'writesOnline',
  'writesOffline',
];

export type JobsTableColumnKeys = typeof jobsTableColumnKeys[number];

export const jobsTableColumnLabels: Record<JobsTableColumnKeys, string> = {
  latestStatus: 'Status',
  workspace: 'Workspace',
  fvType: 'Type',
  fvTaskTypeForDisplay: 'Type',
  featureView: 'Feature View',
  featureStartTime: 'Feature Time Range',
  numAttempts: 'Attempts',
  lastTaskStateChange: 'Last Update',
  latestDuration: 'Latest Duration',
  manuallyTriggered: 'Trigger Type',
  writesOnline: 'Online',
  writesOffline: 'Offline',
};

// returns label from state => jobStatusToLabelMap.get('MATERIALIZATION_TASK_STATUS_DRAINED') = 'drained'
export const jobStatusToLabelMap = Object.keys(jobStatusMap).reduce((map, key) => {
  const status = jobStatusMap[key as keyof typeof jobStatusMap];
  return map.set(status.value, status.label);
}, new Map());
export const jobStatuses = Object.keys(jobStatusMap);
export const JobsTaskTypes = Object.keys(jobsTaskTypeMap);
export const JobsTaskTypeForDisplay = Object.keys(jobsTaskTypeForDisplaysMap);

//Immutable Categorical Arrays
export const immutableTriggerTypes = deepFreeze([{ label: 'automatic' }, { label: 'manual' }]);
export const immutableEnabledTypes = deepFreeze([{ label: 'enabled' }, { label: 'disabled' }]);
export const durationOptions: JobsDurationOptionTypeValue[] = [
  { label: '0-30min', value: { start: '0s', end: '1800s' } },
  { label: '30-60min', value: { start: '1800s', end: '3600s' } },
  { label: '1-6h', value: { start: '3600s', end: '21600s' } },
  { label: '6-24hr', value: { start: '21600s', end: '86400s' } },
  { label: '1-7days', value: { start: '86400s', end: '604800s' } },
  { label: '7+days', value: { start: '604800s' } },
];
export const immutableDurationOptionTypes = deepFreeze(durationOptions);

export const immutableJobStatusObjects: ReadonlyArray<Readonly<JobStatusValue>> = deepFreeze(
  jobStatuses.map((type) => {
    return jobStatusMap[type as keyof typeof jobStatusMap];
  })
);
export const immutableTaskTypes: ReadonlyArray<Readonly<JobsTaskTypeValue>> = deepFreeze(
  JobsTaskTypes.map((type) => {
    return jobsTaskTypeMap[type as unknown as keyof typeof jobsTaskTypeMap];
  })
);

export const immutableTaskTypeForDisplays: ReadonlyArray<Readonly<JobsTaskTypeValue>> = deepFreeze(
  JobsTaskTypeForDisplay.map((type) => {
    return jobsTaskTypeForDisplaysMap[type as unknown as keyof typeof jobsTaskTypeForDisplaysMap];
  })
);

// Function to create a mutable copy of the immutable array
export function createMutableCopy<T>(immutableArray: ReadonlyArray<Readonly<T>>): T[] {
  return immutableArray.map((obj) => ({ ...obj }));
}
export type JobStatusProp = keyof typeof jobStatusMap;
export type JobsTaskTypesType = typeof jobStatuses[number];
export type TableSortDirectionType = 'asc' | 'desc' | undefined;
export type TableSortType = { sort: { direction?: TableSortDirectionType; field?: string } };

interface JobsStateFromParamsProps {
  searchParams: URLSearchParams;
  setSearchParams: (searchParams: URLSearchParams, navigateOpts?: NavigateOptions | undefined) => void;
  setJobsFilterState: (prop: JobsFilterStateProps) => void;
  jobsFilterState: JobsFilterStateProps;
  isFeatureView?: boolean;
}
export type QueryOrSelectorStateKey = JobsDefaultProp;
export type CategoricalSelectorQueryType = string | string[] | boolean | DurationQuery | undefined;
export type FilterPayloadSelectorType = { start: Moment; end: Moment } | FilterOptionType[] | [number, number];
export type FilterPayloadQueryType =
  | { start: string; end: string }
  | CountRange
  | DurationRange
  | DurationQuery
  | CategoricalSelectorQueryType;
export interface FilterPayloadReturnProps {
  selector: FilterPayloadSelectorType;
  query: FilterPayloadQueryType;
  params: string;
}

export const durationFromSeconds = (s: number) => {
  const seconds = s % 60;
  const m = (s - seconds) / 60;
  const minutes = m % 60;
  const h = (m - minutes) / 60;
  const hours = h;

  let result = '';
  if (seconds > 0) {
    result = `${Math.round(seconds)}s`;
  }
  if (minutes > 0) {
    result = `${minutes}m ` + result;
  }

  if (hours > 0) {
    result = `${hours}h ` + result;
  }

  return result;
};

export const DateWithInvalidFallback = (date: string, timezone: string) => {
  if (date) {
    return moment(date).clone().tz(timezone).format(hourLevelDateFormat);
  } else {
    return 'n/a';
  }
};

// FILTER COUNTS AND ACTIVE
export const activeCountRange = (attemptRange: [number, number], start?: number, end?: number) => {
  start = start || 1;
  end = end || 10;
  const lowerBoundUnchanged = attemptRange[0] === start;
  const upperBoundUnchanged = attemptRange[1] === end;
  return !lowerBoundUnchanged || !upperBoundUnchanged;
};

export const numberOfActiveItems = (items: FilterOptionType[]): number => {
  if (!items) return 0;

  const count = items.reduce((memo, current) => {
    return current.checked === 'on' ? memo + 1 : memo;
  }, 0);

  return count;
};

export const activeKeys = (items: FilterOptionType[]): string[] =>
  items
    .filter((s) => {
      return s.checked === 'on';
    })
    .map((s) => {
      return s.label;
    });

export const activeDateFilter = (
  filterDates: { start: Moment; end: Moment } | DateTimeRange | string,
  defaultDates: { start: string; end: string } = { start: defaultStartDateStr, end: defaultEndDateStr },
  diffUnit: 'days' | 'hours' | 'minutes' | 'seconds' = 'hours',
  timezone?: string
) => {
  if (typeof filterDates === 'string') {
    const [start, end] = filterDates.split(',');
    filterDates = { start, end };
  }
  const tz = timezone ?? 'UTC';
  return !!(
    moment(filterDates.start).tz(tz).diff(moment(defaultDates.start), diffUnit) ||
    moment(filterDates.end).tz(tz).diff(moment(defaultDates.end), diffUnit)
  );
};

// Helpers
export const stringToArray = (list: string): string[] => {
  return decodeURI(list).split(',');
};

export const arrayToString = (list: string[]): string => {
  return list.reduce((out, next, i) => {
    const divider = i > 0 ? ',' : '';
    return out + divider + next;
  }, '');
};

export const arrayToFilterOptions = (options: string[], checked?: string[]): FilterOptionType[] => {
  return options.map((o) => {
    const ret: FilterOptionType = { label: o };
    if (checked && checked.indexOf(o) > -1) {
      ret.checked = 'on';
    }
    return ret;
  });
};

export const updateSelected = (options: FilterOptionType[], checked: string[]): FilterOptionType[] => {
  return options.map((o) => {
    if (checked.indexOf(o.label) > -1) {
      o.checked = 'on';
    }
    return o;
  });
};

// PAYLOAD TRANSFORMS
export const dateRangeFilterPayloads = (start: Moment | string, end: Moment | string): FilterPayloadReturnProps => {
  const Start = moment(start).utc();
  const End = moment(end).utc();
  const start_str = Start.format(MomentDateStringFormat);
  const end_str = End.format(MomentDateStringFormat);
  const params = arrayToString([start_str, end_str]);
  return { selector: { start: Start, end: End }, query: { start: start_str, end: end_str }, params };
};

export const categoricalSelectorPayloads = (
  prop: JobsDefaultProp,
  list: FilterOptionType[],
  useMap?: boolean
): FilterPayloadReturnProps => {
  let value: CategoricalSelectorQueryType = undefined;
  let query: CategoricalSelectorQueryType = list
    .filter((i) => i.checked === 'on')
    .map((i) => {
      const item = i.label;
      if (i.value) value = i.value;
      if (useMap) {
        return value;
      }
      return item;
    }) as string[];
  let params = arrayToString(query);

  switch (prop) {
    case 'taskType': {
      params = arrayToString(list.filter((i) => i.checked === 'on').map((i) => i.label));
      break;
    }
    case 'taskTypeForDisplays': {
      params = arrayToString(list.filter((i) => i.checked === 'on').map((i) => i.label));
      break;
    }
    case 'statuses': {
      params = arrayToString(list.filter((i) => i.checked === 'on').map((i) => i.label));
      break;
    }
    case 'duration': {
      query = value;
      break;
    }
    case 'manuallyTriggered': {
      query = Array.isArray(query) && query.length === 1 ? query[0] === 'manual' : undefined;
      break;
    }
    case 'writesOnline': {
      query = Array.isArray(query) && query.length === 1 ? query[0] === 'enabled' : undefined;
      break;
    }
    case 'writesOffline': {
      query = Array.isArray(query) && query.length === 1 ? query[0] === 'enabled' : undefined;
      break;
    }
    default: {
      params = arrayToString(list.filter((i) => i.checked === 'on').map((i) => i.label));
      break;
    }
  }

  return { selector: list, query, params };
};

export const numericRangePayloads = (range: [number, number]): FilterPayloadReturnProps => {
  const rangeObj = { start: range[0], end: range[1] };
  const params = arrayToString([range[0] + '', range[1] + '']);
  return { selector: range, query: rangeObj, params };
};

const updateDefaultsProp = ({ defaults, param, selector, query }: UpdateDefaultsProps) => {
  defaults.queryState[param as keyof GetJobsRequest] = query as any;
  defaults.selectorState[param as keyof JobsSelectorProps] = selector as any;
};

export const jobsStateFromParams = ({
  searchParams,
  setSearchParams,
  jobsFilterState,
  setJobsFilterState,
  isFeatureView = false,
}: JobsStateFromParamsProps): void => {
  // SET STATE FROM DEFAULTS
  const defaults = resetFilterDefaults(jobsFilterState, isFeatureView);
  const queries = searchParams.entries();
  let q = queries.next();

  while (!q.done) {
    const [param, val] = q.value;

    // Check that param is of type
    if (!QueryParamTypeMap[param as QueryParamTypeMapProp]) {
      searchParams.delete(param);
      return;
    }

    const type = QueryParamTypeMap?.[param as QueryParamTypeMapProp].type ?? null;

    switch (type) {
      case FilterTypeMap.List: {
        //NEED TO MAKE SURE THAT THE LIST IS AVAILABLE OTHERWISE WE WILL UPDATE AFTER OPTIONS LOAD;
        const ListState = jobsFilterState.selectorState[param as keyof JobsSelectorProps] as FilterOptionType[];
        if (ListState.length) {
          const selected = val.split(',');
          ListState.forEach((i) => delete i.checked);
          const useMap = ['statuses', 'taskType', 'taskTypeForDisplays'].includes(param);

          const { selector, query } = categoricalSelectorPayloads(
            param as JobsDefaultProp,
            updateSelected(ListState, selected),
            useMap
          );

          updateDefaultsProp({ defaults, param, selector, query });
        }
        break;
      }
      case FilterTypeMap.EmptyList: {
        const { selector, query } = categoricalSelectorPayloads(param as JobsDefaultProp, [
          { label: val, checked: 'on', disabled: true },
        ]);

        updateDefaultsProp({ defaults, param, selector, query });
        break;
      }
      case FilterTypeMap.CountRange: {
        const stringRange = val.split(',');
        const range: [number, number] = [parseInt(stringRange[0]), parseInt(stringRange[1])];
        if (val && activeCountRange(range)) {
          const { selector, query } = numericRangePayloads(range);
          const queryObj: CountRange = query as CountRange;
          if (queryObj?.end && queryObj.end === maxNumOfAttempts) delete queryObj.end;

          updateDefaultsProp({ defaults, param, selector, query });
        }
        break;
      }
      case FilterTypeMap.DateRange:
        if (val && activeDateFilter(val, undefined, 'minutes')) {
          const [first, last] = val.split(',');
          let start = moment(first);
          let end = moment(last);
          start = start ?? defaults?.selectorState?.[param as MomentDateTimeKeys]?.start;
          end = end ?? defaults?.selectorState?.[param as MomentDateTimeKeys]?.end;
          const { selector, query } = dateRangeFilterPayloads(start, end);
          updateDefaultsProp({ defaults, param, selector, query });
        }
        break;
      case FilterTypeMap.Sort:
        if (param === 'sortKey' && defaults?.queryState?.pagination) defaults.queryState.pagination.sortKey = val;
        if (param === 'sortDirection' && defaults?.queryState?.pagination) {
          defaults.queryState.pagination.sortDirection = val as SortDirection;
        }
        break;
      default:
        break;
    }
    q = queries.next();
  }

  // removes any invalid params
  setSearchParams(searchParams, { replace: true });

  // resets filter/query states
  setJobsFilterState(defaults);
};

export const stageTypesToNiceNames: Record<StageTypes, string> = {
  SNOWFLAKE: 'Snowflake',
  PYTHON: 'Python',
  AGGREGATE: 'Tecton Aggregations',
  OFFLINE_STORE: 'Write to Offline Store',
  ONLINE_STORE: 'Write to Online Store',
  BIGQUERY: 'BigQuery',
  BULK_LOAD: 'Bulk Load',
};

export const getStages = (tectonMangeStaging: TectonManagedStage[]): StageEntryInterface[] => {
  return (
    tectonMangeStaging.map((stage: TectonManagedStage) => {
      return {
        type: `${stage?.stage_type ?? 'PYTHON'}`,
        state: `${stage?.state ?? 'STATE_UNSPECIFIED'}`,
        description: stage.description ?? '',
        progress: stage.progress,
        externalLink: stage.external_link,
        compiledQuery: stage.compiled_sql_query,
        errorDetail: stage.error_detail,
        errorType: stage.error_type,
      };
    }) ?? []
  );
};
