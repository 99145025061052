import { FC, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import { Link } from 'react-router-dom';
import { Routes } from '../../../core/routes';
import { ServiceAccount } from '../../../api/gql/graphql';
import { RoleAssignmentType } from '../../../types/tecton_proto/auth/resource_role_assignments';
import PermissionRoles from './PermissionRoles';

import {
  EmptyPrompt,
  ErrorPromptButtons,
  SearchAndActionButton,
  EuiBasicTableColumn,
  Table,
  Icon,
  FlexGroup,
  FlexItem,
  IconTip,
  FlexGroupWrapper,
  AvatarAndName,
} from '@tecton/ComponentRedesign';

// icons
import QuestionInCircle from '@svg/question-in-circle.svg';
import Robot from '@svg/robot.svg';

export interface PermissionsServiceAccountListProps {
  serviceAccounts: ServiceAccount[];
  hasWorkspaceOwnerRole: boolean;
  isError?: boolean;
  isLoading?: boolean;
  onAddServiceAccountClick: VoidFunction;
  onEditServiceAccountRole: (serviceAccount: ServiceAccount) => void;
}

const PermissionsServiceAccountList: FC<PermissionsServiceAccountListProps> = ({
  serviceAccounts,
  hasWorkspaceOwnerRole,
  isError,
  isLoading,
  onAddServiceAccountClick,
  onEditServiceAccountRole,
}) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const filteredServiceAccounts = searchText
    ? serviceAccounts?.filter((serviceAccount: ServiceAccount) => {
        const { name, id } = serviceAccount;
        return [name, id].join('-')?.toLowerCase()?.includes(searchText?.toLowerCase());
      })
    : serviceAccounts;

  const sortedServiceAccounts = isLoading ? undefined : _sortBy(filteredServiceAccounts, ['name']);

  const columns: EuiBasicTableColumn<ServiceAccount>[] = [
    {
      name: 'Name',
      render: (item: ServiceAccount) => {
        return (
          <>
            <Link to={`${Routes.accountsAndAccessServiceAccounts}/${item.id}`}>
              <AvatarAndName name={item.name ?? ''} icon={'Robot'} type="icon" />
            </Link>
          </>
        );
      },
    },
    {
      name: (
        <>
          <FlexGroup justifyContent="center" alignItems="center" direction="row" gutterSize="none">
            <FlexItem>Role</FlexItem>
            <FlexItem>
              <IconTip
                content={`A service account’s role can be
directly assigned, inherited
from a group, or set via their ‘All
Workspaces’ roles, Tecton will use
the highest role as the effective
role, as shown in this column.`}
                type={QuestionInCircle}
              />
            </FlexItem>
          </FlexGroup>
        </>
      ),
      render: (serviceAccount: ServiceAccount) => {
        const roleAssignmentSources =
          serviceAccount.rolesGranted?.flatMap((item) => {
            return (
              item.roleAssignmentSources
                ?.filter((roleAssignSource) => {
                  return roleAssignSource.assignmentType !== RoleAssignmentType.ROLE_ASSIGNMENT_TYPE_DIRECT;
                })
                ?.map((assignmentSource) => {
                  return assignmentSource;
                }) ?? []
            );
          }) ?? [];

        return (
          <>
            <PermissionRoles
              roleAssignmentSources={roleAssignmentSources}
              principalName={serviceAccount.name ?? ''}
              roleName={serviceAccount.workspaceRole ?? ''}
              hasWorkspaceOwnerRole={hasWorkspaceOwnerRole}
              onClick={() => {
                onEditServiceAccountRole(serviceAccount);
              }}
            />
          </>
        );
      },
    },
  ];

  if (isError) {
    const emptyPrompt = (
      <EmptyPrompt
        orientation="horizontal"
        variant="storm"
        title={<>Error Loading Service Accounts</>}
        body={
          <>
            <p>We ran into an error while loading Service Accounts.</p>
          </>
        }
        actions={<ErrorPromptButtons />}
      />
    );
    return <Table items={[]} columns={columns} emptyPrompt={emptyPrompt} />;
  }

  return (
    <FlexGroupWrapper gap="s" direction="column">
      <FlexItem>
        <SearchAndActionButton
          searchPlaceHolder={'Search for Service Account'}
          actionButtonLabel={'Add Service Account'}
          actionButtonVariant={hasWorkspaceOwnerRole ? 'primaryAction' : 'disabledAction'}
          onActionButtonClick={onAddServiceAccountClick}
          onSearchInputChange={function (searchString: string): void {
            setSearchText(searchString);
          }}
        />
      </FlexItem>
      <FlexItem>
        {isLoading ? (
          <Table
            items={[]}
            columns={columns}
            emptyPrompt={<EmptyPrompt title={<>Loading Service Accounts</>} body={<></>} variant="loading" />}
          />
        ) : (
          <Table items={sortedServiceAccounts ?? []} columns={columns} allowWordBreak={false} />
        )}
      </FlexItem>
    </FlexGroupWrapper>
  );
};

export default PermissionsServiceAccountList;
