import { PageNotFound } from '@tecton/ComponentRedesign';
import { useEffect, useRef } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { matchPath, useLocation } from 'react-router-dom';
import { Routes as AppRoutes } from '../../core/routes';
import useGlobalState from '../../shared/useGlobalState';
import useAppCuesInitializer from '../AppCuesInitializer';

import WorkspaceSelectionWorkflow from '../../core/component/workspace-selection/WorkspaceSelectionHandler';
import Workspace from '../../core/component/WorkspaceOutlet';
import OnlineStoreMonitoring from '../../feature/feature-services/component/OnlineStoreMonitoring';
import ServiceLevelObjectives from '../../feature/feature-services/component/ServiceLevelObjectives';
import FeatureMonitoringSummary from '../../feature/feature-views/component/FeatureMonitoringSummary';
import Features from '../../feature/feature-views/component/Features';
import ChoosePlatform from '../../feature/onboarding/choose-platform/ChoosePlatform';
import ConfigureDatabricksPage from '../../feature/onboarding/configure/ConfigureDatabricksPage';
import SetupInProgress from '../../feature/onboarding/data-platform-setup/setup-in-progress/SetupInProgress';
import OnboardingRootRedirectPage from '../../feature/onboarding/OnboardingRootRedirectPage';
import FeatureAnalytics from './featureAnalytics/FeatureAnalyticsContainer';
import ValidationsContainer from './validations/ValidationsContainer';

import { useIsValidationsEnabled } from '../../api/validation';
import { logEvent } from '../../utils/analytics-utils';
import { FeatureFlags, useUserSettings } from '../context/UserSettingsContext';
import LegacyPathRedirect from './dataFlow/LegacyPathRedirect';
import WorkspacesViewSelector from './workspace/WorkspacesViewSelector';

// -------------------------------- Redesign --------------------------------
import ApplicationContainer from '../../components/redesign/ApplicationContainer';

// Home (Landing Page)
import CommitDetails from './dataFlow/CommitDetails';
import DataFlowContainer from './dataFlow/DataFlowContainer';

// FCOs lists
import FeatureServicesContainer from '../featureServices/Redesign/FeatureServicesContainer';
import DataSetsContainer from './dataSets/DatasetsListContainer';
import EntitiesContainer from './entities/EntitiesContainer';
import FeatureViewsContainer from './featureViews/FeatureViewsContainer';
import DataSourcesContainer from './sources/DataSourcesContainer';
import TransformationsContainer from './transformations/TransformationsContainer';

// FCOs detail pages
import DatasetContainerContainer from './dataSets/DatasetContainer';
import EntityContainerContainer from './entities/EntityContainer';
import FeatureServiceContainer from './featureServices/FeatureServiceContainer';
import FeatureViewContainer from './featureViews/FeatureViewContainer';
import SourceContainerContainer from './sources/SourceContainer';
import TransformationContainerContainer from './transformations/TransformationContainer';

// ACL Summary
import AccountsAndAccessOutlet from './acl/AccountsAndAccessOutlet';
import ACLGroupListContainer from './acl/ACLGroupsContainer';
import ACLServiceAccountListsContainer from './acl/ACLServiceAccountListsContainer';
import ACLUserListContainer from './acl/ACLUserListContainer';

// ACL Details
import ACLGroupDetailsContainer from './acl/ACLGroupDetailsContainer';
import ACLServiceAccountContainer from './acl/ACLServiceAccountContainer';
import ACLUserProfileContainer from './acl/ACLUserProfileContainer';

// Permissions
import PermissionsGroupsListContainer from './permissions/PermissionsGroupsListContainer';
import PermissionsOutlet from './permissions/PermissionsOutlet';
import PermissionsServiceAccountsListContainer from './permissions/PermissionsServiceAccountsListContainer';
import PermissionsUserListContainer from './permissions/PermissionsUserListContainer';

// Compute
import ComputeEnvironmentsContainer from './compute/ComputeEnvironmentsContainer';
import ComputeIdentitiesContainer from './compute/ComputeIdentitiesContainer';
import ComputeOutlet from './compute/ComputeOutlet';
import ComputeOverviewContainer from './compute/ComputeOverviewContainer';

import DataPlatformConfigEdit from './compute/DataPlatformConfigEdit';

// Jobs
import Jobs from '../../components/redesign/jobs/JobsContainer';
// Jobs => Rift
import JobDetailsContainer from '../../components/redesign/jobs/JobDetails';

// Misc
import ServerGroupsContainer from './compute/ServerGroupsContainer';
import FeatureServiceDebug from './debug/FeatureServiceDebug';
import MonitoringContainer from './monitoring/MonitoringContainer';

// Debug

import Debug from './debug/Debug';
import FeatureViewDebug from './debug/FeatureViewDebug';

const routesArray = Object.values(AppRoutes);
const routeMatch = (path: string): string | undefined => {
  return routesArray.find((route: any) => matchPath(route, path));
};

const ApplicationRoutes = () => {
  const { isGuidedOnboardingEnabled } = useGlobalState();
  const isValidationsEnabled = useIsValidationsEnabled();
  const { canShowMainApplication, workspace, featureEnabled, canShowComputeIdentity } = useUserSettings();

  const canShowComputeEnvironment = featureEnabled(FeatureFlags.SHOW_COMPUTE_ENVIRONMENT);

  const previousPath = useRef<string | undefined>(undefined);

  const { pathname } = useLocation();

  useEffect(() => {
    // ONLY LOG WHEN BASE PATH CHANGES
    // WE CAN HANDLE CHANGING PARAMS SEPARATELY
    if (pathname && pathname !== previousPath.current) {
      const rt = routeMatch(pathname);
      logEvent('Viewed UI Route', undefined, { path: pathname, route: rt });
      previousPath.current = pathname;
    }
  }, [pathname]);

  // APP_CUES INIT FOR Community Cluster
  useAppCuesInitializer();

  return (
    <Routes>
      <Route path={AppRoutes.root} element={<ApplicationContainer />}>
        {canShowMainApplication ? (
          <>
            {/* TODO: Remove this redirect when we migrate to the new UI */}
            <Route path={AppRoutes.planSummary} element={<LegacyPathRedirect />} />
            {/* Workspace Routes */}
            <Route path="/repo" element={<Workspace />}>
              <>
                <Route path={AppRoutes.dataflow} element={<DataFlowContainer />} />
                <Route path={AppRoutes.workspaceHome} element={<Navigate to={`/repo/${workspace}/home/dataflow`} />} />

                <Route path={AppRoutes.commitDetails} element={<CommitDetails />} />
              </>

              <Route path={AppRoutes.features} element={<Features />} />

              {/* FCOs lists */}
              <Route path={AppRoutes.dataSources} element={<DataSourcesContainer />} />
              <Route path={AppRoutes.transformations} element={<TransformationsContainer />} />
              <Route path={AppRoutes.entities} element={<EntitiesContainer />} />
              <Route path={AppRoutes.featureViews} element={<FeatureViewsContainer />} />
              <Route path={AppRoutes.featureServices} element={<FeatureServicesContainer />} />
              <Route path={AppRoutes.savedFeatureDataFrames} element={<DataSetsContainer />} />

              {/* FCO detail pages */}
              <Route path={AppRoutes.dataSource} element={<SourceContainerContainer />} />
              <Route path={AppRoutes.entity} element={<EntityContainerContainer />} />
              <Route path={AppRoutes.transformation} element={<TransformationContainerContainer />} />
              <Route path={AppRoutes.featureView} element={<FeatureViewContainer />} />
              <Route path={AppRoutes.featureService} element={<FeatureServiceContainer />} />
              <Route path={AppRoutes.savedFeatureDataFrame} element={<DatasetContainerContainer />} />

              {/* Monitoring */}
              <Route path={AppRoutes.monitoring} element={<MonitoringContainer />} />

              {/* Not yet migrated */}
              <Route path={AppRoutes.featureMonitoringSummary} element={<FeatureMonitoringSummary />} />
              <Route path={AppRoutes.featuresByEntity} element={<Features />} />
              <Route path={AppRoutes.sloDashboard} element={<ServiceLevelObjectives />} />
              <Route path={AppRoutes.featureServerMonitoring} element={<OnlineStoreMonitoring />} />

              {/* Permissions */}
              <Route path={AppRoutes.configurations} element={<PermissionsOutlet />}>
                <Route index element={<Navigate to={AppRoutes.accessUsers} replace />} />
                <Route path={AppRoutes.accessUsers} element={<PermissionsUserListContainer />} />
                <Route path={AppRoutes.accessServiceAccounts} element={<PermissionsServiceAccountsListContainer />} />
                <Route path={AppRoutes.accessGroups} element={<PermissionsGroupsListContainer />} />
              </Route>

              {isValidationsEnabled && <Route path={AppRoutes.validations} element={<ValidationsContainer />} />}
            </Route>
            <Route path={AppRoutes.featureAnalytics} element={<FeatureAnalytics />} />
          </>
        ) : (
          <>
            <Route path="/repo/*" element={<WorkspacesViewSelector />} />
            <Route path="/select-a-workspace" element={<Navigate to={'/repo'} />} />
          </>
        )}

        <Route path={AppRoutes.jobs} element={<Jobs />} />
        <Route path={AppRoutes.jobDetails} element={<JobDetailsContainer />} />

        {/* compute */}
        {/* TODO: once environment and DB principal is GA, let's update the route to /compute. Currently we have /data-platform */}
        <Route
          path={AppRoutes.dataPlatformConfiguration}
          element={canShowComputeIdentity || canShowComputeEnvironment ? <ComputeOutlet /> : <DataPlatformConfigEdit />}
        />
        {(canShowComputeIdentity || canShowComputeEnvironment) && (
          <Route path={AppRoutes.dataPlatformConfiguration} element={<ComputeOutlet />}>
            <Route index element={<Navigate to={'./overview'} replace />} />
            <Route path={AppRoutes.computeOverview} element={<ComputeOverviewContainer />} />
            <Route path={AppRoutes.computeEnvironments} element={<ComputeEnvironmentsContainer />} />
            <Route path={AppRoutes.computeIdentities} element={<ComputeIdentitiesContainer />} />
            <Route path={AppRoutes.serverGroups} element={<ServerGroupsContainer />} />
          </Route>
        )}

        {/* Guided onBoarding */}
        {isGuidedOnboardingEnabled && (
          <Route path={AppRoutes.onBoarding} element={<OnboardingRootRedirectPage />}>
            <Route path={AppRoutes.onBoardingChoosePlatform} element={<ChoosePlatform />} />
            <Route path={AppRoutes.onBoardingSetupInProgress} element={<SetupInProgress />} />
            <Route path={AppRoutes.onBoardingConfigureDatabricks} element={<ConfigureDatabricksPage />} />
          </Route>
        )}

        {/* ACL Routes */}
        <Route path={AppRoutes.settings} element={<AccountsAndAccessOutlet />}>
          {/* ACL Summary (Table lists)  */}
          <Route
            path={AppRoutes.accountAndAccess}
            element={<Navigate to={AppRoutes.accountsAndAccessUsers} replace />}
          />
          <Route index element={<Navigate to={AppRoutes.accountsAndAccessUsers} replace />} />
          <Route path={AppRoutes.accountsAndAccessUsers} element={<ACLUserListContainer />} />
          <Route path={AppRoutes.accountsAndAccessServiceAccounts} element={<ACLServiceAccountListsContainer />} />
          <Route path={AppRoutes.accountsAndAccessGroups} element={<ACLGroupListContainer />} />

          {/* ACL Details (Profile) pages */}
          <Route path={AppRoutes.accountsAndAccessUserProfile} element={<ACLUserProfileContainer />} />
          <Route path={AppRoutes.accountsAndAccessServiceAccountProfile} element={<ACLServiceAccountContainer />} />
          <Route path={AppRoutes.accountsAndAccessGroupsProfile} element={<ACLGroupDetailsContainer />} />
        </Route>

        <Route path={AppRoutes.selectWorkspace} element={<WorkspaceSelectionWorkflow />} />
        {/* ACL Details (Profile) pages */}
        <Route path={AppRoutes.debug} element={<Debug />} />
        <Route path={AppRoutes.featureViewDebug} element={<FeatureViewDebug />} />
        <Route path={AppRoutes.featureServiceDebug} element={<FeatureServiceDebug />} />

        {/* Catch all Routes */}
        <Route path="*" element={<PageNotFound workspace={workspace ?? 'prod'} />} />
      </Route>
    </Routes>
  );
};

export default ApplicationRoutes;
