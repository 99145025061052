/* eslint-disable */

export const protobufPackage = "tecton_proto.data";

/**
 * The status of the internal (aka validation) spark cluster.
 *
 * Note in the case of an "upgrade" (i.e. we're creating a new validation
 * cluster to replace an existing one), we may temporarily have two clusters
 * running at the same time to avoid downtime. In that case, these statuses
 * ignore the old cluster, which may still be running and HEALTHY.
 *
 * So for initial turn up, the state transition should be:
 * NO_CLUSTER -> CREATING_NEW_CLUSTER -> NEW_CLUSTER_STARTING ->
 * HEALTHY/UNHEALTHY
 *
 * For an upgrade, the state transition should be:
 * HEALTHY/UNHEALTHY -> CREATING_NEW_CLUSTER -> NEW_CLUSTER_STARTING ->
 * HEALTHY/UNHEALTHY
 */
export enum InternalSparkClusterStatusEnum {
  INTERNAL_SPARK_CLUSTER_STATUS_UNSPECIFIED = "INTERNAL_SPARK_CLUSTER_STATUS_UNSPECIFIED",
  /**
   * INTERNAL_SPARK_CLUSTER_STATUS_NO_CLUSTER - There is no internal cluster. This may not be an error, e.g. the data
   * platform has not been connected yet.
   */
  INTERNAL_SPARK_CLUSTER_STATUS_NO_CLUSTER = "INTERNAL_SPARK_CLUSTER_STATUS_NO_CLUSTER",
  /**
   * INTERNAL_SPARK_CLUSTER_STATUS_CREATING_CLUSTER - Tecton is in the process of creating a new cluster, i.e. we have not yet
   * sent the CreateCluster request.
   */
  INTERNAL_SPARK_CLUSTER_STATUS_CREATING_CLUSTER = "INTERNAL_SPARK_CLUSTER_STATUS_CREATING_CLUSTER",
  /**
   * INTERNAL_SPARK_CLUSTER_STATUS_WAITING_FOR_CLUSTER_TO_START - The cluster creation request has been made successfully. Waiting on the new
   * cluster to start.
   */
  INTERNAL_SPARK_CLUSTER_STATUS_WAITING_FOR_CLUSTER_TO_START =
    "INTERNAL_SPARK_CLUSTER_STATUS_WAITING_FOR_CLUSTER_TO_START",
  /** INTERNAL_SPARK_CLUSTER_STATUS_HEALTHY - There is a running, healthy internal cluster. */
  INTERNAL_SPARK_CLUSTER_STATUS_HEALTHY = "INTERNAL_SPARK_CLUSTER_STATUS_HEALTHY",
  /** INTERNAL_SPARK_CLUSTER_STATUS_UNHEALTHY - There is a running internal cluster that is in an unhealthy state. */
  INTERNAL_SPARK_CLUSTER_STATUS_UNHEALTHY = "INTERNAL_SPARK_CLUSTER_STATUS_UNHEALTHY",
  /** INTERNAL_SPARK_CLUSTER_STATUS_NOT_APPLICABLE - This deployment intentionally does not have an internal cluster. */
  INTERNAL_SPARK_CLUSTER_STATUS_NOT_APPLICABLE = "INTERNAL_SPARK_CLUSTER_STATUS_NOT_APPLICABLE",
}

export interface InternalSparkClusterStatus {
  status?:
    | InternalSparkClusterStatusEnum
    | undefined;
  /**
   * If true, there is an error in the current state. error_message has
   * human-readable details about the error.
   */
  error?: boolean | undefined;
  error_message?:
    | string
    | undefined;
  /**
   * A link to the validation cluster. Its behavior varies in different states.
   *   NO_CLUSTER, CREATING_CLUSTER -> empty
   *   HEALTHY, UNHEALTHY           -> Links to the canonical/active cluster.
   *   WAITING_FOR_CLUSTER_TO_START -> Links to the new/starting-up cluster.
   */
  cluster_url?: string | undefined;
}
