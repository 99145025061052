import { FC } from 'react';
import { MonitoringDateRange } from '../../../monitoring/DateRange-Dropdown-util';

import { GraphType } from '../../../../feature/feature-views/graph-types';
import { X_AXIS_LABEL } from '../../../monitoring/GraphContainer';
import PrometheusMonitoring from './PrometheusMonitoring';

// @tecton
import { Card, Tooltip } from '@tecton/ComponentRedesign';
import useGlobalState from '../../../../shared/useGlobalState';
import UrlUtils from '../../../../utils/url-utils';
import { FlexGroup } from '@tecton/ComponentRedesign/lib/v1';

interface PrometheusContainerProps {
  monitoringDateRange: MonitoringDateRange;
  workspace: string;
  graphMetadata: GraphType;
  featureViewId: string;
  serverGroupId?: string;
  serverGroupName?: string;
  height?: number;
  parseValue?: (a: string) => number; // default is parseInt
}

const PrometheusContainer: FC<PrometheusContainerProps> = ({
  monitoringDateRange,
  workspace,
  graphMetadata,
  featureViewId,
  serverGroupId,
  serverGroupName,
  height,
  parseValue,
}) => {
  const { config } = useGlobalState();
  const chartTitle = graphMetadata.chartTitle.replaceAll('::workspace::', workspace ?? '') ?? '';
  const yAxisLabel = graphMetadata?.yAxisLabel ?? '';

  const queries =
    graphMetadata?.queries?.map((query) => {
      // TODO: Delete this logic when migration to ELB is done.
      const ingressType: string = config?.FEATURE_SERVER_INGRESS_TYPE ?? 'nginx';
      const escapedQuery = UrlUtils.escape(
        query?.query.apply(this, [
          {
            workspace,
            fcoId: featureViewId,
            step: monitoringDateRange?.step,
            ingressType,
            serverGroupId,
            serverGroupName,
          },
        ])
      );
      return {
        label: query.label,
        query: escapedQuery,
      };
    }) ?? [];

  const yTicksCallback = graphMetadata.yTickCallBack;
  const toolTipLabelCallback = graphMetadata.toolTipLabelCallback;
  const getMetricLabelCallback = graphMetadata?.getMetricLabelCallback;
  const graphInfoTooltip = graphMetadata?.graphInfoTooltip?.replaceAll('::step::', `${monitoringDateRange?.step}`);

  const yOverride = {
    title: {
      display: true,
      text: yAxisLabel,
      font: {
        size: 12,
      },
    },
    min: 0,
    ticks: {
      callback: yTicksCallback,
    },
  };

  const hideLegendOverride = {
    display: true,
    labels: {
      display: false,
      generateLabels: () => {
        return '';
      },
    },
  };

  const title = (
    <Tooltip
      content={<>{graphInfoTooltip ?? 'No description provided.'}</>}
      data-testid="chart-tooltip"
      trigger={<>{chartTitle}</>}
      placement="left"
    />
  );

  return (
    <Card title={title}>
      <FlexGroup padding="0" justifyContent="center" alignItems="center">
        <PrometheusMonitoring
          chartTitle={''}
          monitoringDateRange={monitoringDateRange}
          queries={queries}
          xAxisLabel={X_AXIS_LABEL}
          yAxisLabel={yAxisLabel}
          yTicksCallback={yTicksCallback}
          yGrace={3}
          yOverride={yOverride}
          toolTipLabelCallback={toolTipLabelCallback}
          getMetricLabelCallback={getMetricLabelCallback}
          pluginLegendOverride={graphMetadata?.showLegend ? undefined : hideLegendOverride}
          parseValue={parseValue}
          height={height}
        />
      </FlexGroup>
    </Card>
  );
};

export default PrometheusContainer;
