import { FC } from 'react';
import { FeatureViewFCO, FeatureViewFCOFields, FeatureViewFCOType } from '../../../core/types/fcoTypes';
import FeatureViewConfigurationCard, { ConfigCardItemType } from './FeatureViewConfigurationCard';

interface MaterializationConfigurationProps {
  fco: FeatureViewFCO;
}

const BatchFeatureViewMaterializationConfigurationCard: FC<MaterializationConfigurationProps> = ({ fco }) => {
  return (
    <FeatureViewConfigurationCard
      title="General Configuration"
      fco={fco}
      items={[
        ConfigCardItemType.MATERIALIZATION_RUNTIME,
        ConfigCardItemType.FEATURE_START_TIME,
        ConfigCardItemType.MATERIALIZATION_START_TIME,
        ConfigCardItemType.BATCH_SCHEDULE,
        ConfigCardItemType.BATCH_TRIGGER,
        ConfigCardItemType.MANUAL_TRIGGER_BACKFILL_END_TIME,
        ConfigCardItemType.ONLINE_BACKFILL_LOAD_TYPE,
        ConfigCardItemType.MAX_DATA_SOURCE_DELAY,
        ConfigCardItemType.INCREMENTAL_BACKFILL,
        ConfigCardItemType.MAX_BACKFILL_INTERVAL,
        ConfigCardItemType.BATCH_COMPACTION_ENABLED,
        ConfigCardItemType.COMPUTE_IDENTITY,
        ConfigCardItemType.AGGREGATION_INTERVAL,
      ]}
    />
  );
};

const FeatureTableMaterializationConfigurationCard: FC<MaterializationConfigurationProps> = ({ fco }) => {
  return (
    <FeatureViewConfigurationCard
      title="General Configuration"
      fco={fco}
      items={[ConfigCardItemType.MATERIALIZATION_RUNTIME]}
    />
  );
};

const StreamingFeatureViewMaterializationConfigurationCard: FC<MaterializationConfigurationProps> = ({ fco }) => {
  return (
    <FeatureViewConfigurationCard
      title="General Configuration"
      fco={fco}
      items={[
        ConfigCardItemType.MATERIALIZATION_RUNTIME,
        ConfigCardItemType.FEATURE_START_TIME,
        ConfigCardItemType.MATERIALIZATION_START_TIME,
        ConfigCardItemType.STREAM_PROCESSING_MODE,
        ConfigCardItemType.BATCH_SCHEDULE,
        ConfigCardItemType.BATCH_TRIGGER,
        ConfigCardItemType.MANUAL_TRIGGER_BACKFILL_END_TIME,
        ConfigCardItemType.ONLINE_BACKFILL_LOAD_TYPE,
        ConfigCardItemType.MAX_DATA_SOURCE_DELAY,
        ConfigCardItemType.SERVING_TTL,
        ConfigCardItemType.INCREMENTAL_BACKFILL,
        ConfigCardItemType.MAX_BACKFILL_INTERVAL,
        ConfigCardItemType.COMPUTE_IDENTITY,
        ConfigCardItemType.AGGREGATION_INTERVAL,
        ConfigCardItemType.BATCH_COMPACTION_ENABLED,
        ConfigCardItemType.STREAM_TILING_ENABLED,
      ]}
    />
  );
};

const MaterializationConfigurationCard: FC<MaterializationConfigurationProps> = ({ fco }) => {
  switch (fco[FeatureViewFCOFields.FEATURE_VIEW_TYPE]) {
    case FeatureViewFCOType.BATCH:
      return <BatchFeatureViewMaterializationConfigurationCard fco={fco} />;
    case FeatureViewFCOType.STREAM:
      return <StreamingFeatureViewMaterializationConfigurationCard fco={fco} />;
    case FeatureViewFCOType.FEATURE_TABLE:
      return <FeatureTableMaterializationConfigurationCard fco={fco} />;
    default:
      return <></>;
  }
};

export default MaterializationConfigurationCard;
