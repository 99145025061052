export interface GraphPanel {
  panelLabel: string;
  graphs: GraphType[];
  gridTemplateColumns?: string;
}

export type GraphType = {
  chartTitle: string;
  renderType: GraphRenderType; // this will determined  how to render the graph.
} & (
  | {
      // Make sure we don't pass those other attributes when customGraph is passed in
      customGraph: CustomGraph;
      showLegend?: never;
      graphInfoTooltip?: never;
      yTickCallBack?: never;
      toolTipLabelCallback?: never;
      getMetricLabelCallback?: never;
      queries?: never;
      parameters?: never;
      yAxisLabel?: never;
      yOptions?: never;
      parseValue?: never;
    }
  | {
      customGraph?: never; // customGraph should not exist for this type
      showLegend: boolean;
      graphInfoTooltip?: string;
      yTickCallBack: (a: number) => number | string;
      toolTipLabelCallback: (a: ChartInstance) => string;
      getMetricLabelCallback?: (arg1: any, label?: string) => string;
      queries: GraphQuery[];
      parameters?: (featureViewId: string) => string[][];
      yAxisLabel: string;
      yLabel?: string;
      yOptions?: any;
      parseValue?: (string: string) => number; // default is parseInt
    }
);

export interface ChartInstance {
  parsed: { y: number; x: number };
  dataset: { label: string };
}

export interface QueryParam {
  workspace?: string;
  fcoId?: string;
  step?: number;
  ingressType?: string;
  serverGroupId?: string;
  serverGroupName?: string;
}

export interface GraphQuery {
  label: string;
  query: (param: QueryParam) => string;
}

export enum GraphRenderType {
  CUSTOM,
  PROMETHEUS,
  GRAPHITE,
}

export enum CustomGraph {
  NUMBER_OF_FEATURE_VIEWS_AND_TABLE = 'NUMBER_OF_FEATURE_VIEWS_AND_TABLE',
  FEATURE_VIEW_DRILL_DOWN = 'FEATURE_VIEW_DRILL_DOWN',
  FEATURE_SERVICE_PAGE_MONITORING_ONLINE_STORE_LATENCY = 'FEATURE_SERVICE_PAGE_MONITORING_ONLINE_STORE_LATENCY',
  FEATURE_SERVICE_SLO_RELIABILITY = 'FEATURE_SERVICE_SLO_RELIABILITY',
  FEATURE_SERVICE_GLOBAL_SLO_RELIABILITY = 'FEATURE_SERVICE_GLOBAL_SLO_RELIABILITY',
  FEATURE_SERVICE_DYNAMO_DB_MONITORING = 'FEATURE_SERVICE_DYNAMO_DB_MONITORING',
  UNKNOWN = 'UNKNOWN',
}
