import { FC } from 'react';
import { DataSourceFCO } from '../../../core/types/fcoTypes';
import FCOMetadataCard from '../../@tecton/ComponentRedesign/FCOMetadataCard';
import FCOTagsCard from '../../@tecton/ComponentRedesign/FCOTagsCard';
import BatchConfigCard from './BatchConfigCard';
import SchemaCard from './SchemaCard';
import StreamConfigCard from './StreamConfigCard';
import { ColumnLayout } from '../../@tecton/ComponentRedesign/';

interface DataSourceOverviewProps {
  source: DataSourceFCO;
}

const DataSourceOverview: FC<DataSourceOverviewProps> = ({ source }) => {
  return (
    <ColumnLayout>
      <FCOMetadataCard fco={source} />
      <FCOTagsCard fco={source} />
      <BatchConfigCard source={source} />
      <StreamConfigCard source={source} />
      <SchemaCard source={source} />
    </ColumnLayout>
  );
};

export default DataSourceOverview;
