import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { User } from '../../../api/gql/graphql';
import _sortBy from 'lodash/sortBy';

import { hiddenEditLinkRowCss } from './acl-util';
import PrincipalListAccountType from './PrincipalListAccountType';

import { TectonDateFormat } from '@tecton/ComponentRedesign/utils';

import {
  SearchAndActionButton,
  EmptyPrompt,
  ErrorPromptButtons,
  Table,
  EuiBasicTableColumn,
  ButtonIcon,
  AccountStatusBadge,
  FlexGroupWrapper,
  FlexItem,
  AvatarAndName,
  Tooltip,
} from '@tecton/ComponentRedesign';

import { ReactComponent as Trash } from '@svg/trash.svg';

export interface ACLUserListProps {
  users: User[];
  isAdminView: boolean;
  isError?: boolean;
  isLoading?: boolean;
  onInviteUserClick: VoidFunction;
  onAccountTypeRowClicked: (user: User) => void;
  onDeleteUserRowClicked: (user: User) => void;
}

const ACLUserList: FC<ACLUserListProps> = ({
  users,
  isAdminView,
  isError,
  isLoading,
  onInviteUserClick,
  onAccountTypeRowClicked,
  onDeleteUserRowClicked,
}) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  // A great way to not use useEffect. Basically check to see if there are any search text if not used the original lists.
  const filteredUsers = searchText
    ? users?.filter((user: User) => {
        return user.loginEmail?.toLowerCase()?.includes(searchText?.toLowerCase());
      })
    : users;

  const sortedUsers = isLoading ? undefined : _sortBy(filteredUsers, ['loginEmail']);

  const columns: EuiBasicTableColumn<User>[] = [
    {
      name: 'Email',
      render: (user: User) => {
        return (
          <Link to={`./${user.loginEmail}`}>
            <AvatarAndName name={user.loginEmail ?? ''} />
          </Link>
        );
      },
    },
    ...(isAdminView
      ? [
          {
            name: 'Account Type',
            render: (user: User) => {
              return (
                <PrincipalListAccountType
                  rolesGranted={user.rolesGranted ?? []}
                  accountType={user.accountType ?? ''}
                  principalId={user?.loginEmail ?? ''}
                  onAccountTypeRowClicked={() => {
                    onAccountTypeRowClicked(user);
                  }}
                />
              );
            },
          },
        ]
      : []),
    {
      name: 'Status',
      render: (item: User) => {
        return <AccountStatusBadge status={item.oktaStatus ?? ''} />;
      },
    },
    {
      name: 'Created',
      render: (user: User) => {
        return user.createdAt ? TectonDateFormat(moment(user.createdAt)) : <>-</>;
      },
    },
    {
      name: 'Last Login',
      render: (user: User) => {
        return user.lastLogin ? TectonDateFormat(moment(user.lastLogin)) : <>-</>;
      },
    },
    {
      name: 'Actions',
      actions: [
        {
          render: (user: User) => (
            <>
              {isAdminView ? (
                <ButtonIcon
                  iconType={Trash}
                  variant="dangerousAction"
                  isDisabled={!isAdminView}
                  size="xs"
                  onClick={() => {
                    onDeleteUserRowClicked(user);
                  }}
                />
              ) : (
                <Tooltip
                  trigger={
                    <ButtonIcon
                      variant={!isAdminView ? 'disabledAction' : 'dangerousAction'}
                      iconType={Trash}
                      isDisabled={!isAdminView}
                      size="xs"
                      onClick={() => {
                        onDeleteUserRowClicked(user);
                      }}
                    />
                  }
                  content="Administrator Privileges Required."
                />
              )}
            </>
          ),
        },
      ],
    },
  ];

  if (isError) {
    const emptyPrompt = (
      <EmptyPrompt
        orientation="horizontal"
        variant="storm"
        title={<>Error Loading Users</>}
        body={
          <>
            <p>We ran into an error while loading Users.</p>
          </>
        }
        actions={<ErrorPromptButtons />}
      />
    );
    return <Table items={[]} columns={columns} emptyPrompt={emptyPrompt} />;
  }

  return (
    <FlexGroupWrapper gap="s" direction="column">
      <FlexItem>
        <SearchAndActionButton
          searchPlaceHolder={'Search for User'}
          actionButtonLabel={'Invite User'}
          actionButtonVariant={isAdminView ? 'primaryAction' : 'disabledAction'}
          onActionButtonClick={onInviteUserClick}
          onSearchInputChange={(search: string) => {
            setSearchText(search ?? undefined);
          }}
        />
      </FlexItem>
      <FlexItem>
        {isLoading ? (
          <Table
            items={[]}
            columns={columns}
            emptyPrompt={
              <EmptyPrompt title={<>Loading Users</>} body={<></>} variant="loading" orientation="vertical" />
            }
          />
        ) : (
          <Table items={sortedUsers ?? []} columns={columns} rowProps={{ className: hiddenEditLinkRowCss }} />
        )}
      </FlexItem>
    </FlexGroupWrapper>
  );
};

export default ACLUserList;
