/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { Text } from '../';

import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingFocusManager,
  FloatingPortal,
  FloatingContext,
  ReferenceType,
  ExtendedRefs,
  Placement,
} from '@floating-ui/react';
import FlexGroup from './FlexGroup';
import { css, keyframes } from '@emotion/react';

type PopoverLifecycleState = 'spawning' | 'active' | 'exiting';

interface PopoverProps {
  trigger: NonNullable<React.ReactNode>;
  body: React.ReactNode;
  title?: React.ReactNode;
  buttons?: React.ReactNode;
  isOpen?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  maxWidth?: number;
  minWidth?: number;
  parentRef?: React.RefObject<HTMLDivElement>;
  placement?: Placement;
  offsetSize?: number;
  disabled?: boolean;
}

interface PopoverInternalProps extends PopoverProps {
  lifeCycleState: PopoverLifecycleState;
  setLifeCycleState: React.Dispatch<React.SetStateAction<PopoverLifecycleState>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  context: FloatingContext<ReferenceType>;
  refs: ExtendedRefs<ReferenceType>;
  floatingStyles: React.CSSProperties;
  getFloatingProps: (userProps?: React.HTMLProps<HTMLElement>) => Record<string, unknown>;
}

const enter = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

const exit = keyframes`
  from {
  opacity: 1;
  }
  to {
  opacity: 0;
  }
`;

const PopoverBody = styled.div`
  max-height: ${({ theme }) => `${theme.v1.size[120]}px`};
  overflow: auto;
  width: 100%;
  height: auto;
  user-select: none;
`;

const PopoverContainer = styled.div<{ lifeCycleState: PopoverLifecycleState; maxWidth?: number; minWidth?: number }>`
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  box-shadow: ${({ theme }) => theme.v1.shadow.l};
  z-index: ${({ theme }) => theme.v1.layers.popover};
  border-radius: ${({ theme }) => theme.v1.radius[6]};
  border: ${({ theme }) => `1px solid ${theme.v1.colors.border.default}`};
  min-width: ${({ theme, minWidth }) => minWidth ?? theme.v1.size[16]}px;
  max-width: ${({ theme, maxWidth }) => maxWidth ?? theme.v1.size[80]}px;
  color: ${({ theme }) => theme.v1.colors.text.text};
  animation: ${({ theme, lifeCycleState }) => {
    if (lifeCycleState === 'exiting') {
      return css`
        ${exit} ${theme.v1.motion.duration.fast} ${theme.v1.motion.easing.exit} forwards
      `;
    }
    return css`
      ${enter} ${theme.v1.motion.duration.fast} ${theme.v1.motion.easing.enter} forwards
    `;
  }};
`;

const ContentWrapper = styled.div`
  padding: ${({ theme }) => theme.v1.size['2']}px;
`;

const PopoverInternal: FC<PopoverInternalProps> = ({
  title,
  body,
  buttons,
  lifeCycleState,
  setLifeCycleState,
  setIsOpen,
  context,
  refs,
  floatingStyles,
  getFloatingProps,
  maxWidth,
  minWidth,
}) => {
  return (
    <>
      <FloatingPortal>
        <FloatingFocusManager context={context} modal={false}>
          <div ref={refs.setFloating} style={{ ...floatingStyles, zIndex: 99999 }} {...getFloatingProps()}>
            <PopoverContainer
              maxWidth={maxWidth}
              minWidth={minWidth}
              lifeCycleState={lifeCycleState}
              onAnimationEnd={() => {
                if (lifeCycleState === 'spawning') {
                  setLifeCycleState('active');
                }
                if (lifeCycleState === 'exiting') {
                  setIsOpen(false);
                  setLifeCycleState('spawning');
                }
              }}
            >
              <ContentWrapper>
                <FlexGroup direction="column" alignItems="flex-start" gapSize="1.5">
                  {title && <Text element="h5">{title}</Text>}

                  <PopoverBody>
                    <Text>{body}</Text>
                  </PopoverBody>
                  {buttons}
                </FlexGroup>
              </ContentWrapper>
            </PopoverContainer>
          </div>
        </FloatingFocusManager>
      </FloatingPortal>
    </>
  );
};

const Popover: FC<PopoverProps> = ({
  trigger,
  title,
  body,
  buttons,
  isOpen,
  setIsOpen,
  maxWidth,
  minWidth,
  parentRef,
  placement = 'right',
  offsetSize = 10,
  disabled,
}) => {
  const [lifeCycleState, setLifeCycleState] = useState<PopoverLifecycleState>('spawning');

  // TODO: Need to fix conditional hook here
  if (isOpen === undefined) {
    [isOpen, setIsOpen] = useState<boolean>(false);
  }

  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: (change) => {
      if (change) {
        setIsOpen!(true);
      } else {
        setLifeCycleState('exiting');
      }
    },

    middleware: [offset(offsetSize), flip(), shift()],
    ...(parentRef?.current && {
      elements: {
        reference: parentRef.current,
      },
    }),
    placement: placement,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  return (
    <>
      {disabled && trigger}
      {!disabled && (
        <span
          data-testid="popover-trigger"
          onClick={() => {
            setIsOpen!(true);
          }}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          {trigger}
        </span>
      )}

      {isOpen && (
        <PopoverInternal
          data-testid="popover-body"
          lifeCycleState={lifeCycleState}
          setLifeCycleState={setLifeCycleState}
          isOpen={isOpen}
          setIsOpen={setIsOpen!}
          trigger={''}
          body={body}
          buttons={buttons}
          title={title}
          context={context}
          refs={refs}
          floatingStyles={floatingStyles}
          getFloatingProps={getFloatingProps}
          maxWidth={maxWidth}
          minWidth={minWidth}
        />
      )}
    </>
  );
};

export default Popover;
