import styled from '@emotion/styled';
import moment from 'moment-timezone';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import {
  MonitoringDateRange,
  calculateStep,
  calculateUnit,
  getDefaultMonitoringDateRange,
  getMonitoringDateRangeByTimeComboOption,
} from '../../monitoring/DateRange-Dropdown-util';

import { CustomGraph } from '../../../feature/feature-views/graph-types';
import { FEATURE_VIEW_MONITORING_SUMMARY_GRAPH } from '../../../feature/graph-configuration';
import MonitoringGraphs from './MonitoringGraphs';

import FeatureViewFailingIds from './FeatureViewFailingIds';
import FeatureViewHealthDrillDowns from './FeatureViewHealthDrillDowns';
import NumberOfFeatureViewsAndTablesMaterialized from './NumberOfFeatureViewsAndTablesMaterialized';
import NumberOfFeatureViewsAndTablesTotal from './NumberOfFeatureViewsAndTablesTotal';

// @tecton
import { Card, CardGridLayout, FullWidthCard } from '@tecton/ComponentRedesign';
import TectonTimeComboSelector, {
  TimeComboDateRange,
  TimeComboOptionType,
} from '@tecton/ComponentRedesign/lib/TimeComboSelector';
import { filterMonitoringTimeComboOptionCallback } from './monitoring-utils';

const FeatureViewMonitoring: FC = () => {
  const { workspace } = useParams();
  const defaultMonitoringDateRange: MonitoringDateRange = getDefaultMonitoringDateRange();

  const [monitoringDateRange, setMonitoringDateRange] = useState<MonitoringDateRange>(defaultMonitoringDateRange);
  const [selectedTimeComboOption, setSelectedTimeComboOption] = useState<TimeComboOptionType>('1Day');
  const [dateRange, setDateRange] = useState<TimeComboDateRange>({
    startDate: moment().add(-1, 'day'),
    endDate: moment(),
  });

  const FeatureViewTableWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  `;

  const customGraphs: Record<string, React.ReactNode> = {
    [CustomGraph.NUMBER_OF_FEATURE_VIEWS_AND_TABLE]: (
      <>
        <Card title="# of Feature Views and Table">
          <FeatureViewTableWrapper>
            <Card title="Total">
              <NumberOfFeatureViewsAndTablesTotal monitoringDateRange={monitoringDateRange} />
            </Card>
            <Card title="Materialized">
              <NumberOfFeatureViewsAndTablesMaterialized monitoringDateRange={monitoringDateRange} />
            </Card>
          </FeatureViewTableWrapper>
        </Card>
      </>
    ),
    [CustomGraph.FEATURE_VIEW_DRILL_DOWN]: (
      <>
        <FeatureViewHealthDrillDowns monitoringDateRange={monitoringDateRange} />

        <FeatureViewFailingIds monitoringDateRange={monitoringDateRange} />
      </>
    ),
  };

  return (
    <>
      <CardGridLayout>
        <FullWidthCard>
          <TectonTimeComboSelector
            limitMinDateInMonths={2}
            filterOptions={filterMonitoringTimeComboOptionCallback}
            dateRange={dateRange}
            setDateRange={setDateRange}
            initialOption={selectedTimeComboOption}
            onCustomDateSelection={(startDate, endDate) => {
              const startTime = () => startDate.valueOf();
              const endTime = () => endDate.valueOf();
              if (endDate) {
                const step = calculateStep(startDate, endDate);
                const unit = calculateUnit(startDate, endDate);

                const customDateRange: MonitoringDateRange = {
                  startTime: startTime,
                  endTime: endTime,
                  requestId: `${startTime()}${endTime()}`,
                  step: step,
                  unit: unit,
                };
                setSelectedTimeComboOption('custom');
                setMonitoringDateRange(customDateRange);
              }
            }}
            onComboSelection={(timeComboOption) => {
              setSelectedTimeComboOption(timeComboOption);
              const monitoringDateRangeOption = getMonitoringDateRangeByTimeComboOption(timeComboOption);
              setMonitoringDateRange(monitoringDateRangeOption);
            }}
          />
        </FullWidthCard>
      </CardGridLayout>
      <MonitoringGraphs
        monitoringDateRange={monitoringDateRange}
        graphsData={FEATURE_VIEW_MONITORING_SUMMARY_GRAPH}
        customGraphRecord={customGraphs}
        workspace={workspace ?? ''}
      />
    </>
  );
};

export default FeatureViewMonitoring;
