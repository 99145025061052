import { HealthVariants } from '@tecton/ComponentRedesign';
import { ResultMood } from '@tecton/ComponentRedesign/lib/Callout';

import { InternalSparkClusterStatusEnum } from '../../types/tecton_proto/data/internal_spark_cluster_status';

export enum ConfigurationType {
  DATABRICKS = 'DATABRICKS',
  SNOWFLAKE = 'SNOWFLAKE',
}

// TODO: delete this once redesign UI is fully rolled out
export enum ConfigurationStatusType {
  INTERNAL_SPARK_CLUSTER_STATUS_UNSPECIFIED = 'UNKNOWN',
  INTERNAL_SPARK_CLUSTER_STATUS_HEALTHY = 'HEALTHY',
  INTERNAL_SPARK_CLUSTER_STATUS_UNHEALTHY = 'UNHEALTHY',
  INTERNAL_SPARK_CLUSTER_STATUS_CREATING_CLUSTER = 'CREATING_CLUSTER',
  INTERNAL_SPARK_CLUSTER_STATUS_WAITING_FOR_CLUSTER_TO_START = 'WAITING_FOR_CLUSTER_TO_START',
  INTERNAL_SPARK_CLUSTER_STATUS_NO_CLUSTER = 'NO_CLUSTER',
}

export enum ConfigurationStatusTypeV2 {
  INTERNAL_SPARK_CLUSTER_STATUS_UNSPECIFIED = 'Unknown',
  INTERNAL_SPARK_CLUSTER_STATUS_HEALTHY = 'Healthy',
  INTERNAL_SPARK_CLUSTER_STATUS_UNHEALTHY = 'Unhealthy',
  INTERNAL_SPARK_CLUSTER_STATUS_CREATING_CLUSTER = 'Create Cluster',
  INTERNAL_SPARK_CLUSTER_STATUS_WAITING_FOR_CLUSTER_TO_START = 'Waiting for cluster to start',
  INTERNAL_SPARK_CLUSTER_STATUS_NO_CLUSTER = 'No Cluster',
}

export const sparkClusterStatusToHealthMap: Record<InternalSparkClusterStatusEnum, HealthVariants> = {
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_UNSPECIFIED]: 'failure',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_NO_CLUSTER]: 'failure',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_CREATING_CLUSTER]: 'active',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_WAITING_FOR_CLUSTER_TO_START]: 'active',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_HEALTHY]: 'healthy',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_UNHEALTHY]: 'failure',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_NOT_APPLICABLE]: 'neutral',
};

export const sparkClusterStatusToResultMood: Record<InternalSparkClusterStatusEnum, ResultMood> = {
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_UNSPECIFIED]: 'neutral',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_NO_CLUSTER]: 'danger',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_CREATING_CLUSTER]: 'success',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_WAITING_FOR_CLUSTER_TO_START]: 'success',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_HEALTHY]: 'success',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_UNHEALTHY]: 'danger',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_NOT_APPLICABLE]: 'neutral',
};

export const sparkClusterStatusToResultMoodV2: Record<InternalSparkClusterStatusEnum, string> = {
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_UNSPECIFIED]: 'info',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_NO_CLUSTER]: 'danger',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_CREATING_CLUSTER]: 'success',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_WAITING_FOR_CLUSTER_TO_START]: 'success',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_HEALTHY]: 'success',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_UNHEALTHY]: 'danger',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_NOT_APPLICABLE]: 'info',
};
