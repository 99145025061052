import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoleAssignmentSource } from '../../../api/gql/graphql';
import _sortBy from 'lodash/sortBy';
import { Routes } from '../../../core/routes';

import { ButtonEmpty, ButtonIcon, FlexGroupWrapper, FlexItem, Popover } from '@tecton/ComponentRedesign';

import GroupIcon from '@svg/group.svg';
import RoleIconName from '../acl/RoleIconName';

interface PrincipalListAccountTypeProps {
  principalName: string;
  roleName: string;
  roleAssignmentSources: RoleAssignmentSource[];
  hasWorkspaceOwnerRole: boolean;
  onClick?: VoidFunction;
}

const PermissionRoles: FC<PrincipalListAccountTypeProps> = ({
  principalName,
  roleName,
  roleAssignmentSources,
  hasWorkspaceOwnerRole,
  onClick,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const popoverContent =
    roleAssignmentSources.map((assignmentSource, index) => {
      return (
        <>
          <FlexGroupWrapper justifyContent="spaceBetween" gutterSize="s" alignItems="baseline" key={index}>
            <FlexItem grow={false}>
              <ButtonEmpty
                iconType={GroupIcon}
                onClick={() => {
                  navigate(`${Routes.accountsAndAccessGroups}/${assignmentSource.principalGroupId}`);
                }}
              >
                {assignmentSource.principalGroupName}
              </ButtonEmpty>
            </FlexItem>
          </FlexGroupWrapper>
        </>
      );
    }) ?? [];

  return (
    <>
      <FlexGroupWrapper gap="xs" direction="row" alignItems="center">
        <>
          <FlexItem grow={false}>
            <RoleIconName role={roleName ?? ''} onClick={hasWorkspaceOwnerRole ? onClick : undefined} />
          </FlexItem>
          {hasWorkspaceOwnerRole && (
            <>
              <FlexItem grow={false}>
                {popoverContent.length > 0 && (
                  <Popover
                    placement="bottom"
                    key={`${principalName}`}
                    data-testid="applied-details-items-popover"
                    title={'Role applied via group'}
                    trigger={
                      <>
                        {popoverContent.length > 0 && (
                          <FlexItem
                            onClick={(event) => {
                              event.stopPropagation();
                              setIsOpen(!isOpen);
                            }}
                          >
                            <ButtonIcon iconType={GroupIcon} size="xs" />
                          </FlexItem>
                        )}
                      </>
                    }
                    isOpen={isOpen}
                    body={<>{popoverContent}</>}
                    setIsOpen={() => {
                      setIsOpen(!isOpen);
                    }}
                  />
                )}
              </FlexItem>
            </>
          )}
        </>
      </FlexGroupWrapper>
    </>
  );
};

export default PermissionRoles;
