import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { Popover } from '@tecton/ComponentRedesign';

interface ItemSetPopoverProps {
  items: React.ReactNode[];
  target?: React.ReactNode;
  icon: React.ReactNode;
  highlightColor?: string;
  maxWidth?: string;
}

const CountPill = styled.div<{ items: React.ReactNode[]; highlightColor?: string }>(
  ({ theme, items, highlightColor }) => ({
    display: 'inline-flex',
    gap: theme.padding.xs,
    alignItems: 'center',
    cursor: items.length > 0 ? 'pointer' : 'default',
    color: items.length > 0 ? theme.colors.fullShade : theme.colors.lightShade,
    '& path, & ellipse, & rect, & circle': {
      stroke: items.length > 0 ? theme.colors.subduedText : theme.colors.lightShade,
    },
    ':hover': {
      textDecoration: items.length > 0 ? 'underline' : 'none',
      fontWeight: items.length > 0 ? theme.font.weight.semiBold : theme.font.weight.regular,

      '& path, & ellipse, & rect, & circle': {
        stroke: highlightColor,
      },
    },
  })
);

const ItemLine = styled.div(({ theme }) => ({
  padding: `${theme.padding.s} 0`,
}));

const ItemSetPopover: FC<ItemSetPopoverProps> = ({ items, target, icon, highlightColor, maxWidth = '256x' }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Popover
      data-testid="related-items-popover"
      body={
        <>
          {items.map((item, key) => {
            return <ItemLine key={key}>{item}</ItemLine>;
          })}
        </>
      }
      placement="bottom"
      setIsOpen={() => {
        setIsOpen(!isOpen);
      }}
      isOpen={items.length > 0 && isOpen}
      trigger={
        <CountPill
          items={items}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          highlightColor={highlightColor}
        >
          {icon && icon}
          {/* If we pass a targe, use it otherwise use the length */}
          {target ?? items.length}
        </CountPill>
      }
    />
  );
};

export default ItemSetPopover;
