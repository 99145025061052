import React, { FC } from 'react';
import { SelectItemProps } from './SelectItem';
import { FlexGroup, FlexItem, Text, SelectItem, useTectonTheme, Checkbox } from '.';
import { CheckboxState } from './Checkbox';
import styled from '@emotion/styled';

type SelectGroupSize = 's' | 'm';

export interface SelectGroupProps {
  title?: string;
  items: SelectItemProps[];
  filterQuery?: string;
  size?: SelectGroupSize;
  selectable?: boolean;
  state?: CheckboxState;
  onClick?: () => void;
}

const matchFilterQuery = (query: string | undefined, item: SelectItemProps) => {
  if (query === undefined) {
    return true;
  }

  const queryLowerCase = query.toLowerCase();

  return (
    item.name?.toString().toLowerCase().includes(queryLowerCase) ||
    item.description?.toLowerCase().includes(queryLowerCase)
  );
};

const SelectableGroupWrapper = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.v1.size['8']}px;
  padding: ${({ theme }) => theme.v1.size['1']}px ${({ theme }) => theme.v1.size['2']}px;

  border-radius: ${({ theme }) => theme.v1.size['1.5']}px;
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  cursor: pointer;
  color: ${({ theme }) => theme.v1.colors.text.title};
  font-weight: ${({ theme }) => theme.v1.font.weight.medium};

  &:hover {
    background-color: ${({ theme }) => theme.v1.colors.background.hover};
  }
`;

const SelectItemsWrapper = styled.div<{ selectable?: boolean }>`
  margin-left: ${({ theme, selectable }) => (selectable ? `${theme.v1.size['2']}px` : 0)};
  margin-bottom: ${({ theme, selectable }) => (selectable ? `${theme.v1.size['2']}px` : 0)};
`;

const SelectGroup: FC<SelectGroupProps> = ({
  title,
  items,
  filterQuery,
  size,
  selectable,
  state = 'off',
  onClick = () => {},
}) => {
  const { theme } = useTectonTheme();

  return (
    <FlexItem shrink="0" grow="0" data-testid="select-group">
      <FlexGroup direction="column" padding="0">
        <FlexItem shrink="0">
          {selectable && (
            <SelectableGroupWrapper onClick={onClick}>
              <FlexGroup padding="0" alignItems="center">
                {selectable && <Checkbox state={state} hideFocus />}{' '}
                {title && (
                  <Text color={theme.v1.colors.text.subduedText} weight="medium">
                    {title}
                  </Text>
                )}
              </FlexGroup>
            </SelectableGroupWrapper>
          )}
          {!selectable && (
            <Text color={theme.v1.colors.text.subduedText} weight="medium" size={size === 's' ? 'xs' : undefined}>
              {title}
            </Text>
          )}
        </FlexItem>
        <SelectItemsWrapper selectable={selectable}>
          {items
            .filter((item) => matchFilterQuery(filterQuery, item))
            .map((item) => {
              return <SelectItem {...item} />;
            })}
        </SelectItemsWrapper>
      </FlexGroup>
    </FlexItem>
  );
};

export default SelectGroup;
