import styled from '@emotion/styled';
import React, { FC } from 'react';
import { FlexGroup, FlexItem, HeaderLayout } from '.';
import { HeaderLayoutProps } from './HeaderLayout';

interface ViewLayoutProps {
  header: HeaderLayoutProps;
  content: React.ReactNode;
}

const Wrapper = styled.div`
  border-radius: ${({ theme }) => theme.v1.size['4']}px;
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  padding: 0px ${({ theme }) => theme.v1.size['6']}px ${({ theme }) => theme.v1.size['1']}px
    ${({ theme }) => theme.v1.size['6']}px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
`;

const ViewLayout: FC<ViewLayoutProps> = ({ header, content }) => {
  return (
    <Wrapper>
      <FlexGroup gapSize="1" direction="column" padding="4" css={{ height: '100%' }}>
        <FlexItem grow="0" shrink="0">
          <HeaderLayout {...header} />
        </FlexItem>
        <ContentWrapper>{content}</ContentWrapper>
      </FlexGroup>
    </Wrapper>
  );
};

export default ViewLayout;
