import React, { FC } from 'react';
import { Text } from '../';
import { default as Illustration } from './Illustration';
import styled from '@emotion/styled';
import Icon from './Icon';
import FlexGroup from './FlexGroup';

type EmptyPlacement = 'full-page' | 'embedded';
type EmptyUseCase = 'errorKnown' | 'errorUnknown' | 'error404' | 'permissions' | 'empty' | 'guide';

export interface EmptyProps {
  placement: EmptyPlacement;
  actions: React.ReactNode;
  useCase: EmptyUseCase;
  title: React.ReactNode;
  description: React.ReactNode;
}

const EmptyContainer = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
`;

const IllustrationContainer = styled.div`
  width: 350px;
`;

const IconContainer = styled.div`
  width: 56px;
  heigh: 56px;
`;

const Empty: FC<EmptyProps> = ({ placement, actions, useCase, title, description }) => {
  const IllustrationMap: Record<EmptyUseCase, React.ReactNode> = {
    errorKnown: <Illustration illustration={'IssueIllustration'} error />,
    errorUnknown: <Illustration illustration={'IssueIllustration'} />,
    error404: <Illustration illustration={'NoResultsIllustration'} />,
    permissions: <Illustration illustration={'CardIllustration'} error />,
    empty: <Illustration illustration={'NoResultsIllustration'} error />,
    guide: <Illustration illustration={'DataflowIllustration'} />,
  };

  const IconMap: Record<EmptyUseCase, React.ReactNode> = {
    errorKnown: <Icon icon={'GearCogs'} type={'color'} size={'m'} />,
    errorUnknown: <Icon icon={'QuestionInCircle'} type={'color'} size={'m'} />,
    error404: <Icon icon={'Search'} type={'color'} size={'m'} />,
    permissions: <Icon icon={'Lock'} type={'color'} size={'m'} />,
    empty: <Icon icon={'Search'} type={'color'} size={'m'} />,
    guide: <Icon icon={'FeatureBatch'} type={'color'} size={'m'} />,
  };

  if (placement === 'embedded') {
    return (
      <EmptyContainer data-testid="empty">
        <FlexGroup direction="column" justifyContent="center" alignItems="center">
          <IconContainer>{IconMap[useCase]}</IconContainer>
          <div>
            <Text element={'h5'}>{title}</Text>
            <Text weight="light" small>
              {description}
            </Text>
          </div>
          <FlexGroup direction="row" alignItems="center">
            {actions}
          </FlexGroup>
        </FlexGroup>
      </EmptyContainer>
    );
  }

  return (
    <EmptyContainer data-testid="empty">
      <FlexGroup direction="column" alignItems="center">
        <IllustrationContainer>{IllustrationMap[useCase]}</IllustrationContainer>

        <FlexGroup direction="column" gapSize="4" alignItems="center">
          <div>
            <Text element={'h4'}>{title}</Text>
            <Text weight="light">{description}</Text>
          </div>
          <FlexGroup direction="row" alignItems="center">
            {actions}
          </FlexGroup>
        </FlexGroup>
      </FlexGroup>
    </EmptyContainer>
  );
};

export default Empty;
