export enum Routes {
  root = '/',
  defaultProdHome = '/repo/prod/home',
  dashboard = '/repo/:workspace/home/',
  dataflow = '/repo/:workspace/home/*',
  commitDetails = '/repo/:workspace/home/apply-log/:planId',
  workspaceHome = '/repo/:workspace',
  featureViewDebug = '/debug/:workspace/features/:id',
  featureServiceDebug = '/debug/:workspace/feature-services/:id',
  dataSources = '/repo/:workspace/data-sources',
  /*  name/* Allows sub routes, for example :name/features  usually used in tabs */
  dataSource = '/repo/:workspace/data-sources/:name/*',
  entities = '/repo/:workspace/entities',
  transformations = '/repo/:workspace/transformations',
  /*  name/* Allows sub routes, for example :name/features  usually used in tabs */
  transformation = '/repo/:workspace/transformations/:name/*',
  entity = '/repo/:workspace/entities/:name/*',
  features = '/repo/:workspace/features/all',
  featureViews = '/repo/:workspace/features',
  featureMonitoringSummary = '/repo/:workspace/features/monitoring-summary',
  /*  name/* Allows sub routes, for example :name/features  usually used in tabs */
  featureView = '/repo/:workspace/features/:name/*',
  featuresByEntity = '/repo/:workspace/features/entity/:entityName',
  feature = '/repo/:workspace/features/:name/*',
  sloDashboard = '/repo/:workspace/feature-services/global-monitoring',
  featureServerMonitoring = '/repo/:workspace/feature-services/feature-server-monitoring',
  featureServices = '/repo/:workspace/feature-services',
  /*  name/* Allows sub routes, for example :name/features  usually used in tabs */
  featureService = '/repo/:workspace/feature-services/:name/*',
  savedFeatureDataFrames = '/repo/:workspace/datasets',
  planListIndex = '/repo/:workspace/plan-summary/',
  planSummary = '/:workspace/plan-summary/:planId/*',
  savedFeatureDataFrame = '/repo/:workspace/datasets/:name/overview',
  jobs = '/jobs',
  jobDetails = '/jobs/:jobId/*',
  debug = '/debug',
  featureAnalytics = '/feature-analytics',
  dataPlatformConfiguration = '/data-platform',
  computeOverview = '/data-platform/overview',
  computeEnvironments = '/data-platform/environments',
  computeEnvironmentDetails = '/data-platform/environments/:environmentId',
  computeIdentities = '/data-platform/compute-identities',
  serverGroups = '/data-platform/server-groups',
  dataPlatformEdit = '/data-platform/databricks/edit',
  onBoarding = '/onboarding',
  onBoardingChoosePlatform = '/onboarding/choose-platform',
  onBoardingSetupInProgress = '/onboarding/setup-in-progress',
  onBoardingConfigureDatabricks = '/onboarding/configure-databricks',
  onBoardingCreateRepo = '/onboarding/create-repo',
  onBoardingCreateDataSource = '/onboarding/create-data-source',
  onBoardingCreateHiveDataSource = '/onboarding/create-hive-data-source',
  onBoardingCreateFileDataSource = '/onboarding/create-file-data-source',
  onBoardingCreateBatchFeatureView = '/onboarding/create-batch-feature-view',
  onBoardingWelcome = '/onboarding/welcome',
  settings = '/settings',
  accessControlPrincipalUsers = '/settings/principals/*',
  selectWorkspace = '/select-workspace',
  accountAndAccess = '/settings/accounts-and-access',
  accountsAndAccessUsers = '/settings/accounts-and-access/users',
  accountsAndAccessUserProfile = '/settings/accounts-and-access/users/:user/*',
  accountsAndAccessGroups = '/settings/accounts-and-access/groups',
  accountsAndAccessGroupsProfile = '/settings/accounts-and-access/groups/:group/*',
  accountsAndAccessServiceAccounts = '/settings/accounts-and-access/service-accounts',
  accountsAndAccessServiceAccountProfile = '/settings/accounts-and-access/service-accounts/:serviceAccount/*',
  configurationAccessUsers = '/configurations/access/users',
  configurationAccessGroups = '/configurations/access/groups',
  configurationUsers = '/repo/:workspace/configurations/access/*',
  configurationAccessServiceAccounts = '/configurations/access/service-accounts',
  configurations = '/repo/:workspace/configurations/*',
  validations = '/repo/:workspace/data-quality',
  monitoring = '/repo/:workspace/monitoring/*',
  accessUsers = 'access/users',
  accessServiceAccounts = 'access/service-accounts',
  accessGroups = 'access/groups',
}

const firstClassObjectPaths = {
  entity: Routes.entity,
  data_source: Routes.dataSource,
  feature_view: Routes.featureView,
  feature: Routes.feature,
  feature_service: Routes.featureService,
  transformation: Routes.transformation,
  saved_feature_dataframe: Routes.savedFeatureDataFrame,
  archived_feature_view: Routes.featureView,
  archived_feature_service: Routes.featureService,
};

export { firstClassObjectPaths };
