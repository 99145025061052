import styled from '@emotion/styled';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { updateDateAndKeepTime, validMonthsForYearWithinBounds, validYearsWithinBounds } from './DatePickerUtils';
import { FlexGroup, FlexItem, SelectPopover, Button, Popover, Text, TimePicker } from '.';
import { DayPicker } from 'react-day-picker';

export const CalendarWrapper = styled.div`
  .rdp-root {
    --rdp-day-width: ${({ theme }) => theme.v1.size['8']}px;
    --rdp-day-height: ${({ theme }) => theme.v1.size['8']}px;
    --rdp-day_button-width: ${({ theme }) => theme.v1.size['8']}px;
    --rdp-day_button-width: ${({ theme }) => theme.v1.size['8']}px;

    user-select: none;
  }

  // Within-cell borders
  td,
  th {
    border-bottom-color: ${({ theme }) => theme.v1.colors.background.transparent};
  }

  // Styles for the entire month container
  .rdp-months {
    background-color: ${({ theme }) => theme.v1.colors.background.empty} !important;
    padding: 8px 12px;
  }

  // The month display at the top of the calendar
  .rdp-caption_label {
    color: ${({ theme }) => theme.v1.colors.text.title};
    font-size: ${({ theme }) => theme.v1.font.size.s};
    font-weight: ${({ theme }) => theme.v1.font.weight.medium};
  }

  // The name of the days at the top of the table
  .rdp-weekday {
    color: ${({ theme }) => theme.v1.colors.text.subduedText};
  }
`;

export const MonthYearPicker: FC<{
  month: Date;
  setMonth: React.Dispatch<React.SetStateAction<Date>>;
  minDate: Date;
  maxDate: Date;
}> = ({ month, setMonth, minDate, maxDate }) => {
  const [isMonthsOpen, setIsMonthsOpen] = useState<boolean>(false);
  const [isYearsOpen, setIsYearsOpen] = useState<boolean>(false);

  const currentMonth = month.toLocaleString('default', { month: 'long' });
  const currentYear = month.getFullYear();

  const months = validMonthsForYearWithinBounds(currentYear, minDate, maxDate);
  const years = validYearsWithinBounds(minDate, maxDate);

  const priorMonth = moment(month).add('-1', 'month').endOf('month').toDate();
  const nextMonth = moment(month).add('1', 'month').startOf('month').toDate();

  return (
    <FlexGroup padding="0" justifyContent="space-between" alignItems="center">
      <FlexItem grow="0">
        <FlexGroup padding="0">
          <FlexItem>
            <SelectPopover
              label={currentMonth}
              isOpen={isMonthsOpen}
              setIsOpen={setIsMonthsOpen}
              groups={[
                {
                  title: 'Month',
                  items: months.map((iteratedMonth) => {
                    return {
                      onClick: () => {
                        const newDate = moment(`${iteratedMonth} ${currentYear}`, 'MMMM YYYY')
                          .startOf('month')
                          .toDate();
                        setMonth(newDate);
                        setIsMonthsOpen(false);
                      },
                      name: iteratedMonth,
                    };
                  }),
                },
              ]}
            />
          </FlexItem>
          <FlexItem>
            <SelectPopover
              label={currentYear.toString()}
              isOpen={isYearsOpen}
              setIsOpen={setIsYearsOpen}
              groups={[
                {
                  title: 'Year',
                  items: years.map((year) => {
                    return {
                      onClick: () => {
                        const newDate = moment(`${currentMonth} ${year}`, 'MMMM YYYY').startOf('month').toDate();
                        setMonth(newDate);
                        setIsYearsOpen(false);
                      },
                      name: year.toString(),
                    };
                  }),
                },
              ]}
            />
          </FlexItem>
        </FlexGroup>
      </FlexItem>
      <FlexItem grow="0">
        <FlexItem>
          <FlexGroup padding="0" alignItems="center">
            <Button
              icon="LargeChevronLeft"
              size="s"
              type="secondary"
              state={priorMonth.getTime() < minDate.getTime() ? 'disabled' : 'default'}
              onClick={(event) => {
                event?.stopPropagation();
                setMonth(priorMonth);
              }}
            />
            <Button
              icon="LargeChevronRight"
              size="s"
              type="secondary"
              state={nextMonth.getTime() > maxDate.getTime() ? 'disabled' : 'default'}
              onClick={(event) => {
                event?.stopPropagation();
                setMonth(nextMonth);
              }}
            />
          </FlexGroup>
        </FlexItem>
      </FlexItem>
    </FlexGroup>
  );
};

const StyledDay = styled.div<{ disabled: boolean; isToday: boolean; isSelectedDay: boolean }>`
  width: ${({ theme }) => theme.v1.size['8']}px;
  height: ${({ theme }) => theme.v1.size['8']}px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ theme, disabled, isSelectedDay }) => {
    if (isSelectedDay) {
      return theme.v1.colors.text.invertedText;
    }

    return disabled ? theme.v1.colors.text.disabledText : theme.v1.colors.text.link;
  }};

  border-radius: ${({ theme }) => theme.v1.size['2']}px;

  background-color: ${({ theme, isToday, isSelectedDay }) => {
    if (isSelectedDay) {
      return theme.v1.colors.background.primary;
    }

    return isToday ? theme.v1.colors.background.active : theme.v1.colors.background.empty;
  }};

  font-size: ${({ theme, isSelectedDay }) => (isSelectedDay ? theme.v1.font.size.s : theme.v1.font.size.xs)};
  font-weight: ${({ theme, isSelectedDay }) =>
    isSelectedDay ? theme.v1.font.weight.medium : theme.v1.font.weight.regular};

  &:hover {
    background-color: ${({ theme, disabled, isSelectedDay }) => {
      if (isSelectedDay) {
        return theme.v1.colors.background.primary;
      }

      return disabled ? theme.v1.colors.background.empty : theme.v1.colors.background.hover;
    }};
    color: ${({ theme, disabled, isSelectedDay }) => {
      if (isSelectedDay) {
        return theme.v1.colors.text.invertedText;
      }
      return disabled ? theme.v1.colors.text.disabledText : theme.v1.colors.text.link;
    }};
  }
`;

export const DatePickerDay: FC<{
  day: Date;
  isOutsideRange: boolean;
  currentDate: Date;
  setCurrentDate: (date: Date) => void;
  minDate: Date;
  maxDate: Date;
}> = ({ day, isOutsideRange, currentDate, setCurrentDate, minDate, maxDate }) => {
  const isBefore = moment(day).startOf('day').toDate().getTime() < minDate.getTime();
  const isAfter = moment(day).endOf('day').toDate().getTime() > maxDate.getTime();
  const isDisabled = isOutsideRange || isBefore || isAfter;
  const isToday = moment(day).startOf('day').toDate().getTime() === moment().startOf('day').toDate().getTime();
  const isSelectedDay =
    moment(day).startOf('day').toDate().getTime() === moment(currentDate).startOf('day').toDate().getTime();

  return (
    <td className="rdp-day">
      <StyledDay
        disabled={isDisabled}
        role="button"
        onClick={(event) => {
          event.stopPropagation();
          if (isDisabled) return;
          setCurrentDate(updateDateAndKeepTime(currentDate, day));
        }}
        isToday={isToday}
        isSelectedDay={isSelectedDay}
      >
        <FlexGroup justifyContent="center" alignItems="center" padding="0">
          <div>{day.getDate()}</div>
        </FlexGroup>
      </StyledDay>
    </td>
  );
};

interface DatePickerCalendarProps {
  allowTimeSelection?: boolean;
  title: React.ReactNode;
  month: Date;
  setMonth: React.Dispatch<React.SetStateAction<Date>>;
  currentDate: Date;
  setCurrentDate: (date: Date) => void;
  minDate: Date;
  maxDate: Date;
}

// This prevents clicking on the calendar from settting isEditing in the parent component
// This is necessary because the parent component has an onClick listener to make it easier to access
const PreventClickFromCascading = styled.div``;

export const DatePickerCalendar: FC<DatePickerCalendarProps> = ({
  allowTimeSelection,
  title,
  month,
  setMonth,
  currentDate,
  setCurrentDate,
  minDate,
  maxDate,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <PreventClickFromCascading onClick={(event) => event.stopPropagation()}>
      <Popover
        maxWidth={640}
        trigger={
          <Button
            iconLeft="Calendar"
            type="ghost"
            onClick={(event) => {
              event?.stopPropagation();
              setIsOpen(!isOpen);
            }}
          />
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        body={
          <>
            {' '}
            <CalendarWrapper
              onClick={(event) => {
                event.stopPropagation;
              }}
            >
              {title && <Text element="h4">{title}</Text>}
              <FlexGroup padding="0" gapSize="1">
                <FlexItem>
                  <FlexGroup direction="column" padding="1" gapSize="1">
                    <MonthYearPicker month={month} setMonth={setMonth} minDate={minDate} maxDate={maxDate} />

                    <DayPicker
                      required
                      month={month}
                      mode="single"
                      selected={currentDate}
                      onSelect={setCurrentDate}
                      hidden={{
                        before: minDate ?? new Date(2000),
                        after: maxDate ?? new Date(2038, 1, 19),
                      }}
                      components={{
                        Chevron: () => <></>,
                        MonthCaption: () => <></>,
                        Day: (props) => {
                          return (
                            <DatePickerDay
                              day={props.day.date}
                              isOutsideRange={props.day.outside}
                              currentDate={currentDate}
                              setCurrentDate={setCurrentDate}
                              minDate={minDate}
                              maxDate={maxDate}
                            />
                          );
                        },
                      }}
                    />
                  </FlexGroup>
                </FlexItem>
                <FlexItem>
                  {allowTimeSelection && <TimePicker currentDate={currentDate} setCurrentDate={setCurrentDate} />}
                </FlexItem>
              </FlexGroup>
            </CalendarWrapper>
          </>
        }
      />
    </PreventClickFromCascading>
  );
};
