import React, { FC, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import { Routes } from '../../../core/routes';
import { Link } from 'react-router-dom';

// generated code
import { ServiceAccount } from '../../../api/gql/graphql';

// @tecton
import {
  SearchAndActionButton,
  EmptyPrompt,
  ButtonIcon,
  EuiBasicTableColumn,
  Table,
  FlexGroupWrapper,
  FlexItem,
  AvatarAndName,
  Tooltip,
} from '@tecton/ComponentRedesign';

// tecton icons

import { ReactComponent as TrashIcon } from '@svg/trash.svg';

export interface ACLGroupServiceAccountsListProps {
  assignedServiceAccounts: ServiceAccount[];
  isAdminView: boolean;
  onDeleteServiceAccount: (serviceAccounts: ServiceAccount) => void;
  onAddonAddServiceAccount: () => void;
  isLoading?: boolean;
}

// Errors and loading state should handler out side if this components.
const ACLGroupServiceAccountsList: FC<ACLGroupServiceAccountsListProps> = ({
  assignedServiceAccounts,
  isAdminView,
  onDeleteServiceAccount,
  onAddonAddServiceAccount,
  isLoading,
}) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const filteredWorkspaceRole = searchText
    ? assignedServiceAccounts?.filter((serviceAccount: ServiceAccount) => {
        return serviceAccount.name?.toLowerCase()?.includes(searchText?.toLowerCase());
      })
    : assignedServiceAccounts;

  const sortedServiceAccounts = isLoading ? undefined : _sortBy(filteredWorkspaceRole, ['name']);

  const columns: EuiBasicTableColumn<ServiceAccount>[] = [
    {
      name: 'Service Account',
      render: (serviceAccount: ServiceAccount) => {
        return (
          <Link to={`${Routes.accountsAndAccessServiceAccounts}/${serviceAccount.id}/workspaces`}>
            <AvatarAndName name={serviceAccount.name ?? ''} icon={'Robot'} type="icon" />
          </Link>
        );
      },
    },
    {
      name: 'Actions',
      actions: [
        {
          name: 'remove',
          isPrimary: true,
          render: (serviceAccount: ServiceAccount) => {
            return (
              <>
                <Tooltip
                  content={`Remove ${serviceAccount?.name} from Group.`}
                  trigger={
                    <>
                      <ButtonIcon
                        variant="dangerousAction"
                        iconType={TrashIcon}
                        color="ghost"
                        size="xs"
                        onClick={() => {
                          onDeleteServiceAccount(serviceAccount);
                        }}
                      />
                    </>
                  }
                />
              </>
            );
          },
        },
      ],
    },
  ];

  return (
    <FlexGroupWrapper gap="s" direction="column">
      <FlexItem>
        <SearchAndActionButton
          searchPlaceHolder={'Search for Service Account'}
          actionButtonLabel={'Add Service Account To Group'}
          actionButtonVariant={isAdminView ? 'primaryAction' : 'disabledAction'}
          onActionButtonClick={() => {
            onAddonAddServiceAccount();
          }}
          onSearchInputChange={function (search: string): void {
            setSearchText(search ?? undefined);
          }}
        />
      </FlexItem>
      <FlexItem>
        {/* The list will never be empty */}
        <Table
          items={sortedServiceAccounts ?? []}
          columns={columns}
          emptyPrompt={
            <>
              {assignedServiceAccounts.length === 0 && (
                <EmptyPrompt
                  title={<>This Group is Empty</>}
                  body={
                    <>
                      <p>
                        To add a user to this group click the 'Add Service Account to Group' button in the upper right
                        corner.
                      </p>
                    </>
                  }
                />
              )}
              {assignedServiceAccounts.length > 0 && (
                <EmptyPrompt
                  title={<>No Results</>}
                  body={
                    <>
                      <p>The search filter returned no results.</p>
                    </>
                  }
                />
              )}
            </>
          }
        />
      </FlexItem>
    </FlexGroupWrapper>
  );
};

export default ACLGroupServiceAccountsList;
