import React, { useState } from 'react';

import {
  EuiSelectableGroupLabelOption,
  EuiSelectableLIOption,
} from '@elastic/eui/src/components/selectable/selectable_option';
import { ColumnsButton, Popover, Selectable } from '@tecton/ComponentRedesign';
import { ExclusiveUnion } from '@elastic/eui';

interface SelectableOptionInterface {
  columnKey: string;
  label: string;
  checked?: 'on';
}

export type ColumnsOptionType = ExclusiveUnion<
  EuiSelectableGroupLabelOption<SelectableOptionInterface>,
  EuiSelectableLIOption<SelectableOptionInterface>
>;

interface ColumnDisplayControlsProps {
  isColumnControlsOpen: boolean;
  setIsColumnControlsOpen: (isOpen: boolean) => void;
  columnOptions: ColumnsOptionType[];
  numberOfSelectedColumnsOptions: number;
  setColumnOptions: (items: ColumnsOptionType[]) => void;
}

const parseLocalStorageColumnOptions = (
  data: object,
  initialState: ColumnsOptionType[]
): ColumnsOptionType[] | undefined => {
  if (Array.isArray(data)) {
    const keys: Record<string, 'on' | undefined> = {};

    data.forEach((d: any) => {
      if (
        typeof d === 'object' &&
        d.columnKey &&
        typeof d.columnKey === 'string' &&
        (d.checked === 'on' || d.checked === undefined)
      ) {
        keys[d.columnKey] = d.checked;
      }
    });

    return initialState.map((d): ColumnsOptionType => {
      return {
        label: d.label,
        columnKey: d.columnKey,
        checked: keys[d.columnKey] || undefined,
      };
    });
  } else {
    return undefined;
  }
};

const fetchFromLocalStorage = (key: string, initialState: ColumnsOptionType[]): ColumnsOptionType[] | undefined => {
  const storedValue = window.localStorage.getItem(key);
  return storedValue !== null ? parseLocalStorageColumnOptions(JSON.parse(storedValue), initialState) : initialState;
};

const useColumnsDisplayControlsState = (tableKey: string, initialState?: ColumnsOptionType[], disable?: boolean) => {
  const [isColumnControlsOpen, setIsColumnControlsOpen] = useState<boolean>(false);

  const localStorageState = disable ? null : fetchFromLocalStorage(tableKey, initialState || []);

  const [columnOptions, unwrappedSetColumnOptions] = useState<ColumnsOptionType[]>(
    localStorageState || initialState || []
  );

  const setColumnOptions = (options: ColumnsOptionType[]) => {
    window.localStorage.setItem(tableKey, JSON.stringify(options));

    unwrappedSetColumnOptions(options);
  };

  const numberOfSelectedColumnsOptions: number = columnOptions.reduce((memo, current) => {
    return current.checked === 'on' ? memo + 1 : memo;
  }, 0);

  const activeColumns: string[] = columnOptions.filter((o) => o.checked === 'on').map((o) => o.columnKey);

  return {
    isColumnControlsOpen,
    setIsColumnControlsOpen,
    columnOptions,
    setColumnOptions,
    numberOfSelectedColumnsOptions,
    activeColumns,
  };
};

export const ColumnDisplayControls = ({
  isColumnControlsOpen,
  setIsColumnControlsOpen,
  columnOptions,
  setColumnOptions,
}: ColumnDisplayControlsProps) => {
  return (
    <Popover
      body={
        <Selectable
          key={''}
          options={columnOptions}
          changeCallback={(newOptions) => {
            setColumnOptions(newOptions);
          }}
          renderOption={(item) => {
            return <>{item.label}</>;
          }}
          height={'200px'} // TODO: This is now required due to changes to Selectable
          width={'300px'} // But it shouldn't be. Need to review and get default sizes for that
        />
      }
      isOpen={isColumnControlsOpen}
      setIsOpen={() => {
        setIsColumnControlsOpen(!isColumnControlsOpen);
      }}
      placement="bottom"
      trigger={
        <ColumnsButton
          onClick={() => {
            setIsColumnControlsOpen(!isColumnControlsOpen);
          }}
          isDisabled={columnOptions.length === 0}
          isActive={isColumnControlsOpen}
        />
      }
    />
  );
};

export default ColumnDisplayControls;
export { useColumnsDisplayControlsState };
