import React, { FC, useEffect, useRef, useState } from 'react';
import { Avatar, FlexGroup, FlexItem, Icon, IconType, Tooltip } from '.';
import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { IconSize } from './Icon';
import { Theme } from '@emotion/react';
import Badge, { BadgeProps } from './Badge';
import { AvatarProps } from './Avatar';

type LinkSize = 's' | 'm';
type LinkVariants = 'primary' | 'primary-danger' | 'secondary' | 'secondary-danger';

interface LinkProps {
  name: React.ReactNode;
  icon?: IconType;
  iconRight?: IconType;
  url?: string;
  size?: LinkSize;
  disabled?: boolean;
  variant?: LinkVariants;
  shortcut?: string;
  tooltip?: string;
  badge?: BadgeProps;
  avatar?: AvatarProps;
  iconType?: 'color' | 'mono';
}

const getColorByVariant = (theme: Theme, variant: LinkVariants) => {
  const ColorMap: Record<LinkVariants, string> = {
    primary: theme.v1.colors.text.link,
    'primary-danger': theme.v1.colors.text.dangerText,
    secondary: theme.v1.colors.text.text,
    'secondary-danger': theme.v1.colors.text.dangerDisabledText,
  };

  return ColorMap[variant];
};

const LinkWrapper = styled.div<{ size: LinkSize; disabled: boolean; variant: LinkVariants }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: ${({ theme, disabled, variant }) =>
    disabled ? theme.v1.colors.text.disabledText : getColorByVariant(theme, variant)};
  font-size: ${({ theme, size }) => {
    return size === 'm' ? theme.v1.font.size.s : theme.v1.font.size.xs;
  }};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  text-decoration: none !important;
`;

const Shortcut = styled.span`
  color: ${({ theme }) => theme.v1.colors.text.subduedText};
`;

interface LinkContentProps extends LinkProps {
  textRef: React.RefObject<HTMLDivElement>;
}

const LinkContent: FC<LinkContentProps> = ({
  name,
  icon,
  size = 'm',
  disabled = false,
  variant = 'primary',
  iconRight,
  shortcut,
  tooltip,
  badge,
  textRef,
  iconType = 'mono',
  avatar,
}) => {
  const IconSizeMap: Record<LinkSize, IconSize> = {
    s: 'xs',
    m: 's',
  };

  return (
    <FlexGroup gapSize="1" alignItems="center" padding="0">
      {icon && (
        <FlexItem shrink={0} grow="0">
          <FlexGroup alignItems="center" padding="0">
            <Icon icon={icon} size={IconSizeMap[size]} type={iconType} />
          </FlexGroup>
        </FlexItem>
      )}

      {avatar && (
        <FlexItem shrink={0} grow="0">
          <FlexGroup alignItems="center" padding="0">
            <Avatar {...avatar} size="s" />
          </FlexGroup>
        </FlexItem>
      )}
      <FlexItem grow="0">
        <LinkWrapper size={size} ref={textRef} disabled={disabled} variant={variant} className="linkName">
          {name}
        </LinkWrapper>
      </FlexItem>
      {shortcut && (
        <FlexItem shrink={1} grow="0">
          <Shortcut>⌘{shortcut}</Shortcut>
        </FlexItem>
      )}
      {iconRight && (
        <FlexItem shrink={0} grow="0">
          <FlexGroup alignItems="center" padding="0">
            <Icon icon={iconRight} type={iconType} size={IconSizeMap[size]} />
          </FlexGroup>
        </FlexItem>
      )}

      {badge && (
        <FlexItem shrink={0} grow="0">
          <FlexGroup alignItems="center" padding="0">
            <Badge {...badge} size="s" />
          </FlexGroup>
        </FlexItem>
      )}

      {tooltip && (
        <FlexItem shrink={0} grow="0">
          <FlexGroup alignItems="center" padding="0">
            <Tooltip trigger={<Icon type="mono" size="s" icon="Information" />} content={tooltip} />
          </FlexGroup>
        </FlexItem>
      )}
    </FlexGroup>
  );
};

const StyledRouterLink = styled(RouterLink)`
  * {
    text-decoration: none !important;
  }

  &:hover {
    text-decoration: none !important;

    .linkName {
      text-decoration: underline !important;
    }
  }
`;

const Link: FC<LinkProps> = ({
  name,
  icon,
  url,
  size = 'm',
  disabled = false,
  variant = 'primary',
  iconRight,
  shortcut,
  tooltip,
  badge,
  iconType = 'mono',
  avatar,
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      const { scrollWidth, offsetWidth } = textRef.current;
      setIsTruncated(scrollWidth > offsetWidth);
    }
  }, [textRef]);

  return (
    <div data-test-id="link">
      <Tooltip
        disabled={!isTruncated}
        trigger={
          <>
            {!disabled && (
              <>
                {' '}
                <StyledRouterLink to={url ?? '#'}>
                  <LinkContent
                    name={name}
                    icon={icon}
                    size={size}
                    disabled={disabled}
                    variant={variant}
                    shortcut={shortcut}
                    iconRight={iconRight}
                    tooltip={tooltip}
                    badge={badge}
                    textRef={textRef}
                    iconType={iconType}
                    avatar={avatar}
                  />
                </StyledRouterLink>
              </>
            )}
            {disabled && (
              <>
                {' '}
                <LinkContent
                  name={name}
                  icon={icon}
                  size={size}
                  disabled={disabled}
                  variant={variant}
                  shortcut={shortcut}
                  iconRight={iconRight}
                  tooltip={tooltip}
                  badge={badge}
                  textRef={textRef}
                  iconType={'mono'}
                  avatar={avatar}
                />
              </>
            )}
          </>
        }
        content={name}
      />
    </div>
  );
};

export default Link;
