import React, { FC, useEffect, useState } from 'react';
import { FlexGroup, FlexItem, Button, Text, useTectonTheme } from '.';
import styled from '@emotion/styled';
import 'react-day-picker/style.css';
import { range } from 'lodash';
import moment from 'moment';
import { DatePickerCalendar } from './DatePickerCalendar';
import DatePickerInputs from './DatePickerInputs';

interface DatePickerProps {
  label?: React.ReactNode;
  currentDate: Date | null | undefined;
  setCurrentDate: (date: Date | null | undefined) => void;
  maxDate?: Date;
  minDate?: Date;
  allowTimeSelection?: boolean;
  title?: React.ReactNode;
  beforeMinDateMessage?: React.ReactNode;
  afterMaxDateMessage?: React.ReactNode;
}

const DEFAULT_MIN_DATE = new Date(2018, 1, 1); // Default to earliest Tecton date
const DEFAULT_MAX_DATE = new Date(2038, 1, 19); // Default to max Unix date

const getHalfHourIntervals = (): string[] => {
  const start = moment().startOf('day');

  return range(0, 48).map((rangeIndex) =>
    start
      .clone()
      .add(rangeIndex * 30, 'minutes')
      .format('HH:mm')
  );
};

const TimePanel = styled.div`
  height: ${({ theme }) => theme.v1.size['64']}px;
  overflow: scroll;
  border-left: 1px solid ${({ theme }) => theme.v1.colors.border.default};
  padding-left: ${({ theme }) => theme.v1.size['2']}px;
  padding-right: ${({ theme }) => theme.v1.size['4']}px;
`;

export const TimePicker: FC<{ currentDate: Date; setCurrentDate: (date: Date) => void }> = ({
  currentDate,
  setCurrentDate,
}) => {
  return (
    <TimePanel>
      <FlexGroup padding="0" direction="column">
        {getHalfHourIntervals().map((interval) => {
          const hours = interval.split(':')[0];
          const minutes = interval.split(':')[1];

          const selectableDate = moment(currentDate)
            .hours(+hours)
            .minutes(+minutes)
            .toDate();
          return (
            <Button
              size="s"
              type={currentDate.getTime() === selectableDate.getTime() ? 'primary' : 'secondary'}
              label={interval}
              onClick={() => {
                setCurrentDate(selectableDate);
              }}
            />
          );
        })}
      </FlexGroup>
    </TimePanel>
  );
};

const Label = styled.div`
  font-size: ${({ theme }) => theme.v1.font.size.xs};
  font-weight: ${({ theme }) => theme.v1.font.weight.medium};
`;

const PseudoInput = styled.div<{ validationState: DateValidationStates }>`
  cursor: pointer;
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  border: ${({ theme, validationState }) => {
    if (validationState === 'in progress') {
      return `1px solid ${theme.v1.colors.border.focus}`;
    }

    if (validationState === 'too early' || validationState === 'too late' || validationState === 'not a date') {
      return `1px solid ${theme.v1.colors.text.dangerText}`;
    }

    if (validationState === 'valid') {
      return `1px solid ${theme.v1.colors.text.successText}`;
    }

    return `1px solid ${theme.v1.colors.border.default}`;
  }};
  border-radius: ${({ theme }) => theme.v1.size['2']}px;
  padding: ${({ theme }) => `0px  ${theme.v1.size['2']}px 0px 0px`};

  height: ${({ theme }) => theme.v1.size['8']}px;
  overflow: hidden;

  &:hover {
    background-color: ${({ theme }) => theme.v1.colors.background.hover};
  }

  width: 200px;
`;

export type DateValidationStates = 'untouched' | 'in progress' | 'too early' | 'too late' | 'not a date' | 'valid';

const DatePicker: FC<DatePickerProps> = ({
  label,
  currentDate,
  setCurrentDate,
  maxDate = DEFAULT_MAX_DATE,
  minDate = DEFAULT_MIN_DATE,
  allowTimeSelection,
  title,
  beforeMinDateMessage = 'That date is too early',
  afterMaxDateMessage = 'That date is too late',
}) => {
  let initialLabel = allowTimeSelection ? 'MM/DD/YYYY HH:mm' : 'MM/DD/YYYY';

  if (currentDate) {
    if (allowTimeSelection) {
      initialLabel = moment(currentDate).format('MM/DD/YYYY HH:mm');
    } else {
      initialLabel = moment(currentDate).format('MM/DD/YYYY');
    }
  }

  const [month, setMonth] = useState<Date | null | undefined>(currentDate);
  const [inputLabel, setLabel] = useState<string>(initialLabel);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [validationState, setValidationState] = useState<DateValidationStates>('untouched');
  const [currentLabel, setCurrentLabel] = useState<React.ReactNode>(label);

  const { theme } = useTectonTheme();

  useEffect(() => {
    if (currentDate === null) {
      setValidationState('not a date');
      return;
    }
    if (currentDate) {
      setMonth(moment(currentDate).startOf('month').toDate());
      if (allowTimeSelection) {
        setLabel(moment(currentDate).format('MM/DD/YYYY HH:mm'));
        setValidationState('untouched');
      } else {
        setLabel(moment(currentDate).format('MM/DD/YYYY'));
        setValidationState('untouched');
      }
    }
  }, [allowTimeSelection, currentDate]);

  return (
    <div data-testid="date-picker">
      <FlexGroup direction="column" padding="0" justifyContent="flex-start">
        <Label>{currentLabel ?? <> </>}</Label>
        <FlexItem>
          <PseudoInput
            onClick={() => {
              setIsEditing(true);
            }}
            validationState={validationState}
          >
            <FlexGroup padding="0" justifyContent="space-between">
              {!isEditing && (
                <>
                  <Button
                    type="ghost"
                    label={inputLabel}
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  />
                </>
              )}
              {isEditing && (
                <DatePickerInputs
                  date={currentDate}
                  min={minDate}
                  max={maxDate}
                  onCancel={() => {
                    setIsEditing(false);
                  }}
                  onChange={(date) => {
                    setCurrentDate(date);
                    setIsEditing(false);
                    setCurrentLabel(label);
                  }}
                  onValidationStateChange={setValidationState}
                  allowTimeSelection={allowTimeSelection}
                />
              )}
              <FlexItem grow="0">
                <DatePickerCalendar
                  title={title}
                  month={month ?? new Date()}
                  setMonth={setMonth as React.Dispatch<React.SetStateAction<Date>>}
                  currentDate={currentDate ?? new Date()}
                  setCurrentDate={setCurrentDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  allowTimeSelection={allowTimeSelection}
                />
              </FlexItem>
            </FlexGroup>
          </PseudoInput>
        </FlexItem>
        {validationState === 'untouched' && <Text size="xs">Select a date.</Text>}
        {validationState === 'in progress' && <Text size="xs">Select a date.</Text>}
        {validationState === 'valid' && (
          <Text size="xs" color={theme.v1.colors.text.successText}>
            Valid date.
          </Text>
        )}
        {validationState === 'too early' && (
          <Text size="xs" color={theme.v1.colors.text.dangerText}>
            {beforeMinDateMessage}
          </Text>
        )}
        {validationState === 'too late' && (
          <Text size="xs" color={theme.v1.colors.text.dangerText}>
            {afterMaxDateMessage}
          </Text>
        )}
        {validationState === 'not a date' && (
          <Text size="xs" color={theme.v1.colors.text.dangerText}>
            Not a valid date
          </Text>
        )}
      </FlexGroup>
    </div>
  );
};

export default DatePicker;
