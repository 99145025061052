import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
  getValidationStateForDateWithMinAndMax,
  handleDayKeyDown,
  handleHourKeyDown,
  handleMinuteKeyDown,
  handleMonthKeyDown,
  handleYearKeyDown,
} from './DatePickerUtils';
import moment from 'moment';
import { DateValidationStates } from './DatePicker';
import { Tooltip } from '.';

const InputContainer = styled.div<{ isValidated?: DateValidationStates }>`
  display: inline-flex;
  align-items: center;
  padding: ${({ theme }) => theme.v1.size['0']}px ${({ theme }) => theme.v1.size['5']}px;
`;

const DatePartInput = styled.input<{ maxLength: number }>`
  border: none;
  outline: none;
  width: ${({ maxLength }) => maxLength}ch;
  text-align: center;
  font-size: ${({ theme }) => theme.v1.font.size.xs};
  background-color: ${({ theme }) => theme.v1.colors.background.transparent};
  color: ${({ theme }) => theme.v1.colors.text.link};

  &:focus {
    background-color: ${({ theme }) => theme.v1.colors.background.activeFocus};
  }

  &:placeholder {
    color: ${({ theme }) => theme.v1.colors.text.disabledText};
  }
`;

const Separator = styled.span`
  margin: 0 2px;
`;

interface DateInputProps {
  date?: Date | null;
  max?: Date;
  min?: Date;
  onCancel: () => void;
  onChange?: (date: Date | null) => void;
  onValidationStateChange?: (state: DateValidationStates) => void;
  allowTimeSelection?: boolean;
}

const isValidDate = (dateString: string) => {
  return moment(dateString, 'M/D/YYYY', true).isValid() || moment(dateString, 'M/D/YYYY H:m', true).isValid();
};

const DatePickerInputs: React.FC<DateInputProps> = ({
  date,
  max,
  min,
  onChange,
  onCancel,
  onValidationStateChange,
  allowTimeSelection,
}) => {
  const [isDateValid, setIsDateValid] = useState<DateValidationStates>('in progress');
  const initialMonth = date ? (date.getMonth() + 1).toString() : undefined;
  const initialDays = date ? date.getDate().toString() : undefined;
  const initialYear = date ? date.getFullYear().toString() : undefined;
  const initialHour = date ? date.getHours().toString() : undefined;
  const initialMinute = date ? date.getMinutes().toString() : undefined;

  const [month, setMonth] = useState<string | undefined>(initialMonth);
  const [day, setDay] = useState<string | undefined>(initialDays);
  const [year, setYear] = useState<string | undefined>(initialYear);
  const [hour, setHour] = useState<string | undefined>(initialHour);
  const [minute, setMinute] = useState<string | undefined>(initialMinute);
  const [focusedPart, setFocusedPart] = useState<'month' | 'day' | 'year' | 'hour' | 'minute'>('month');
  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);
  const hourRef = useRef<HTMLInputElement>(null);
  const minuteRef = useRef<HTMLInputElement>(null);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const nextElement = event.relatedTarget as HTMLElement;
    if (nextElement === null) {
      callOnChangeIfDateIsValid();
      onCancel();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const keyedValue = e.key;
    let newValue: string | undefined;

    // Accept or cancel results
    if (keyedValue === 'Enter') {
      callOnChangeIfDateIsValid();
      return;
    }

    if (keyedValue === 'Escape') {
      onCancel();
      return;
    }

    // Individual key handlers
    if (focusedPart === 'month') {
      newValue = handleMonthKeyDown(month, keyedValue, setFocusedPart) as string | undefined;
      setMonth(newValue);
    }

    if (focusedPart === 'day') {
      newValue = handleDayKeyDown(day, keyedValue, setFocusedPart, e.shiftKey) as string | undefined;
      setDay(newValue);
    }

    if (focusedPart === 'year') {
      newValue = handleYearKeyDown(year, keyedValue, setFocusedPart, e.shiftKey, allowTimeSelection) as
        | string
        | undefined;

      setYear(newValue);
    }

    if (focusedPart === 'hour') {
      newValue = handleHourKeyDown(hour, keyedValue, setFocusedPart, e.shiftKey) as string | undefined;

      setHour(newValue);
    }

    if (focusedPart === 'minute') {
      newValue = handleMinuteKeyDown(minute, keyedValue, setFocusedPart, e.shiftKey) as string | undefined;

      setMinute(newValue);
    }
  };

  // Manage focus on selection
  useEffect(() => {
    if (focusedPart === 'month') {
      monthRef.current?.focus();
    }

    if (focusedPart === 'day') {
      dayRef.current?.focus();
    }

    if (focusedPart === 'year') {
      yearRef.current?.focus();
    }

    if (focusedPart === 'hour') {
      hourRef.current?.focus();
    }

    if (focusedPart === 'minute') {
      minuteRef.current?.focus();
    }
  }, [focusedPart]);

  const callOnChangeIfDateIsValid = () => {
    const dateString = allowTimeSelection ? `${month}/${day}/${year} ${hour}:${minute}` : `${month}/${day}/${year}`;

    const checkDateValidity = isValidDate(dateString);

    if (checkDateValidity) {
      if (
        getValidationStateForDateWithMinAndMax({ date: new Date(dateString), min: min, max: max }) === 'valid' &&
        onChange
      )
        onChange && onChange(new Date(dateString));
    } else {
      onChange && onChange(null);
    }
  };

  // Handle validation state change regardless of where it comes from
  useEffect(() => {
    onValidationStateChange && onValidationStateChange(isDateValid);
  }, [isDateValid, onValidationStateChange, setIsDateValid]);

  // VCalls validation
  useEffect(() => {
    const dateString = `${month}/${day}/${year}`;

    const checkDateValidity = isValidDate(dateString);
    if (checkDateValidity) {
      setIsDateValid(getValidationStateForDateWithMinAndMax({ date: new Date(dateString), min: min, max: max }));
    } else {
      setIsDateValid('not a date');
    }
  }, [month, day, year, min, max, allowTimeSelection, hour, minute]);

  return (
    <InputContainer isValidated={isDateValid}>
      <>
        <DatePartInput
          ref={monthRef}
          type="text"
          value={month || ''}
          placeholder="MM"
          maxLength={2}
          onFocus={() => {
            setFocusedPart('month');
          }}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
        {focusedPart === 'month' && (
          <Tooltip
            placement="top"
            offsetSize={10}
            trigger={<></>}
            openByDefault
            content={<>Month in MM format (01-12)</>}
          />
        )}
      </>
      <Separator>/</Separator>
      <>
        <DatePartInput
          type="text"
          value={day || ''}
          ref={dayRef}
          placeholder="DD"
          maxLength={2}
          onBlur={handleBlur}
          onFocus={() => {
            setFocusedPart('day');
          }}
          onKeyDown={handleKeyDown}
        />{' '}
        {focusedPart === 'day' && (
          <Tooltip
            placement="top"
            offsetSize={10}
            trigger={<></>}
            openByDefault
            content={<>Date in DD format (01-31)</>}
          />
        )}
      </>
      <Separator>/</Separator>
      <>
        <DatePartInput
          ref={yearRef}
          type="text"
          value={year || ''}
          placeholder="YYYY"
          maxLength={4}
          onBlur={handleBlur}
          onFocus={() => {
            setFocusedPart('year');
          }}
          onKeyDown={handleKeyDown}
        />
        {focusedPart === 'year' && (
          <Tooltip placement="top" offsetSize={10} trigger={<></>} openByDefault content={<>Year in YYYY Format</>} />
        )}
      </>
      {allowTimeSelection && (
        <>
          <Separator></Separator>
          <>
            <DatePartInput
              ref={hourRef}
              type="text"
              value={hour || ''}
              placeholder="HH"
              maxLength={2}
              onBlur={handleBlur}
              onFocus={() => {
                setFocusedPart('hour');
              }}
              onKeyDown={handleKeyDown}
            />
            {focusedPart === 'hour' && (
              <Tooltip
                placement="top"
                offsetSize={10}
                trigger={<></>}
                openByDefault
                content={<>Hours in 24-hour Format (00-23)</>}
              />
            )}
          </>
          <Separator>:</Separator>
          <DatePartInput
            ref={minuteRef}
            type="text"
            value={minute || ''}
            placeholder="MM"
            maxLength={2}
            onBlur={handleBlur}
            onFocus={() => {
              setFocusedPart('minute');
            }}
            onKeyDown={handleKeyDown}
          />
          {focusedPart === 'minute' && (
            <Tooltip
              placement="top"
              offsetSize={10}
              trigger={<></>}
              openByDefault
              content={<>Minutes in mm format (00-59)</>}
            />
          )}
        </>
      )}
    </InputContainer>
  );
};

export default DatePickerInputs;
