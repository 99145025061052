import { AvatarAndName, EnabledDisabledBadge, FCOCard, Monospace, TruncateText } from '@tecton/ComponentRedesign';
import moment from 'moment-timezone';
import { FC } from 'react';
import { AnyFCO, FCOFields } from '../../../core/types/fcoTypes';
import { TectonDateTimeFormat } from './utils';

interface FCOMetadataCardProps {
  fco: AnyFCO;
}

const FCOMetadataCard: FC<FCOMetadataCardProps> = ({ fco }) => {
  const items = [
    {
      title: <>ID</>,
      content: <>{fco[FCOFields.ID]}</>,
    },

    {
      title: <>Owner</>,
      content:
        fco[FCOFields.OWNER] === 'n/a' ? (
          <AvatarAndName icon={'NotSet'} name="Not Set" type="icon" />
        ) : (
          <AvatarAndName name={fco.owner} />
        ),
    },
    {
      title: <>Created</>,
      content: TectonDateTimeFormat(moment(fco[FCOFields.CREATED_DATE])),
    },
    {
      title: <>Last Updated By</>,
      content: fco[FCOFields.LAST_MODIFIED_BY] && <AvatarAndName name={fco[FCOFields.LAST_MODIFIED_BY]} />,
    },
    {
      title: <>Last Updated</>,
      content: TectonDateTimeFormat(moment(fco[FCOFields.LAST_MODIFIED_DATE])),
    },
    {
      title: <>Defined In</>,
      content: (
        <Monospace>
          <TruncateText>{fco[FCOFields.SOURCE_FILE_NAME]}</TruncateText>
        </Monospace>
      ),
    },
    {
      title: <>Prevent Destroy</>,
      content: <EnabledDisabledBadge enabled={fco[FCOFields.PREVENT_DESTROY] ?? false} />,
    },
  ];

  return <FCOCard title="Metadata" items={items} data-testid="fco-metadata-card" />;
};

export default FCOMetadataCard;
