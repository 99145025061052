import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { useTectonTheme } from '../../Theme/ThemeProvider';
import { CodeLanguage, CopyButton, customSyntaxTheme, FlexGroup, FlexItem } from '.';

interface CodeProps {
  code: string;
  language?: CodeLanguage;
  hideLineNumbers?: boolean;
  hideCopy?: boolean;
  errorLines?: number[];
}

const CodeContainer = styled.div`
  background-color: ${({ theme }) => theme.v1.colors.background.default};
  padding: ${({ theme }) => theme.v1.size['2']}px;

  .error-line {
    display: block;
    border-left: ${({ theme }) => theme.v1.size['0.5']}px solid
      ${({ theme }) => theme.v1.colors.background.dangerPrimary};
    background-color: ${({ theme }) => theme.v1.colors.background.warning};

    &:before {
      border-left: 5px solid black;
    }
  }
`;

const CodeColumn = styled.div`
  flex: 3;
`;

const Code: FC<CodeProps> = ({
  code,
  language = 'python',
  hideLineNumbers = false,
  hideCopy = false,
  errorLines = [],
}) => {
  const { theme } = useTectonTheme();

  const customTheme = customSyntaxTheme(theme);

  return (
    <CodeContainer data-testid="code">
      <FlexGroup gapSize="2">
        <FlexItem grow="1" shrink="1">
          <CodeColumn>
            <SyntaxHighlighter
              language={language}
              style={customTheme}
              showLineNumbers={!hideLineNumbers}
              wrapLines
              lineProps={(lineNumber) => {
                return {
                  className: errorLines.includes(lineNumber) ? 'error-line' : '',
                };
              }}
            >
              {code}
            </SyntaxHighlighter>
          </CodeColumn>
        </FlexItem>
        {!hideCopy && (
          <FlexItem grow="0" shrink="0">
            <FlexGroup justifyContent="flex-start" padding="0">
              <CopyButton code={code} />
            </FlexGroup>
          </FlexItem>
        )}
      </FlexGroup>
    </CodeContainer>
  );
};

export default Code;
