import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useState } from 'react';

import {
  getDefaultMonitoringDateRange,
  MonitoringDateRange,
} from '../../../../components/monitoring/DateRange-Dropdown-util';
import DateRangeSelectorMonitoring from '../../../../components/monitoring/DateRangeSelectorMonitoring';
import MonitoringErrorBoundary from '../../../../components/monitoring/MonitoringErrorBoundary';
import { logEvent } from '../../../../utils/analytics-utils';
import MonitoringGraphs from '../../../feature-views/MonitoringGraphs';
import SLOReliability30Days from './SLOReliability30Days';

import { useParams } from 'react-router';
import { CustomGraph } from '../../../feature-views/graph-types';
import { FEATURE_SERVICE_GLOBAL_SLO } from '../../../graph-configuration';

const Monitoring: FC = () => {
  const { workspace } = useParams();
  const defaultMonitoringDateRange: MonitoringDateRange = getDefaultMonitoringDateRange();
  const [monitoringDateRange, setMonitoringDateRange] = useState<MonitoringDateRange>(defaultMonitoringDateRange);

  const onChange = (monitoringDateRange: MonitoringDateRange) => {
    setMonitoringDateRange(monitoringDateRange);
  };

  logEvent('Graphs: SLO Monitoring');

  const customGraphs: Record<string, React.ReactNode> = {
    [CustomGraph.FEATURE_SERVICE_GLOBAL_SLO_RELIABILITY]: (
      <Box flex={1} className="pa2">
        <MonitoringErrorBoundary>
          <SLOReliability30Days monitoringDateRange={monitoringDateRange} />
        </MonitoringErrorBoundary>
      </Box>
    ),
  };

  return (
    <Stack spacing={2}>
      <Box display={'flex'}>
        <Box flex={1}>
          <DateRangeSelectorMonitoring onChange={onChange} />
        </Box>
      </Box>
      <>
        <MonitoringGraphs
          monitoringDateRange={monitoringDateRange}
          graphsData={FEATURE_SERVICE_GLOBAL_SLO}
          customGraphRecord={customGraphs}
          workspace={workspace ?? ''}
        />
      </>
    </Stack>
  );
};

export default Monitoring;
