import styled from '@emotion/styled';
import React, { FC, useContext } from 'react';
import { useTectonTheme } from '../../Theme/ThemeProvider';
import { Button, Icon, IconType, Text, FlexGroup, ToastContext } from '.';
import { css, keyframes } from '@emotion/react';

export type ToastStatus = 'default' | 'success' | 'warning' | 'error';
type ToastLifecycle = 'spawning' | 'available' | 'exiting' | 'exited';

const enter = keyframes`
    from {

        max-height:0;
    }

    to {

        max-height:200px;
    }
`;

const exit = keyframes`
    from {

        max-height:200px;
    }

    to {

        max-height:0;
    }`;

export interface ToastProps {
  status?: ToastStatus;
  title: React.ReactNode;
  body: React.ReactNode;
  icon?: boolean;
  id: string;
  lifeCycleState: ToastLifecycle;
  selfRemoving?: boolean;
  action: React.ReactNode;
}

const StyledToast = styled.div<{ lifeCycleState: ToastLifecycle }>`
  overflow: hidden;
  bottom: ${({ theme }) => theme.v1.size['8']}px;
  right: ${({ theme }) => theme.v1.size['8']}px;
  z-index: ${({ theme }) => theme.v1.layers.toast};
  box-shadow: ${({ theme }) => theme.v1.shadow.m};
  padding: ${({ theme }) => theme.v1.size['4']}px;
  border-radius: ${({ theme }) => theme.v1.size['2']}px;
  color: ${({ theme }) => theme.v1.colors.text.text};
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  width: ${({ theme }) => theme.v1.size['80']}px;
  height: auto;

  animation: ${({ theme, lifeCycleState }) => {
    if (lifeCycleState === 'spawning') {
      return css`
        ${enter} ${theme.v1.motion.duration.fast} ${theme.v1.motion.easing.enter} forwards
      `;
    }

    if (lifeCycleState === 'exiting') {
      return css`
        ${exit} ${theme.v1.motion.duration.xfast} ${theme.v1.motion.easing.exit} forwards
      `;
    }
  }};
`;

const Title = styled.div<{ color: string }>`
  font-size: ${({ theme }) => theme.v1.font.size.m};
  font-weight: ${({ theme }) => theme.v1.font.weight.medium};
  color: ${({ color }) => color};
`;

const Wrapper = styled.div`
  position: relative;
  width: auto;
  height: auto;
  overflow: visible;
`;

const Toast: FC<ToastProps> = ({ status = 'default', title, body, icon = true, lifeCycleState, id, action }) => {
  const { theme } = useTectonTheme();
  const { removeToast, deleteToast, freezeToast } = useContext(ToastContext);

  const StatusColorMap: Record<ToastStatus, string> = {
    default: theme.v1.colors.text.title,
    success: theme.v1.colors.text.successText,
    warning: theme.v1.colors.text.warningText,
    error: theme.v1.colors.text.dangerText,
  };

  const IconMap: Record<ToastStatus, IconType> = {
    default: 'Information',
    success: 'CheckCircle',
    warning: 'Warning',
    error: 'Danger',
  };

  return (
    <Wrapper data-testid="toast">
      <StyledToast
        lifeCycleState={lifeCycleState}
        onAnimationEnd={() => {
          if (lifeCycleState === 'exiting') {
            deleteToast(id);
          }
          if (lifeCycleState === 'spawning') {
            freezeToast(id);
          }
        }}
      >
        <FlexGroup direction="column" padding={'0'} gapSize="2">
          <FlexGroup justifyContent="space-between" alignItems="center" padding="0">
            <FlexGroup alignItems="center" gapSize="1.5" padding={'0'}>
              {icon && <Icon icon={IconMap[status]} size="s" color={StatusColorMap[status]} type={'color'} />}
              <Title color={StatusColorMap[status]}>{title}</Title>
            </FlexGroup>
            {action && (
              <Button
                type="ghost"
                icon="Exit"
                onClick={() => {
                  removeToast(id);
                }}
                size="s"
              />
            )}
          </FlexGroup>
          <Text>{body}</Text>
          {action && (
            <FlexGroup justifyContent="flex-end" padding={'0'}>
              {action}
            </FlexGroup>
          )}
        </FlexGroup>
      </StyledToast>
    </Wrapper>
  );
};

export default Toast;
