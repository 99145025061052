/* eslint-disable */
import { Principal, PrincipalBasic } from "../auth/principal";
import { ContainerImage } from "../common/container_image";

export const protobufPackage = "tecton_proto.data";

export enum JobEnvironment {
  JOB_ENVIRONMENT_UNSPECIFIED = "JOB_ENVIRONMENT_UNSPECIFIED",
  JOB_ENVIRONMENT_REALTIME = "JOB_ENVIRONMENT_REALTIME",
  JOB_ENVIRONMENT_RIFT_BATCH = "JOB_ENVIRONMENT_RIFT_BATCH",
  JOB_ENVIRONMENT_RIFT_STREAM = "JOB_ENVIRONMENT_RIFT_STREAM",
}

export enum RemoteEnvironmentStatus {
  /** REMOTE_ENVIRONMENT_STATUS_PENDING - Remote execution environment build status */
  REMOTE_ENVIRONMENT_STATUS_PENDING = "REMOTE_ENVIRONMENT_STATUS_PENDING",
  REMOTE_ENVIRONMENT_STATUS_READY = "REMOTE_ENVIRONMENT_STATUS_READY",
  REMOTE_ENVIRONMENT_STATUS_ERROR = "REMOTE_ENVIRONMENT_STATUS_ERROR",
  REMOTE_ENVIRONMENT_STATUS_DELETING = "REMOTE_ENVIRONMENT_STATUS_DELETING",
  REMOTE_ENVIRONMENT_STATUS_DELETION_FAILED = "REMOTE_ENVIRONMENT_STATUS_DELETION_FAILED",
}

export enum RemoteComputeType {
  /** REMOTE_COMPUTE_TYPE_CORE - protolint:disable:this ENUM_FIELD_NAMES_ZERO_VALUE_END_WITH */
  REMOTE_COMPUTE_TYPE_CORE = "REMOTE_COMPUTE_TYPE_CORE",
  REMOTE_COMPUTE_TYPE_EXTENDED = "REMOTE_COMPUTE_TYPE_EXTENDED",
  REMOTE_COMPUTE_TYPE_SNOWPARK_DEPRECATED_DO_NOT_USE = "REMOTE_COMPUTE_TYPE_SNOWPARK_DEPRECATED_DO_NOT_USE",
  REMOTE_COMPUTE_TYPE_CUSTOM = "REMOTE_COMPUTE_TYPE_CUSTOM",
}

/** RemoteComputeEnvironment is the record stored in the database. */
export interface RemoteComputeEnvironment {
  id?:
    | string
    | undefined;
  /** Remote Execution Environment name */
  name?: string | undefined;
  type?: RemoteComputeType | undefined;
  status?: RemoteEnvironmentStatus | undefined;
  image_info?: ContainerImage | undefined;
  provisioned_image_info?: ContainerImage | undefined;
  created_at?: string | undefined;
  updated_at?: string | undefined;
  created_by?:
    | Principal
    | undefined;
  /**
   * This field only exists on the object if it is returned in an API.
   * It is not stored in the Database.
   */
  created_by_principal?: PrincipalBasic | undefined;
  description?:
    | string
    | undefined;
  /** Python Version for the environment, one of 3.8 or 3.9 */
  python_version?:
    | string
    | undefined;
  /** Contents of requirements.txt passed as input */
  requirements?:
    | string
    | undefined;
  /** Contents of fully resolved requirements.txt */
  resolved_requirements?:
    | string
    | undefined;
  /** S3 prefix and zip file name for the environment's wheels, only applicable for Custom Environments */
  s3_wheels_location?:
    | string
    | undefined;
  /** List of Feature Services using the environment */
  feature_services?:
    | DependentFeatureService[]
    | undefined;
  /** realtime_job_environment tracks metadata for the realtime environment. */
  realtime_job_environment?: RealtimeEnvironment | undefined;
  rift_batch_job_environment?: RiftBatchEnvironment | undefined;
  supported_job_environments?:
    | JobEnvironment[]
    | undefined;
  /** SDK version used to create the environment */
  sdk_version?:
    | string
    | undefined;
  /** additional status info to show end users, e.g. if an attempted deletion failed and why */
  status_details?: string | undefined;
}

export interface RealtimeEnvironment {
  /**
   * tecton_transform_runtime_version is the version of the tecton-runtime transformation library
   * used by this environments' realtime feature view execution environment.
   */
  tecton_transform_runtime_version?: string | undefined;
  image_info?: ContainerImage | undefined;
  provisioned_image_info?:
    | ContainerImage
    | undefined;
  /** AWS Lambda Function ARN, fully qualified with the associated version. */
  remote_function_uri?: string | undefined;
  feature_services?: DependentFeatureService[] | undefined;
  online_provisioned?: boolean | undefined;
}

export interface RiftBatchEnvironment {
  tecton_materialization_runtime_version?: string | undefined;
  image_info?:
    | ContainerImage
    | undefined;
  /** specific to Anyscale cluster environments */
  cluster_environment_build_id?: string | undefined;
}

/** Object Store Upload Information for the environment's wheels */
export interface RemoteEnvironmentUploadInfo {
  environment_id?:
    | string
    | undefined;
  /** Add other object storage types here in future */
  s3_upload_info?: S3UploadInfo | undefined;
}

/** Upload Information for a part in a Multi Part Upload */
export interface ObjectStoreUploadPart {
  /** Add other object store upload parts in future */
  s3_upload_part?: S3UploadPart | undefined;
}

/** S3 Upload Information for environment's wheels */
export interface S3UploadInfo {
  upload_id?: string | undefined;
  upload_parts?: S3UploadPart[] | undefined;
}

export interface S3UploadPart {
  parent_upload_id?:
    | string
    | undefined;
  /** Part Number and ETag uniquely identify a part in a multi-part upload, and are returned by S3 API after upload */
  part_number?: number | undefined;
  e_tag?:
    | string
    | undefined;
  /** The presigned URL for upload */
  upload_url?: string | undefined;
}

export interface DependentFeatureService {
  workspace_name?: string | undefined;
  feature_service_name?: string | undefined;
}

export interface DependentFeatureView {
  workspace_name?: string | undefined;
  feature_view_name?: string | undefined;
}
