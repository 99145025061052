import {
  ComboFilterSelector,
  ComboSelectorChecklistOption,
  EmptyValue,
  EnabledDisabledBadge,
  FlexGroup,
  IconAndTooltip,
  Icons,
  IconTypes,
  RelatedFCOs,
} from '@tecton/ComponentRedesign';
import { uniq } from 'lodash';
import React, { FC, useState } from 'react';
import { AnyFCO, FCOType, FeatureServiceFCO, FeatureServiceFCOFields } from '../../../core/types/fcoTypes';

import { Link } from 'react-router-dom';
import { ServerGroup } from '../../../api/serverGroup';
import { ColumnNameWithHelp } from '../../../components/shared';
import { ServerGroupType } from '../../../types/tecton_proto/common/server_group_type';
import { logEvent } from '../../../utils/analytics-utils';
import {
  FCOFilterStateType,
  FCOFilterType,
  FCOHistoryFilterState,
  FCOHistoryFilterTypes,
  FCOHistoryTableColumns,
  FCOListView,
  NameColumn,
  RelatedFCOCountFilterLogic,
  RelatedFCOCountFilterType,
  RelatedFCOFilterLogic,
  RelatedFCOFilterType,
  ServerGroupFilterLogic,
  ServerGroupFilterType,
  SharedFCOHistoryFilterLogic,
  SimpleChecklistFilterLogic,
  SimpleChecklistFilterType,
  structureTagsForFilter,
  TagFilterLogic,
  TagsColumn,
  TagsFilterType,
  valuesForFCOCount,
} from '../../redesign/fcoListView';
import { getRelatedFCOsComboFilterOptions } from '../../redesign/featureViews/featureViewUtils';
import { ServerGroupSelectable } from '../../redesign/monitoring/FeatureServerGroupLists';

interface FeatureServicesListProps {
  items: FeatureServiceFCO[];
  idToFcoMap: Record<string, AnyFCO>;
  workspace: string;
  serverGroupsList: ServerGroupSelectable[];
  isFeatureServerGroupEnabled?: boolean;
}

const SERVER_GROUP_TAGS_MAP = {
  [ServerGroupType.SERVER_GROUP_TYPE_FEATURE_SERVER_GROUP]: {
    name: 'Feature Server Group',
    icon: Icons[IconTypes.FEATURE_SERVER_GROUP],
    description: 'Feature server group serve features and autoscales. Click for status.',
  },
  [ServerGroupType.SERVER_GROUP_TYPE_TRANSFORM_SERVER_GROUP]: {
    name: 'Transform Server Group',
    icon: Icons[IconTypes.TRANSFORM_SERVER_GROUP],
    description: 'Transform server groups compute features in realtime and autoscales. Click for status.',
  },
  [ServerGroupType.SERVER_GROUP_TYPE_UNSPECIFIED]: {
    name: 'Default Feature Server',
    icon: Icons[IconTypes.DEFAULT_SERVER_GROUP],
    description:
      'Computes and serves all features not being served by dedicated Feature Server Groups or Transform Server groups. Click for status.',
  },
};

export const getRealtimeServerColumn = (
  workspace: string,

  serverGroupsList?: ServerGroup[]
) => [
  {
    name: (
      <ColumnNameWithHelp
        name={'Realtime Server'}
        helpContent={`These autoscaling server groups consist of Feature Servers for serving features and Transform Servers for computing features in realtime.`}
      />
    ),
    key: 'Realtime Server',
    field: FeatureServiceFCOFields.SERVER_GROUPS,
    sortable: true,
    width: '300px',
    render: (_: string, fco: FeatureServiceFCO) => {
      const hasMultipleServerGroups = fco.serverGroups.length > 1;

      return (
        <FlexGroup gutterSize="m">
          {fco.serverGroups.map((item) => {
            const serverGroupId = item.type === ServerGroupType.SERVER_GROUP_TYPE_UNSPECIFIED ? fco.id : item.id;

            const serverGroupItem = serverGroupsList
              ? serverGroupsList.find(({ serverGroupId: Id }) => Id === serverGroupId)
              : undefined;

            const serverGroupName = serverGroupItem?.name
              ? serverGroupItem.name
              : SERVER_GROUP_TAGS_MAP[item.type].name;

            const serverGroupEnvironment = serverGroupItem?.environment;
            const tooltipContent = `${serverGroupEnvironment ? `Environment: ${serverGroupEnvironment}. ` : ''}${
              SERVER_GROUP_TAGS_MAP[item.type].description
            }`;

            const monitoringLink =
              item.type === ServerGroupType.SERVER_GROUP_TYPE_UNSPECIFIED
                ? `/repo/${workspace}/monitoring/realtime-server?${new URLSearchParams({ id: fco.id })}`
                : `/repo/${workspace}/monitoring/realtime-server?${new URLSearchParams({ id: item.id })}`;

            return (
              <>
                <Link
                  to={monitoringLink}
                  key={item.type}
                  onMouseOver={() => {
                    logEvent('Feature Service', '', {
                      event: `Metadata inspected`,
                      description: 'Realtime server link hovered for tooltip',
                      action: 'Realtime server link hovered for tooltip',
                      linkHovered: `${window.location.protocol}//${window.location.host}/app${monitoringLink}`,
                      serverType: `${item.type}`,
                    });
                  }}
                  onClick={() => {
                    logEvent('Feature Service', '', {
                      event: `Metadata inspected`,
                      description: 'Realtime server link clicked',
                      action: 'link clicked',
                      linkClicked: `${window.location.protocol}//${window.location.host}/app${monitoringLink}`,
                      serverType: `${item.type}`,
                    });
                  }}
                >
                  <IconAndTooltip
                    icon={SERVER_GROUP_TAGS_MAP[item.type].icon}
                    description={tooltipContent}
                    name={serverGroupName}
                    maxWidthName={hasMultipleServerGroups ? '120px' : undefined}
                  />
                </Link>
              </>
            );
          })}
        </FlexGroup>
      );
    },
  },
];

const FeatureServicesList: FC<FeatureServicesListProps> = ({ items, idToFcoMap, workspace, serverGroupsList }) => {
  type FeatureServicesFilterKeys =
    | FCOFilterType
    | 'Online Serving'
    | '# of Feature Views'
    | 'Feature Views'
    | 'Realtime Servers';

  interface FeatureServiceStateType extends FCOFilterStateType {
    'Online Serving': ComboSelectorChecklistOption[];
    '# of Feature Views': ComboSelectorChecklistOption[];
    'Feature Views': ComboSelectorChecklistOption[];
    'Realtime Servers': ServerGroupSelectable[];
  }

  const getFeatureServicesIdCallback = (item: FeatureServiceFCO) => {
    return uniq(item[FeatureServiceFCOFields.ALL_FEATURE_VIEWS]) ?? [];
  };

  const featureViews = getRelatedFCOsComboFilterOptions(items, getFeatureServicesIdCallback, idToFcoMap);

  const defaultFilterState: FeatureServiceStateType = {
    Tags: structureTagsForFilter(items),
    ...FCOHistoryFilterState(items as AnyFCO[]),
    'Online Serving': [
      { label: 'Enabled', value: true },
      { label: 'Disabled', value: false },
    ],
    '# of Feature Views': valuesForFCOCount,
    'Feature Views': featureViews,
    'Realtime Servers': serverGroupsList.map((sg) => {
      return { ...sg, checked: false };
    }),
  };

  const [filterState, setFilterState] = useState(defaultFilterState);

  const filterTypes: Record<FeatureServicesFilterKeys, ComboFilterSelector> = {
    Tags: TagsFilterType(filterState, setFilterState as React.Dispatch<React.SetStateAction<FCOFilterStateType>>),
    'Online Serving': SimpleChecklistFilterType(filterState, setFilterState, 'Online Serving', (item) => {
      const enabled = (item as { value: boolean }).value;
      return <EnabledDisabledBadge enabled={enabled} />;
    }),
    '# of Feature Views': RelatedFCOCountFilterType(filterState, setFilterState, '# of Feature Views'),
    'Feature Views': RelatedFCOFilterType(filterState, setFilterState, 'Feature Views', FCOType.FEATURE_VIEW),
    'Realtime Servers': ServerGroupFilterType(
      filterState,
      setFilterState,
      'Realtime Servers',
      false // hide the filter if option if feature flag is off: FEATURE_SERVER_GROUP
    ),
    ...FCOHistoryFilterTypes(filterState, setFilterState as React.Dispatch<React.SetStateAction<FCOFilterStateType>>),
  };

  const columns = [
    ...NameColumn,
    ...TagsColumn,
    {
      name: 'Online Enabled',
      key: 'Online',
      field: FeatureServiceFCOFields.IS_ONLINE_SERVING_ENABLED,
      sortable: true,
      render: (_: unknown, featureView: FeatureServiceFCO) => {
        if (featureView[FeatureServiceFCOFields.IS_ONLINE_SERVING_ENABLED] === undefined) {
          return <EmptyValue />;
        }

        return (
          <EnabledDisabledBadge enabled={featureView[FeatureServiceFCOFields.IS_ONLINE_SERVING_ENABLED] ?? false} />
        );
      },
    },
    {
      name: 'Feature Views',
      key: 'Feature Views',
      sortable: true,
      field: FeatureServiceFCOFields.DEPENDENT_FEATURE_VIEWS,
      render: (_: unknown, featureService: FeatureServiceFCO) => {
        const items = featureService[FeatureServiceFCOFields.DEPENDENT_FEATURE_VIEWS]
          .map((featureViewId) => idToFcoMap[featureViewId])
          .map((fco) => {
            return { name: fco.name ?? '', description: fco.description };
          });
        return <RelatedFCOs fcos={items} type={FCOType.FEATURE_VIEW} workspace={workspace} />;
      },
    },
    ...FCOHistoryTableColumns,
    ...getRealtimeServerColumn(workspace, serverGroupsList),
  ];

  const filterFunctionsByFilterKey: Record<FeatureServicesFilterKeys, (fco: FeatureServiceFCO) => boolean> = {
    Tags: TagFilterLogic(filterState['Tags']),
    ...SharedFCOHistoryFilterLogic(filterState),
    'Online Serving': SimpleChecklistFilterLogic(
      filterState,
      'Online Serving',
      FeatureServiceFCOFields.IS_ONLINE_SERVING_ENABLED
    ),
    '# of Feature Views': RelatedFCOCountFilterLogic(
      filterState,
      '# of Feature Views',
      FeatureServiceFCOFields.ALL_FEATURE_VIEWS
    ),
    'Feature Views': RelatedFCOFilterLogic(filterState, 'Feature Views', FeatureServiceFCOFields.ALL_FEATURE_VIEWS),
    'Realtime Servers': ServerGroupFilterLogic(filterState, 'Realtime Servers', FeatureServiceFCOFields.SERVER_GROUPS),
  };

  return (
    <FCOListView
      unfilteredFCOItems={items}
      filters={filterTypes}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      defaultFilterState={defaultFilterState}
      filterFunctionsByFilterKey={filterFunctionsByFilterKey}
      searchPlaceholder={'Search Services'}
    />
  );
};

export default FeatureServicesList;
