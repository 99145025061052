import { type FC } from 'react';
import DataPlatformConfigEdit from './DataPlatformConfigEdit';

// This is a place holder
const ComputeOverviewContainer: FC = () => {
  return (
    <>
      <DataPlatformConfigEdit showHeader={false} />
    </>
  );
};

export default ComputeOverviewContainer;
