import moment from 'moment-timezone';
import React, { FC, useState } from 'react';
import { FCOFields, FeatureViewFCO, FeatureViewFCOFields, OnlineStoreType } from '../../../core/types/fcoTypes';
import {
  BATCH_FEATURE_VIEW_MONITORING_TAB,
  COMMON_DYNAMODB_FEATURE_VIEW_STORE_TYPE,
  COMMON_REDIS_FEATURE_VIEW_STORE_TYPE,
  CONTINUES_FEATURE_VIEW_MONITORING_TAB,
  PUSH_FEATURE_VIEW_MONITORING_TAB,
  STREAM_FEATURE_VIEW_MONITORING_TAB,
} from '../../../feature/graph-configuration';
import { useUserSettings } from '../../context/UserSettingsContext';
import {
  MonitoringDateRange,
  calculateStep,
  calculateUnit,
  getDefaultMonitoringDateRange,
  getMonitoringDateRangeByTimeComboOption,
} from '../../monitoring/DateRange-Dropdown-util';
import MonitoringGraphs from '../monitoring/MonitoringGraphs';

// @tecton
import {
  CardGridLayout,
  EmptyPrompt,
  FlexGroupWrapper,
  FlexItem,
  FullWidthCard,
  Loading,
} from '@tecton/ComponentRedesign';
import TectonTimeComboSelector, {
  TimeComboDateRange,
  TimeComboOptionType,
} from '@tecton/ComponentRedesign/lib/TimeComboSelector';
import { useWorkspaceList } from '../../../shared/query';
import { filterMonitoringTimeComboOptionCallback } from '../monitoring/monitoring-utils';

interface FeatureViewDetailMonitoringProps {
  featureview: FeatureViewFCO;
}

const FeatureViewMonitoringDevelopmentWorkspaceWrapper: FC<{
  featureview: FeatureViewFCO;
  children: React.ReactNode;
}> = ({ featureview, children }) => {
  const { workspace } = useUserSettings();

  const { data, isLoading: workspacesLoading } = useWorkspaceList();
  if (workspacesLoading) {
    return <Loading />;
  }

  const isDevelopmentWorkspace = data.workspaces.find((findWorkspace: any) => findWorkspace.name === workspace);

  if (!isDevelopmentWorkspace || !isDevelopmentWorkspace.capabilities.materializable === true) {
    return (
      <EmptyPrompt
        variant="search"
        body={<>{workspace} is a development workspace. Monitoring is not available in development workspaces.</>}
        title={<>Monitoring Not Available</>}
      />
    );
  }

  if (!featureview[FeatureViewFCOFields.IS_ONLINE_MATERIALIZATION_ENABLED]) {
    return (
      <EmptyPrompt
        variant="storm"
        body={<>Enable online materialization for "{featureview.name}" to view online Monitoring.</>}
        title={<>Online Materialization is not enabled</>}
      />
    );
  }

  return <>{children}</>;
};

// This is in the Feature View Details page -> monitoring tab.
const FeatureViewDetailMonitoring: FC<FeatureViewDetailMonitoringProps> = ({ featureview }) => {
  const { workspace } = useUserSettings();
  const defaultMonitoringDateRange: MonitoringDateRange = getDefaultMonitoringDateRange();
  const [monitoringDateRange, setMonitoringDateRange] = useState<MonitoringDateRange>(defaultMonitoringDateRange);
  const [selectedTimeComboOption, setSelectedTimeComboOption] = useState<TimeComboOptionType>('1Day');
  const [dateRange, setDateRange] = useState<TimeComboDateRange>({
    startDate: moment().add(-1, 'day'),
    endDate: moment(),
  });
  const batchOnly = !featureview[FeatureViewFCOFields.IS_STREAM];
  const isPush = featureview[FeatureViewFCOFields.IS_PUSH];
  const continuousPackage = featureview[FeatureViewFCOFields.IS_CONTINUOUS];

  let graphData = STREAM_FEATURE_VIEW_MONITORING_TAB; // Default

  if (batchOnly) {
    graphData = BATCH_FEATURE_VIEW_MONITORING_TAB;
  } else if (isPush) {
    graphData = PUSH_FEATURE_VIEW_MONITORING_TAB;
  } else if (continuousPackage) {
    graphData = CONTINUES_FEATURE_VIEW_MONITORING_TAB;
  }

  return (
    <FeatureViewMonitoringDevelopmentWorkspaceWrapper featureview={featureview}>
      <CardGridLayout>
        <FullWidthCard>
          <TectonTimeComboSelector
            limitMinDateInMonths={2}
            filterOptions={filterMonitoringTimeComboOptionCallback}
            dateRange={dateRange}
            setDateRange={setDateRange}
            initialOption={selectedTimeComboOption}
            onCustomDateSelection={(startDate, endDate) => {
              const startTime = () => startDate.valueOf();
              const endTime = () => endDate.valueOf();
              if (endDate) {
                const step = calculateStep(startDate, endDate);
                const unit = calculateUnit(startDate, endDate);

                const customDateRange: MonitoringDateRange = {
                  startTime: startTime,
                  endTime: endTime,
                  requestId: `${startTime()}${endTime()}`,
                  step: step,
                  unit: unit,
                };
                setSelectedTimeComboOption('custom');
                setMonitoringDateRange(customDateRange);
              }
            }}
            onComboSelection={(timeComboOption) => {
              setSelectedTimeComboOption(timeComboOption);
              const monitoringDateRangeOption = getMonitoringDateRangeByTimeComboOption(timeComboOption);
              setMonitoringDateRange(monitoringDateRangeOption);
            }}
          />
        </FullWidthCard>
      </CardGridLayout>

      <FlexGroupWrapper gap="xxl">
        <FlexItem>
          <MonitoringGraphs
            monitoringDateRange={monitoringDateRange}
            graphsData={graphData}
            customGraphRecord={{}}
            workspace={workspace ?? ''}
            fcoId={featureview[FCOFields.ID] ?? ''}
          />
        </FlexItem>

        {featureview[FeatureViewFCOFields.ONLINE_STORE_TYPE] === OnlineStoreType.DYNAMO && (
          <FlexItem>
            <MonitoringGraphs
              monitoringDateRange={monitoringDateRange}
              graphsData={COMMON_DYNAMODB_FEATURE_VIEW_STORE_TYPE}
              customGraphRecord={{}}
              workspace={workspace ?? ''}
              fcoId={featureview[FCOFields.ID]}
            />
          </FlexItem>
        )}

        {featureview[FeatureViewFCOFields.ONLINE_STORE_TYPE] === OnlineStoreType.REDIS && (
          <FlexItem>
            <MonitoringGraphs
              monitoringDateRange={monitoringDateRange}
              graphsData={COMMON_REDIS_FEATURE_VIEW_STORE_TYPE}
              customGraphRecord={{}}
              workspace={workspace ?? ''}
              fcoId={featureview[FCOFields.ID]}
            />
          </FlexItem>
        )}
      </FlexGroupWrapper>
    </FeatureViewMonitoringDevelopmentWorkspaceWrapper>
  );
};

export default FeatureViewDetailMonitoring;
