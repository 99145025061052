import React, { FC } from 'react';
import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';
import { GraphType } from '../../../feature/feature-views/graph-types';
import { X_AXIS_LABEL } from './GraphContainer';
import GraphiteGraph from './GraphiteGraph';

// @tecton
import TectonTooltip from '@tecton/ComponentRedesign/lib/Tooltip';
import { Card } from '@tecton/ComponentRedesign';
import { FlexGroup } from '../../@tecton/ComponentRedesign/lib/v1';

interface GraphiteContainerProps {
  monitoringDateRange: MonitoringDateRange;
  workspace: string;
  graphMetadata: GraphType;
  featureViewId: string;
  height?: number;
}

const GraphiteContainer: FC<GraphiteContainerProps> = ({
  monitoringDateRange,
  graphMetadata,
  featureViewId,
  height,
}) => {
  const chartTitle = graphMetadata.chartTitle ?? '';
  const yAxisLabel = graphMetadata?.yAxisLabel ?? '';

  const yTicksCallback = graphMetadata.yTickCallBack;
  const toolTipLabelCallback = graphMetadata.toolTipLabelCallback;
  const parameters = graphMetadata?.parameters?.apply(this, [featureViewId]) ?? [];
  const graphInfoTooltip = graphMetadata?.graphInfoTooltip?.replaceAll('::step::', `${monitoringDateRange?.step}`);

  const hideLegendOverride = {
    display: true,
    labels: {
      display: false,
      generateLabels: () => {
        return '';
      },
    },
  };

  const yOverride = graphMetadata?.yOptions
    ? { ...graphMetadata?.yOptions, time: { unit: monitoringDateRange.unit } }
    : null;

  const title = (
    <TectonTooltip
      content={graphInfoTooltip ?? 'No description provided.'}
      data-testid="chart-tooltip"
      trigger={<>{chartTitle}</>}
    />
  );

  return (
    <Card title={title}>
      <FlexGroup padding="0" justifyContent="center" alignItems="center">
        <GraphiteGraph
          chartTitle={chartTitle}
          monitoringDateRange={monitoringDateRange}
          parameters={parameters}
          xAxisLabel={X_AXIS_LABEL}
          yAxisLabel={yAxisLabel}
          yOverride={yOverride}
          yTicksCallback={yTicksCallback}
          yGrace={3}
          toolTipLabelCallback={toolTipLabelCallback}
          pluginLegendOverride={graphMetadata?.showLegend ? undefined : hideLegendOverride}
          height={height}
        />
      </FlexGroup>
    </Card>
  );
};

export default GraphiteContainer;
