import { useQuery } from '@tanstack/react-query';
import { Remote_Environment_Service } from '.';
import {
  GetRemoteEnvironmentRequest,
  GetRemoteEnvironmentResponse,
  ListRemoteEnvironmentsResponse,
} from '../types/tecton_proto/remoteenvironmentservice/remote_environment_service';

const fetchListEnvironments = async (): Promise<ListRemoteEnvironmentsResponse> => {
  const response = await Remote_Environment_Service.post('list-remote-environments');
  return response.data;
};

export const useListEnvironments = () => {
  return useQuery(['getListEnvironments'], () => {
    return fetchListEnvironments();
  });
};

export const useGetEnvironment = (id: string, enabled: boolean) => {
  const fetchEnvironment = async (payload: GetRemoteEnvironmentRequest): Promise<GetRemoteEnvironmentResponse> => {
    const response = await Remote_Environment_Service.post('get-remote-environment', payload);
    return response.data;
  };

  return useQuery(
    ['getEnvironment'],
    () => {
      return fetchEnvironment({ id });
    },
    { enabled: enabled }
  );
};
