import React, { FC } from 'react';
import { AnyFCO, FeatureViewFCO, FeatureViewFCOFields, FeatureViewFCOType } from '../../../core/types/fcoTypes';
import FCOMetadataCard from '../../@tecton/ComponentRedesign/FCOMetadataCard';
import FCOTagsCard from '../../@tecton/ComponentRedesign/FCOTagsCard';
import FeatureViewFeaturesCard from './FeatureViewFeaturesCard';
import {
  BatchFeatureViewConfigCard,
  FeatureTableConfigCard,
  OnDemandFeatureViewConfigCard,
  StreamingFeatureViewConfigCard,
} from './FeatureViewConfigurationCardsByType';
import { ColumnLayout } from '../../@tecton/ComponentRedesign';

interface ConfigCardProps {
  featureView: FeatureViewFCO;
  relatedFCOs: any;
}

const FeatureViewConfigCard: FC<ConfigCardProps> = ({ featureView, relatedFCOs }) => {
  switch (featureView[FeatureViewFCOFields.FEATURE_VIEW_TYPE]) {
    case FeatureViewFCOType.BATCH:
      return <BatchFeatureViewConfigCard fco={featureView} relatedFCOs={relatedFCOs} />;
    case FeatureViewFCOType.STREAM:
      return <StreamingFeatureViewConfigCard fco={featureView} relatedFCOs={relatedFCOs} />;
    case FeatureViewFCOType.REALTIME:
      return <OnDemandFeatureViewConfigCard fco={featureView} relatedFCOs={relatedFCOs} />;
    case FeatureViewFCOType.FEATURE_TABLE:
      return <FeatureTableConfigCard fco={featureView} relatedFCOs={relatedFCOs} />;
    default:
      return <></>;
  }
};

interface FeatureViewOverviewProps {
  featureView: FeatureViewFCO;
  idToFcoMap: Record<string, AnyFCO>;
}

const FeatureViewOverview: FC<FeatureViewOverviewProps> = ({ featureView, idToFcoMap }) => {
  const relatedFCOs = {
    entities: featureView[FeatureViewFCOFields.ENTITY_IDS].map((id: string) => idToFcoMap[id]),
  };

  return (
    <ColumnLayout>
      <FCOMetadataCard fco={featureView} />
      <FCOTagsCard fco={featureView} />
      <FeatureViewConfigCard featureView={featureView} relatedFCOs={relatedFCOs} />
      <FeatureViewFeaturesCard featureView={featureView} />
    </ColumnLayout>
  );
};

export default FeatureViewOverview;
