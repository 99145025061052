/* eslint-disable */
import { Duration } from "../../google/protobuf/duration";
import { ComputeIdentity } from "../common/compute_identity";
import { Id } from "../common/id";

export const protobufPackage = "tecton_proto.data";

/** TODO: remove this and replace with TaskType */
export enum DataSourceType {
  DATA_SOURCE_TYPE_UNSPECIFIED = "DATA_SOURCE_TYPE_UNSPECIFIED",
  DATA_SOURCE_TYPE_BATCH = "DATA_SOURCE_TYPE_BATCH",
  DATA_SOURCE_TYPE_STREAM = "DATA_SOURCE_TYPE_STREAM",
  DATA_SOURCE_TYPE_INGEST = "DATA_SOURCE_TYPE_INGEST",
  DATA_SOURCE_TYPE_DELETION = "DATA_SOURCE_TYPE_DELETION",
  DATA_SOURCE_TYPE_FEATURE_EXPORT = "DATA_SOURCE_TYPE_FEATURE_EXPORT",
  DATA_SOURCE_TYPE_DATASET_GENERATION = "DATA_SOURCE_TYPE_DATASET_GENERATION",
}

export enum MaterializationStatusState {
  MATERIALIZATION_STATUS_STATE_UNSPECIFIED = "MATERIALIZATION_STATUS_STATE_UNSPECIFIED",
  /**
   * MATERIALIZATION_STATUS_STATE_SCHEDULED - MaterializationTask exists but no MaterializationTaskAttempt has been
   * created yet by the Orchestrator. This can happen for a long time if
   * Databricks/EMR is congested with lots of new materialization tasks attempts
   * being scheduled.
   */
  MATERIALIZATION_STATUS_STATE_SCHEDULED = "MATERIALIZATION_STATUS_STATE_SCHEDULED",
  /**
   * MATERIALIZATION_STATUS_STATE_PENDING - MaterializationTaskAttempt was created and materialization run is about to
   * be started at Databricks.
   */
  MATERIALIZATION_STATUS_STATE_PENDING = "MATERIALIZATION_STATUS_STATE_PENDING",
  /** MATERIALIZATION_STATUS_STATE_RUNNING - Materialization run has been successfully submitted to Databricks. */
  MATERIALIZATION_STATUS_STATE_RUNNING = "MATERIALIZATION_STATUS_STATE_RUNNING",
  /** MATERIALIZATION_STATUS_STATE_SUCCESS - Materialization run has been successfully executed. */
  MATERIALIZATION_STATUS_STATE_SUCCESS = "MATERIALIZATION_STATUS_STATE_SUCCESS",
  /** MATERIALIZATION_STATUS_STATE_ERROR - Materialization run has been terminated with error. */
  MATERIALIZATION_STATUS_STATE_ERROR = "MATERIALIZATION_STATUS_STATE_ERROR",
  /**
   * MATERIALIZATION_STATUS_STATE_DRAINED - Materialization run has been drained by Orchestrator.
   * This happens when either:
   *  - FeatureView materialization was disabled - for batch/streaming tasks
   *  - When new SparkClusterEnvironment was released - for streaming tasks only
   */
  MATERIALIZATION_STATUS_STATE_DRAINED = "MATERIALIZATION_STATUS_STATE_DRAINED",
  MATERIALIZATION_STATUS_STATE_MANUAL_CANCELLATION_REQUESTED =
    "MATERIALIZATION_STATUS_STATE_MANUAL_CANCELLATION_REQUESTED",
  MATERIALIZATION_STATUS_STATE_MANUALLY_CANCELLED = "MATERIALIZATION_STATUS_STATE_MANUALLY_CANCELLED",
}

export interface AttemptConsumptionInfo {
  online_rows_written?: string | undefined;
  offline_rows_written?: string | undefined;
  job_duration?: Duration | undefined;
}

/**
 * Represents a metadata and status of piece of materialization work done by the
 * Orchestrator.
 *
 * Next ID: 33
 */
export interface MaterializationAttemptStatus {
  /**
   * TODO: remove this and replace with TaskType
   * Type of a data source being processed.
   */
  data_source_type?:
    | DataSourceType
    | undefined;
  /**
   * Optional start time of the raw data time range being materialized.
   * Empty for the task/attempt that materializes a stream.
   */
  window_start_time?:
    | string
    | undefined;
  /**
   * Optional end time of the raw data time range being materialized.
   * Empty for the task/attempt that materializes a stream.
   */
  window_end_time?:
    | string
    | undefined;
  /**
   * Optional start time of the feature time range being materialized.
   * Empty for the task/attempt that materializes a stream.
   */
  feature_start_time?:
    | string
    | undefined;
  /**
   * Optional end time of the feature time range being materialized.
   * Empty for the task/attempt that materializes a stream.
   */
  feature_end_time?:
    | string
    | undefined;
  /** Materialization state of the DataBricks run. */
  materialization_state?:
    | MaterializationStatusState
    | undefined;
  /** A short, human-readable description of the state of a job. Only set when the job is in a terminal state. */
  state_message?:
    | string
    | undefined;
  /** A stringified (for some reason) RunTerminationReason */
  termination_reason?:
    | string
    | undefined;
  /** Whether the job failed to a spot instance being terminated */
  spot_instance_failure?:
    | boolean
    | undefined;
  /**
   * ID and creation time of the MaterializationTask that is being attempted to
   * materialize.
   */
  materialization_task_id?: Id | undefined;
  materialization_attempt_id?: Id | undefined;
  materialization_task_created_at?:
    | string
    | undefined;
  /**
   * Serial number of the attempt for a corresponding MaterializationTask.
   * Equal to a number of existing unsuccessful attempts for the
   * MaterializationTask plus 1. Empty if MaterializationAttemptStatus
   * represents MaterializationTask with no MaterializationTaskAttempt.
   */
  attempt_number?:
    | number
    | undefined;
  /** Spark cluster environment to use for the materialization run. */
  spark_cluster_environment_version?: number | undefined;
  tecton_runtime_version?: string | undefined;
  compute_identity?:
    | ComputeIdentity
    | undefined;
  /** URL pointing at an external UI (Databricks, Snowflake, EMR, etc.) displaying details for the materialization run. */
  run_page_url?: string | undefined;
  tecton_managed_attempt_id?: Id | undefined;
  attempt_consumption?:
    | AttemptConsumptionInfo
    | undefined;
  /**
   * Time the MaterializationTaskAttempt was created by the Orchestrator.
   * Empty if MaterializationAttemptStatus represents MaterializationTask with
   * no MaterializationTaskAttempt.
   */
  attempt_created_at?: string | undefined;
  is_permanent_failure?: boolean | undefined;
  retry_time?:
    | string
    | undefined;
  /**
   * The progress of the attempt, as a proportion in the range [0,1]. Currently
   * only populated for Snowflake.
   */
  progress?: number | undefined;
  duration?: Duration | undefined;
  allow_forced_retry?: boolean | undefined;
  allow_overwrite_retry?: boolean | undefined;
  allow_cancel?: boolean | undefined;
  allow_restart?: boolean | undefined;
  write_to_online_feature_store?: boolean | undefined;
  write_to_offline_feature_store?: boolean | undefined;
}

export interface MaterializationStatus {
  /**
   * ID of the FeatureView materialization status is being calculated for.
   * TODO(amargvela): Rename this when we force the min SDK version update.
   */
  feature_package_id?:
    | Id
    | undefined;
  /**
   * List of materialization attempts made by the Orchestrator. Contains one
   * entry per MaterializationTaskAttempt and a single entry for each
   * MaterializationTask without any MaterializationTaskAttempts scheduled.
   * Entries are sorted using the fields of MaterializationAttemptStatus with a
   * following priority:
   *   data_source_type --> window_start_time --> attempt_number -->
   *   spark_cluster_environment_version.
   */
  materialization_attempts?:
    | MaterializationAttemptStatus[]
    | undefined;
  /**
   * This message indicates error message from job schedule, for example, not
   * enough resources, etc
   */
  schedule_error_message?: string | undefined;
}
