import React, { FC, useState } from 'react';
import { FlexGroup, FlexItem, useTectonTheme } from '.';
import styled from '@emotion/styled';
import { V1Sizes } from '../../Theme/emotion';

type CheckboxSize = 's' | 'm' | 'l';
export type CheckboxState = 'on' | 'off' | 'indeterminate';

interface CheckboxProps {
  state: CheckboxState;
  onClick?: () => void;
  label?: React.ReactNode;
  size?: CheckboxSize;
  disabled?: boolean;
  noPadding?: boolean;
  hideFocus?: boolean;
}

const CheckboxWrapper = styled.div<{
  disabled?: boolean;
  focus: boolean;
  state: CheckboxState;
  paddingArray: [V1Sizes, V1Sizes];
  hideFocus: boolean;
}>`
  display: inline-block;
  background-color: ${({ theme }) => theme.v1.colors.background.transparent};
  padding: ${({ theme, paddingArray }) => theme.v1.size[paddingArray[0]]}px
    ${({ theme, paddingArray }) => theme.v1.size[paddingArray[1]]}px;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  user-select: none;

  color: ${({ theme, disabled, state }) =>
    disabled
      ? theme.v1.colors.text.disabledText
      : state === 'on' || state === 'off'
      ? theme.v1.colors.text.title
      : theme.v1.colors.text.text};

  .box-outline {
    stroke: ${({ theme }) => theme.v1.colors.border.default};
  }

  border-radius: ${({ theme }) => `${theme.v1.size['1']}px`};

  border: ${({ theme, hideFocus, focus }) =>
    hideFocus
      ? `1px solid ${theme.v1.colors.background.transparent}`
      : focus
      ? `1px solid ${theme.v1.colors.border.focus}`
      : `1px solid ${theme.v1.colors.background.transparent}`};

  &:hover {
    color: ${({ theme, disabled }) => (disabled ? theme.v1.colors.text.disabledText : theme.v1.colors.text.title)};
    background-color: ${({ theme, disabled, hideFocus }) =>
      hideFocus
        ? theme.v1.colors.background.transparent
        : disabled
        ? theme.v1.colors.background.empty
        : theme.v1.colors.background.hover};

    .box-outline {
      stroke: ${({ theme, disabled }) => (disabled ? theme.v1.colors.border.default : theme.v1.colors.border.focus)};
    }
  }

  overflow: visible;
`;

const StyledBox = styled.path<{ state: CheckboxState; disabled: boolean }>`
  fill: ${({ theme, state, disabled }) => {
    if (state === 'off') {
      return theme.v1.colors.background.empty;
    }

    if (disabled) {
      return theme.v1.colors.background.disabled;
    }

    return theme.v1.colors.background.primary;
  }};

  &:hover {
    stroke: ${({ theme }) => theme.v1.colors.border.default};
  }
`;

const StyledSvg = styled.svg`
  overflow: visible;
  overflow-clip-margin: none !important;
`;

const Empty: FC<{ size: CheckboxSize; state: CheckboxState; disabled?: boolean }> = ({ size, state, disabled }) => {
  const { theme } = useTectonTheme();

  const sizeMap: Record<CheckboxSize, string> = {
    s: '12',
    m: '14',
    l: '16',
  };

  return (
    <StyledSvg
      width={sizeMap[size]}
      height={sizeMap[size]}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledBox
        d="M0.5 6C0.5 2.96243 2.96243 0.5 6 0.5H10C13.0376 0.5 15.5 2.96243 15.5 6V10C15.5 13.0376 13.0376 15.5 10 15.5H6C2.96243 15.5 0.5 13.0376 0.5 10V6Z"
        state={state}
        className="box-outline"
        disabled={!!disabled}
      />

      {state === 'indeterminate' && (
        <rect
          x="5"
          y="5"
          width="6"
          height="6"
          fill={disabled ? theme.v1.colors.text.disabledText : theme.v1.colors.background.empty}
        />
      )}
      {state === 'on' && (
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.47693 11.0233L6.47762 11.024C6.58731 11.1326 6.73117 11.1878 6.87502 11.1878C7.01886 11.1878 7.16273 11.1326 7.27242 11.024L12.5234 5.773C12.7425 5.55286 12.7425 5.19766 12.5234 4.97751L12.5228 4.97684C12.3026 4.75781 11.9471 4.75816 11.7269 4.97718L6.87503 9.82981L4.27276 7.22684C4.05262 7.00781 3.69742 7.00781 3.47727 7.22683L3.4766 7.22751C3.25758 7.44766 3.25791 7.80319 3.47694 8.02334L6.47693 11.0233Z"
          fill={disabled ? theme.v1.colors.text.disabledText : theme.v1.colors.background.empty}
        />
      )}
    </StyledSvg>
  );
};

const Label = styled.span<{ size: CheckboxSize; state: CheckboxState }>`
  font-size: ${({ theme, size }) =>
    size === 'l' ? theme.v1.font.size.m : size === 'm' ? theme.v1.font.size.s : theme.v1.font.size.xs};

  font-weight: ${({ theme, state }) =>
    state === 'on' || state === 'indeterminate' ? theme.v1.font.weight.medium : theme.v1.font.weight.regular};
`;

const Checkbox: FC<CheckboxProps> = ({
  state,
  onClick,
  label,
  size = 'm',
  disabled = false,
  noPadding = false,
  hideFocus = false,
}) => {
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  const paddingMap: Record<CheckboxSize, [V1Sizes, V1Sizes]> = {
    s: ['0.5', '1'],
    m: ['1', '1.5'],
    l: ['1', '2'],
  };

  const gapSizeArray: Record<CheckboxSize, V1Sizes> = {
    s: '1',
    m: '1.5',
    l: '2',
  };

  return (
    <CheckboxWrapper
      data-testid="checkbox"
      onClick={() => {
        !disabled && onClick && onClick();
      }}
      focus={hasFocus}
      hideFocus={hideFocus}
      disabled={disabled}
      state={state}
      paddingArray={noPadding ? ['0', '0'] : paddingMap[size]}
      tabIndex={disabled ? undefined : 0}
      onFocus={() => !disabled && setHasFocus(true)}
      onBlur={() => !disabled && setHasFocus(false)}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          !disabled && onClick && onClick();
        }
      }}
    >
      <FlexGroup alignItems="center" padding="0" gapSize={gapSizeArray[size]}>
        <FlexItem grow={0} overflow="visible">
          <FlexGroup alignItems="center" padding="0">
            <Empty size={size} state={state} disabled={disabled} />
          </FlexGroup>
        </FlexItem>
        <Label size={size} state={state}>
          {label}
        </Label>
      </FlexGroup>
    </CheckboxWrapper>
  );
};

export default Checkbox;
