import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { Tooltip, Text, useTectonTheme } from '.';

type DragState = 'inactive' | 'hover' | 'dragging' | 'dragged';

interface DragHandleProps {
  dragCallback?: (event: React.MouseEvent) => void;
  dragEndCallback?: (event: React.MouseEvent) => void;
  dragStartCallback?: (event: React.MouseEvent) => void;
}

const DragContainer = styled.div<{ state: DragState }>`
  padding: 0;
  margin: 0;
  width: ${({ theme }) => theme.v1.size['1']}px;
  cursor: col-resize;
  border-right: ${({ theme, state }) =>
    state === 'inactive' ? `1px solid ${theme.v1.colors.border.default}` : `2px solid ${theme.v1.colors.border.focus}`};
`;

const DragHandle: FC<DragHandleProps> = ({ dragStartCallback, dragCallback, dragEndCallback }) => {
  const [dragState, setDragState] = useState<DragState>('inactive');
  const [tooltipPosition, setTooltipPosition] = useState<{ left: number; top: number }>({ left: 0, top: 0 });
  const { theme } = useTectonTheme();

  const handleMouseUp = (e: MouseEvent) => {
    e.preventDefault();
    setDragState('inactive');
    dragEndCallback && dragEndCallback(e as unknown as React.MouseEvent);
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    setDragState('dragging');
    dragCallback && dragCallback(e as unknown as React.MouseEvent);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    setDragState('dragging');
    dragStartCallback && dragStartCallback(e as unknown as React.MouseEvent);

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <>
      <DragContainer
        data-testid="drag-handle"
        onMouseEnter={(e) => {
          setDragState('hover');
          setTooltipPosition({
            left: e.nativeEvent.pageX,
            top: e.nativeEvent.pageY,
          });
        }}
        onMouseLeave={() => {
          setDragState('inactive');
        }}
        onMouseDown={handleMouseDown}
        state={dragState}
      >
        &nbsp;
        {dragState === 'hover' && (
          <div style={{ position: 'absolute', top: tooltipPosition.top, left: tooltipPosition.left }}>
            <Tooltip
              placement="left"
              openByDefault
              offsetSize={24}
              content={
                <>
                  <div>
                    <Text size="xs" color={theme.v1.colors.text.invertedText}>
                      <Text inline weight="bold" size="xs" color={theme.v1.colors.text.invertedText}>
                        Drag
                      </Text>{' '}
                      to resize
                    </Text>
                  </div>
                </>
              }
              trigger={<></>}
            />
          </div>
        )}
      </DragContainer>
    </>
  );
};

export default DragHandle;
