import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTectonTheme } from '@tecton/ComponentRedesign';
import { Theme as ThemeInterface } from '@emotion/react';

type TextElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
type FontWeight = 'regular' | 'medium' | 'bold';
export type TextSize = 'xxs' | 'xs' | 's' | 'm' | 'l';

interface TextProps {
  element?: TextElement;
  children: React.ReactNode;
  weight?: FontWeight;
  italic?: boolean;
  underlined?: boolean;
  size?: TextSize;
  code?: boolean;
  inline?: boolean;
  color?: string;
  noUnderline?: boolean;
}

const StyledText = styled.span<{
  textElement: TextElement;
  theme: ThemeInterface;
  weight?: FontWeight;
  italic?: boolean;
  underlined?: boolean;
  size?: TextSize;
  code?: boolean;
  inline?: boolean;
  color?: string;
  noUnderline?: boolean;
}>(({ theme, textElement, weight = 'regular', italic, underlined, size, code, inline, color }) => {
  const isParagraph = textElement === 'p';

  let textFontSize = theme.text.size[textElement];
  if (size) {
    textFontSize = theme.v1.font.size[size];
  }

  return {
    fontSize: textFontSize,
    fontWeight: isParagraph ? theme.font.weight[weight] ?? theme.text.weight['p'] : theme.text.weight[textElement],
    lineHeight: theme.text.lineHeight[textElement],
    fontStyle: isParagraph && italic ? 'italic' : 'none',
    textDecoration: isParagraph && underlined ? 'underline' : 'none !important',
    fontFamily: isParagraph && code ? theme.font.familyCode : theme.font.family,
    textTransform: textElement === 'h6' ? 'uppercase' : 'none',
    display: inline ? 'inline' : 'inherit',
    color: color ? `${color} !important` : theme.v1.colors.text.text,
  };
});

const Text: FC<TextProps> = ({
  element = 'p',
  weight,
  italic,
  underlined,
  size,
  code,
  inline,
  color,
  noUnderline,
  children,
}) => {
  const { theme } = useTectonTheme();

  return (
    <StyledText
      textElement={element}
      theme={theme}
      as={element}
      weight={weight}
      italic={italic}
      underlined={underlined}
      size={size}
      code={code}
      inline={inline}
      color={color}
      noUnderline={noUnderline}
      data-testid="text"
    >
      {children}
    </StyledText>
  );
};

export default Text;
