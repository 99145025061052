import React, { FC } from 'react';
import styled from '@emotion/styled';
import TectonWorkspaceSelector from './TectonWorkspaceSelector';
import { AvailableWorkspaces, Workspace } from '../ComponentRedesign/SharedComponentTypes';
import { Avatar, Popover, Tooltip, useTectonTheme } from '@tecton/ComponentRedesign';

// @svg
import { ReactComponent as Separator } from '@svg/separator.svg';
import { IconType } from '../ComponentRedesign/lib/v1';

interface TectonSideNavigationSelectedWorkspaceProps {
  isCollapsed?: boolean;
  workspaces: AvailableWorkspaces;
  isWorkspaceSelectorOpen?: boolean;
  setIsWorkspaceSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onWorkspaceSelected: (workspace: Workspace) => void;
  currentWorkspace: Workspace;
}

const Wrapper = styled.div`
  cursor: pointer;
  width: 44px;
  overflow: hidden;
`;

const SelectedWorkspaceWrapper = styled.div<{ isWorkspaceSelectorOpen?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: ${({ theme }) => theme.gap.s};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 36px 124px 20px;
  width: 200px;
  gap: ${({ theme }) => theme.gap.m};
  align-items: center;
`;

const NameAndType = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
`;

const Name = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.medium};
  width: 124px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
  font-style: normal;
  color: ${({ theme }) => theme.colors.title};
`;

const ExpandedSelectedWorkspaceWrapper = styled.div<{ isWorkspaceSelectorOpen?: boolean }>`
  width: 216px;
  padding: ${({ theme }) => theme.padding.s};
  border-radius: ${({ theme }) => theme.border.radius.medium};
  background-color: ${({ theme, isWorkspaceSelectorOpen }) =>
    isWorkspaceSelectorOpen ? theme.colors.backgroundPrimary : 'none'};

  :hover {
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  }
`;

const CollapsedSelectedWorkspace: FC<{
  workspace: Workspace;
  setIsWorkspaceSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ workspace, setIsWorkspaceSelectorOpen }) => {
  const { theme } = useTectonTheme();
  const icon: IconType = workspace.workspaceType === 'Live' ? 'ProductionWorkspace' : 'DevelopmentWorkspace';
  const backgroundColor = workspace.workspaceType === 'Live' ? theme.colors.warning : theme.colors.behindTextSuccess;

  return (
    <Wrapper
      onClick={() => {
        setIsWorkspaceSelectorOpen(true);
      }}
    >
      <Tooltip
        trigger={
          <Avatar name={workspace.name} size="l" type="icon" square icon={icon} backgroundColor={backgroundColor} />
        }
        content={workspace.name ?? ''}
        placement="right"
      />
    </Wrapper>
  );
};

const SelectedWorkspace: FC<{
  workspace: Workspace;
  isWorkspaceSelectorOpen?: boolean;
  setIsWorkspaceSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCollapsed?: boolean;
}> = ({ workspace, setIsWorkspaceSelectorOpen, isCollapsed, isWorkspaceSelectorOpen }) => {
  const { theme } = useTectonTheme();

  if (isCollapsed) {
    return <CollapsedSelectedWorkspace workspace={workspace} setIsWorkspaceSelectorOpen={setIsWorkspaceSelectorOpen} />;
  }

  const icon: IconType = workspace.workspaceType === 'Live' ? 'ProductionWorkspace' : 'DevelopmentWorkspace';
  const backgroundColor = workspace.workspaceType === 'Live' ? theme.colors.warning : theme.colors.behindTextSuccess;

  return (
    <ExpandedSelectedWorkspaceWrapper
      onClick={() => {
        setIsWorkspaceSelectorOpen(!isWorkspaceSelectorOpen);
      }}
    >
      <Grid>
        <div>
          <Avatar square size="m" type="icon" icon={icon} backgroundColor={backgroundColor} />
        </div>
        <NameAndType>
          <Name>{workspace.name}</Name>
        </NameAndType>
        <Separator />
      </Grid>
    </ExpandedSelectedWorkspaceWrapper>
  );
};

const TectonSideNavigationSelectedWorkspace: FC<TectonSideNavigationSelectedWorkspaceProps> = ({
  isCollapsed,
  workspaces,
  isWorkspaceSelectorOpen,
  setIsWorkspaceSelectorOpen,
  onWorkspaceSelected,
  currentWorkspace,
}) => {
  return (
    <SelectedWorkspaceWrapper isWorkspaceSelectorOpen={isWorkspaceSelectorOpen}>
      <Popover
        trigger={
          <SelectedWorkspace
            workspace={currentWorkspace}
            setIsWorkspaceSelectorOpen={setIsWorkspaceSelectorOpen}
            isCollapsed={isCollapsed}
            isWorkspaceSelectorOpen={isWorkspaceSelectorOpen}
          />
        }
        body={<TectonWorkspaceSelector workspaces={workspaces} onWorkspaceSelected={onWorkspaceSelected} />}
        isOpen={isWorkspaceSelectorOpen}
        placement="right"
        setIsOpen={() => {
          setIsWorkspaceSelectorOpen(!isWorkspaceSelectorOpen);
        }}
        maxWidth={900}
      />
    </SelectedWorkspaceWrapper>
  );
};

export default TectonSideNavigationSelectedWorkspace;
