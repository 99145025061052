import moment from 'moment-timezone';
import { FC } from 'react';

// generated code
import { Group, UserDetail } from '../../../api/gql/graphql';

// @tecton
import {
  AccountStatusBadge,
  Button,
  Card,
  FlexGrid,
  FlexGroup,
  FlexGroupWrapper,
  FlexItem,
  TitledItemEditable,
} from '@tecton/ComponentRedesign';
import { TectonDateFormat } from '@tecton/ComponentRedesign/utils';

import PrincipalDetailsAccountType from './PrincipalDetailsAccountType';
import TitleValueLayout from './TitleValueLayout';

// icons
import { ReactComponent as Trash } from '@tecton/ComponentRedesign/svg/trash.svg';

export interface ACLUserCardProps {
  isAdminView: boolean;
  userDetail?: UserDetail;
  assignedGroups: Group[];
  onDeleteUserClicked: VoidFunction;
  onUnlockUserClicked: VoidFunction;
  onEditAccountTypeClicked: VoidFunction;
}

const ACLUserDetailsCard: FC<ACLUserCardProps> = ({
  isAdminView,
  userDetail,
  assignedGroups,
  onDeleteUserClicked,
  onUnlockUserClicked,
  onEditAccountTypeClicked,
}) => {
  const user = userDetail?.user;

  const userInformationLists = [
    ...(isAdminView
      ? [
          {
            title: 'Account Type',
            name: 'account_type',
            content: userDetail?.accountTypeWithInheritance.effectiveAccountType,
            isEditable: true,
            renderField: () => {
              const groups =
                assignedGroups?.map((group) => {
                  return {
                    groupName: group.name ?? '',
                    groupId: group?.id ?? '',
                  };
                }) ?? [];

              const accountType =
                userDetail?.accountTypeWithInheritance.effectiveAccountType === 'Admin' ? 'Admin' : 'Regular';

              return (
                <>
                  <PrincipalDetailsAccountType
                    accountType={accountType}
                    groups={groups}
                    onEditAccountType={onEditAccountTypeClicked}
                  />
                </>
              );
            },
          },
        ]
      : []),
    {
      title: 'Email',
      name: 'email',
      isEditable: true,
      content: user?.loginEmail,
      renderField: (fieldTitle: string, fieldName: string, fieldValue: string) => {
        return (
          <>
            <TitleValueLayout title={fieldTitle} valueContent={<>{fieldValue}</>} />
          </>
        );
      },
    },
    {
      title: 'Status',
      name: 'status',
      isEditable: true,
      content: user?.oktaStatus ?? 'UNKNOWN',
      renderField: (fieldTitle: string) => {
        return (
          <>
            <TitleValueLayout
              title={fieldTitle}
              valueContent={
                <>
                  <AccountStatusBadge status={user?.oktaStatus ?? 'UNKNOWN'} />
                </>
              }
            />
          </>
        );
      },
    },
    {
      title: 'Account Created',
      name: 'account_created',
      isEditable: true,
      content: user?.createdAt ? TectonDateFormat(moment(user?.createdAt)) : '-',
      renderField: (fieldTitle: string, fieldName: string, fieldValue: string) => {
        return (
          <>
            <TitleValueLayout title={fieldTitle} valueContent={<>{fieldValue}</>} />
          </>
        );
      },
    },
    {
      title: 'Last Login',
      name: 'last_login',
      isEditable: true,
      content: user?.lastLogin ? TectonDateFormat(moment(user?.lastLogin)) : '-',
      renderField: (fieldTitle: string, fieldName: string, fieldValue: string) => {
        return (
          <>
            <TitleValueLayout title={fieldTitle} valueContent={<>{fieldValue}</>} />
          </>
        );
      },
    },
  ];

  const adminActionCard = (
    <Card title={`Delete User`}>
      <FlexGroup direction="column" gutterSize="s">
        <FlexItem>Deleting a user removes the user’s account from Tecton permanently.</FlexItem>
        <FlexItem>
          <FlexGroup direction="row" justifyContent="flexEnd">
            <FlexItem grow={false}>
              <Button
                label={`Delete User`}
                variant="dangerousAction"
                iconLeft={Trash}
                onClick={() => {
                  onDeleteUserClicked();
                }}
              />
            </FlexItem>
          </FlexGroup>
        </FlexItem>
      </FlexGroup>
    </Card>
  );

  const unlockUserActionCard = (
    <Card title={`Unlock User`}>
      <FlexGroup direction="column" gutterSize="s">
        <FlexItem>
          Users are automatically locked out after 4 unsuccessful log in attempts. Unlocking this user will allow them
          to attempt logging in again.
        </FlexItem>
        <FlexItem>
          <FlexGroup direction="row" justifyContent="flexEnd">
            <FlexItem grow={false}>
              <Button
                label={`Unlock User`}
                variant="dangerousAction"
                iconLeft={Trash}
                onClick={() => {
                  onUnlockUserClicked();
                }}
              />
            </FlexItem>
          </FlexGroup>
        </FlexItem>
      </FlexGroup>
    </Card>
  );

  return (
    <>
      <FlexGroupWrapper direction="column" gap="xl">
        <FlexItem>
          <Card title={`Overview`}>
            <FlexGrid columns={1} gutterSize="m">
              {userInformationLists.map((item, key) => {
                return (
                  <>
                    <TitledItemEditable
                      key={key}
                      fieldTitle={item.title ?? ''}
                      fieldName={item.name ?? ''}
                      fieldValue={item.content ?? ''}
                      isEditable={item?.isEditable ?? false}
                      onFieldSaveConfirm={() => {}}
                      renderField={item.renderField}
                      orientation="vertical"
                    />
                  </>
                );
              })}
            </FlexGrid>
          </Card>
        </FlexItem>
        {isAdminView && (
          <FlexItem>
            <FlexGroup direction="column" gutterSize="s">
              <FlexItem>{adminActionCard}</FlexItem>
              {user?.oktaStatus === 'LOCKED_OUT' && <FlexItem>{unlockUserActionCard}</FlexItem>}
            </FlexGroup>
          </FlexItem>
        )}
      </FlexGroupWrapper>
    </>
  );
};

export default ACLUserDetailsCard;
