import { FlexGroup, FlexItem, Spacer } from '@tecton';
import { copyToClipboard, EmptyPrompt, SearchInput, Table } from '@tecton/ComponentRedesign';
import { Badge, Flyout, KeyValueList, Link } from '@tecton/ComponentRedesign/lib/v1';
import { useEffect, useRef, useState, type FC } from 'react';

// Third party
import moment from 'moment';

// Generated files
import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';
import { useListEnvironments } from '../../../api/compute';
import { ContainerImage } from '../../../types/tecton_proto/common/container_image';
import {
  RemoteComputeType,
  RemoteEnvironmentStatus,
} from '../../../types/tecton_proto/data/remote_compute_environment';
import { BadgeColor } from '../../@tecton/ComponentRedesign/lib/v1/Badge';

// Mapping Environment status for Badge label and color.
const remoteEnvironmentStatusMap: Record<
  RemoteEnvironmentStatus,
  {
    label: string;
    color: BadgeColor;
  }
> = {
  [RemoteEnvironmentStatus.REMOTE_ENVIRONMENT_STATUS_PENDING]: {
    label: 'Pending',
    color: 'blueMist',
  },
  [RemoteEnvironmentStatus.REMOTE_ENVIRONMENT_STATUS_READY]: {
    label: 'Ready',
    color: 'green',
  },
  [RemoteEnvironmentStatus.REMOTE_ENVIRONMENT_STATUS_ERROR]: {
    label: 'Error',
    color: 'red',
  },
  [RemoteEnvironmentStatus.REMOTE_ENVIRONMENT_STATUS_DELETING]: {
    label: 'Deleting',
    color: 'yellow',
  },
  [RemoteEnvironmentStatus.REMOTE_ENVIRONMENT_STATUS_DELETION_FAILED]: {
    label: 'Deletion Failed',
    color: 'red',
  },
};

const remoteComputeType: Record<RemoteComputeType, string> = {
  [RemoteComputeType.REMOTE_COMPUTE_TYPE_CORE]: 'Core',
  [RemoteComputeType.REMOTE_COMPUTE_TYPE_EXTENDED]: 'Extended',
  [RemoteComputeType.REMOTE_COMPUTE_TYPE_SNOWPARK_DEPRECATED_DO_NOT_USE]: 'Deprecated',
  [RemoteComputeType.REMOTE_COMPUTE_TYPE_CUSTOM]: 'Custom',
};

const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.v1.colors.border.default};
  padding-bottom: ${({ theme }) => theme.v1.size['2']}px;
`;

const IDWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
`;

const FlyoutWrapper = styled.div`
  display: flex;
`;

type Environment = {
  id?: string;
  name?: string;
  description?: string;
  type?: RemoteComputeType;
  createdAt?: string;
  resolvedRequirements?: string;
  status?: RemoteEnvironmentStatus;
  materializationVersion?: string;
  sdkVersion?: string;
  remoteFunctionURL?: string;
  image_info?: ContainerImage;
};

const FlyoutContent: FC<{ environment: Environment; canScrollToPip: boolean }> = ({ environment, canScrollToPip }) => {
  const pipDependenciesRef = useRef<HTMLDivElement>(null);

  const {
    id,
    name,
    type,
    description,
    createdAt,
    resolvedRequirements,
    materializationVersion,
    sdkVersion,
    remoteFunctionURL,
    image_info,
  } = environment;

  useEffect(() => {
    if (canScrollToPip) {
      handleScroll();
    }
  }, []);

  const splitRequirements = (requirements: string) => {
    return requirements
      .replace(/\\\n\s*/g, ' ') // Remove line continuation characters and extra spaces
      .split('\n') // Split by newline
      .map((line: string) => line.trim()) // Trim each line
      .filter((line: string) => line)
      .map((line: string) => {
        return <div>{line}</div>;
      });
  };

  const handleScroll = () => {
    pipDependenciesRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  const remoteType = type ? remoteComputeType[type] : 'n/a';
  const imageInfos = [
    {
      title: 'repository_name',
      content: image_info?.repository_name ?? 'n/a',
    },
    {
      title: 'image_uri',
      content: image_info?.image_uri ?? 'n/a',
    },
    {
      title: 'image_digest',
      content: image_info?.image_digest ?? 'n/a',
    },
    {
      title: 'image_tag',
      content: image_info?.image_tag ?? 'n/a',
    },
  ];

  const items = [
    { title: 'Name', content: name },
    {
      title: 'ID',
      content: (
        <IDWrapper
          onClick={() => {
            copyToClipboard(id ?? '');
          }}
        >
          <Link url={`#`} name={id} iconRight="Copy" />
        </IDWrapper>
      ),
    },
    {
      title: 'Status',
      content: <>{remoteType}</>,
    },
    { title: 'Description', content: description },
    {
      title: 'Types',
      content: remoteType,
    },
    { title: 'Materialization Version', content: materializationVersion ?? 'n/a' },
    { title: 'Tecton Runtime Version', content: sdkVersion ?? 'n/a' },
    { title: 'Created At', content: moment(createdAt).utc().format('YYYY-MM-DD HH:mm UTC') },
  ];

  const pipDependencies = [
    {
      title: 'Pip Dependencies',
      content: resolvedRequirements ? splitRequirements(resolvedRequirements ?? '') ?? 'n/a' : 'n/a',
    },
  ];

  return (
    <FlyoutWrapper>
      <FlexGroup gutterSize="l" direction="row">
        <FlexItem>
          <Divider>
            <KeyValueList items={items} />
          </Divider>
          <FlexItem>
            <Divider ref={pipDependenciesRef}>
              <KeyValueList items={pipDependencies} />
            </Divider>
          </FlexItem>
        </FlexItem>
      </FlexGroup>
    </FlyoutWrapper>
  );
};

const ComputeEnvironmentsContainer: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const environmentId = searchParams.get('id');

  const [searchValue, setSearchValue] = useState<string>('');
  const [canShowFlyout, setCanShowFlyout] = useState(!!environmentId);
  const [flyoutEnvironment, setFlyoutEnvironment] = useState<Environment | undefined>();
  const [canScrollToPip, setCanScrollToPip] = useState(false);

  const { data, isLoading } = useListEnvironments();

  const columns = [
    {
      name: 'Status',
      key: 'status',
      field: 'status',
      sortable: true,
      width: '128px',
      render: (a: unknown, { status }: Environment) => {
        const environmentStatus =
          remoteEnvironmentStatusMap[status ?? RemoteEnvironmentStatus.REMOTE_ENVIRONMENT_STATUS_ERROR];
        return (
          <>
            <Badge label={environmentStatus.label} color={environmentStatus.color} />
          </>
        );
      },
    },
    {
      name: 'Name',
      key: 'name',
      field: 'name',
      sortable: true,
      width: '252px',
      render: (a: unknown, environment: Environment) => {
        return (
          <div
            onClick={() => {
              setCanShowFlyout(!canShowFlyout);
              setFlyoutEnvironment(environment);
              setCanScrollToPip(false);
            }}
          >
            <Link url={`?id=${environment.id}`} name={environment.name} />
          </div>
        );
      },
    },
    {
      name: 'Pip Dependencies',
      key: 'resolvedRequirements',
      field: 'resolvedRequirements',
      sortable: true,
      render: (a: unknown, environment: Environment) => {
        const count = environment.resolvedRequirements?.split('\n').length;

        return count === undefined ? (
          'n/a'
        ) : (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            onClick={() => {
              setCanShowFlyout(!canShowFlyout);
              setFlyoutEnvironment(environment);
              setCanScrollToPip(true);
            }}
          >
            <Link
              url={`?id=${environment.id}`}
              name={environment.resolvedRequirements?.split('\n').length}
              icon={'Log'}
            />
          </div>
        );
      },
    },
    {
      name: 'Materialization Version',
      key: 'materializationVersion',
      field: 'materializationVersion',
      sortable: true,
      render: (a: unknown, { materializationVersion }: Environment) => materializationVersion ?? 'n/a',
    },
    {
      name: 'Tecton Runtime Version',
      key: 'sdkVersion',
      field: 'sdkVersion',
      sortable: true,
      render: (a: unknown, { sdkVersion }: Environment) => sdkVersion ?? 'n/a',
    },
    {
      name: 'Description',
      key: 'description',
      field: 'description',
      sortable: true,
      render: (a: unknown, { description }: Environment) => description,
    },
    {
      name: 'Created At (UTC)',
      key: 'createdAt',
      field: 'createdAt',
      sortable: true,
      width: '200px',
      render: (a: unknown, { createdAt }: Environment) => {
        const date = moment(createdAt).utc().format('YYYY-MM-DD HH:mm UTC');
        return date;
      },
    },
  ];

  if (isLoading) {
    return (
      <EmptyPrompt
        title={<>Loading Environments</>}
        body={<>Environments are loading.</>}
        variant="loading"
        orientation="vertical"
      />
    );
  }

  const items: Environment[] =
    data?.remote_environments
      ?.filter((environment) => {
        if (searchValue) {
          const normalizedValue = searchValue.toLowerCase();

          return (
            environment?.name?.toLowerCase().includes(normalizedValue) ||
            environment?.type?.toLowerCase().includes(normalizedValue) ||
            environment?.description?.toLowerCase().includes(normalizedValue) ||
            environment?.created_at?.toLowerCase().includes(normalizedValue) ||
            environment?.realtime_job_environment?.tecton_transform_runtime_version
              ?.toLowerCase()
              .includes(normalizedValue) ||
            environment?.sdk_version?.toLowerCase().includes(normalizedValue)
          );
        }

        return true;
      })
      ?.map((environment) => {
        const {
          id,
          name,
          description,
          type,
          created_at: createdAt,
          resolved_requirements,
          status,
          realtime_job_environment,
          sdk_version,
          image_info,
        } = environment;

        return {
          id,
          name,
          type,
          description,
          createdAt,
          resolvedRequirements: resolved_requirements,
          status,
          materializationVersion: realtime_job_environment?.tecton_transform_runtime_version,
          remoteFunctionURL: realtime_job_environment?.remote_function_uri,
          sdkVersion: sdk_version,
          image_info,
        };
      }) ?? [];

  if (environmentId && flyoutEnvironment === undefined) {
    const foundEnvironment = items.find((environment) => {
      return environmentId === environment.id;
    });

    if (foundEnvironment) {
      setFlyoutEnvironment(foundEnvironment);
    }
  }

  return (
    <>
      <FlexGroup gutterSize="s" alignItems="center">
        <FlexItem>
          <SearchInput
            placeholder={'Search'}
            value={searchValue}
            fullWidth
            onChange={({ currentTarget }) => {
              setSearchValue(currentTarget.value);
            }}
          />
        </FlexItem>
      </FlexGroup>
      <Spacer size="xs" />
      <Table items={items} columns={columns} layout="auto" />
      <Flyout
        title={<>{flyoutEnvironment?.name}</>}
        content={
          <>{flyoutEnvironment && <FlyoutContent environment={flyoutEnvironment} canScrollToPip={canScrollToPip} />}</>
        }
        isOpen={canShowFlyout}
        setIsOpen={() => {
          setCanShowFlyout(false);
          setSearchParams(undefined);
        }}
      />
    </>
  );
};

export default ComputeEnvironmentsContainer;
