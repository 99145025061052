import React, { FC } from 'react';
import { FlexGroup, FlexItem, Icon, IconType, ResponsiveContainer, Tooltip, useTectonTheme } from '.';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

interface NavigationItemProps {
  name: string;
  url?: string;
  onClick?: () => void;
  icon: IconType;
  active?: boolean;
}

const LargeWrapper = styled.div<{ active: boolean }>`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.v1.size['2']}px;
  padding: ${({ theme }) => theme.v1.size['1']}px;

  background-color: ${({ theme, active }) =>
    active ? theme.v1.colors.background.empty : theme.v1.colors.background.default};

  font-weight: ${({ theme, active }) => (active ? theme.v1.font.weight.medium : theme.v1.font.weight.regular)};

  .color-icon {
    display: ${({ active }) => (active ? 'block' : 'none')};
  }

  .mono-icon {
    display: ${({ active }) => (active ? 'none' : 'block')};
  }

  .navigation-item-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.v1.colors.text.link};
  }

  &:hover {
    background-color: ${({ theme, active }) =>
      !active ? theme.v1.colors.background.hover : theme.v1.colors.background.empty};

    .color-icon {
      display: block;
    }

    .mono-icon {
      display: none;
    }
  }
`;

const Item: FC<NavigationItemProps> = ({ name, icon, active = false }) => {
  const { theme } = useTectonTheme();

  return (
    <>
      <LargeWrapper active={active} className="item-large">
        <FlexGroup alignItems="center" padding="0" gapSize="1.5">
          <FlexItem grow="0" shrink="0">
            <FlexGroup padding="0" alignItems="center">
              <Icon
                icon={icon}
                size="s"
                type="custom"
                color={active ? theme.v1.colors.icon.primary : theme.v1.colors.icon.secondary}
              />
            </FlexGroup>
          </FlexItem>

          <FlexGroup padding="0" alignItems="center">
            <div className="navigation-item-name">{name}</div>
          </FlexGroup>
        </FlexGroup>
      </LargeWrapper>
    </>
  );
};

const TooltipWrapper = styled.div<{ active: boolean }>`
  cursor: pointer;

  width: ${({ theme }) => theme.v1.size['8']}px;
  height: ${({ theme }) => theme.v1.size['8']}px;

  background-color: ${({ theme, active }) =>
    active ? theme.v1.colors.background.empty : theme.v1.colors.background.default};
  border-radius: ${({ theme }) => theme.v1.size['2']}px;

  .color-icon {
    display: ${({ active }) => {
      return active ? 'block' : 'none';
    }};
  }

  .mono-icon {
    display: ${({ active }) => {
      return active ? 'none' : 'block';
    }};
  }

  &:hover {
    background-color: ${({ theme, active }) =>
      !active ? theme.v1.colors.background.hover : theme.v1.colors.background.empty};
    .color-icon {
      display: block;
    }

    .mono-icon {
      display: none;
    }
  }
`;

const ItemWithTooltip: FC<NavigationItemProps> = ({ name, icon, active = false }) => {
  return (
    <Tooltip
      delay={1}
      duration={0}
      placement="right"
      offsetSize={8}
      trigger={
        <>
          <TooltipWrapper active={active} className="item-with-tooltip">
            <FlexGroup alignItems="center" padding="0" justifyContent="center">
              <Icon icon={icon} size="s" type="mono" />
            </FlexGroup>
          </TooltipWrapper>
        </>
      }
      content={name}
    />
  );
};

const ResponsiveSwitch = styled.div`
  .item-with-tooltip {
    display: none;
  }

  // Compressed Side Nav
  @container (max-width: ${({ theme }) => theme.v1.navigationBreakpoints.s}px) {
    .item-large {
      display: none;
    }
    .item-with-tooltip {
      display: block;
    }
  }

  // Large Side Nav
  @container (min-width: ${({ theme }) => theme.v1.navigationBreakpoints.s}px) {
    .item-with-tooltip {
      display: none;
    }
    .item-large {
      display: block;
    }
  }

  &:hover {
    & * {
      text-decoration: none;
    }
  }
`;

const NavigationItem: FC<NavigationItemProps> = ({
  name,
  icon,
  url = undefined,
  onClick = undefined,
  active = false,
}) => {
  return (
    <ResponsiveContainer data-testid="navigation-item">
      <ResponsiveSwitch
        onClick={
          onClick
            ? onClick
            : () => {
                return;
              }
        }
      >
        <Link to={url ?? '#'}>
          <Item name={name} icon={icon} active={active} />
          <ItemWithTooltip name={name} icon={icon} active={active} />
        </Link>
      </ResponsiveSwitch>
    </ResponsiveContainer>
  );
};

export default NavigationItem;
