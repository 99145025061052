import React, { FC } from 'react';
import { FlexGroup, FlexItem, Text } from '.';
import styled from '@emotion/styled';

interface SparkBarProps {
  label: React.ReactNode;
  value: number;
  maxValue: number;
}

const BarWrapper = styled.div`
  width: ${({ theme }) => theme.v1.size['12']}px;
`;

const Bar = styled.div<{ width: number }>`
  background-color: ${({ theme }) => theme.v1.colors.icon.disabled};
  border-radius: ${({ theme }) => theme.v1.size['1.5']}px;
  height: ${({ theme }) => theme.v1.size['1.5']}px;
  width: ${({ width }) => width * 100}%;
`;

const SparkBar: FC<SparkBarProps> = ({ label, value, maxValue }) => {
  return (
    <FlexGroup padding="0" gapSize="1" alignItems="center" data-testid="spark-bar">
      <FlexItem css={{ width: '1em', textAlign: 'right' }} grow={0}>
        <Text>{label}</Text>
      </FlexItem>
      <BarWrapper>
        <Bar width={value / maxValue} />
      </BarWrapper>
    </FlexGroup>
  );
};

export default SparkBar;
