import styled from '@emotion/styled';
import React, { FC } from 'react';
import { FlexGroup, FlexItem, ResponsiveContainer, Text } from '.';
import { useTectonTheme } from '../../Theme/ThemeProvider';

interface NavigationGroupProps {
  label?: string;
  items?: React.ReactNode[];
}

const Wrapper = styled.div`
  max-height: 100%;
  overflow: hidden;

  .full-size {
    display: block;
  }

  .compressed {
    display: none;
  }
  .navigation-label {
    padding-left: ${({ theme }) => theme.v1.size['4']}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @container (max-width: ${({ theme }) => theme.v1.navigationBreakpoints.s}px) {
    .navigation-label {
      display: none;
    }

    .full-size {
      display: none;
    }

    .compressed {
      display: none;
    }
  }
`;

const ItemsWrapper = styled.div`
  overflow: auto;
  height: 100%;
`;

const NavigationGroup: FC<NavigationGroupProps> = ({ label, items }) => {
  const { theme } = useTectonTheme();

  return (
    <ResponsiveContainer data-testid="navigation-group">
      <Wrapper>
        <FlexItem grow={'0'} shrink="0">
          {label && (
            <div className="navigation-label">
              <Text size="xs" color={theme.v1.colors.text.subduedText}>
                {label.toUpperCase()}
              </Text>
            </div>
          )}
        </FlexItem>
        <ItemsWrapper>
          <FlexItem grow={'0'} shrink="0">
            <FlexGroup direction="column" padding="0" gapSize="0.5" justifyContent="center">
              {items}
            </FlexGroup>
          </FlexItem>
        </ItemsWrapper>
      </Wrapper>
    </ResponsiveContainer>
  );
};

export default NavigationGroup;
