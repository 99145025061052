import React, { FC } from 'react';
import { RadioGroupOption } from './acl-types';
import { aclIcons } from './RoleIconName';

import { Card, Radio, FlexGroupWrapper, FlexItem, Icon, CardContentTopPadding } from '@tecton/ComponentRedesign';

interface AccountTypeRadioOptionProps {
  options: RadioGroupOption[];
  onSelection: (selectedOption: RadioGroupOption) => void;
}

const AccountTypeRadioOption: FC<AccountTypeRadioOptionProps> = ({ options, onSelection }) => {
  return (
    <>
      <FlexGroupWrapper direction="column" gap="s">
        {options?.map((option) => {
          const { id, label, description, checked } = option;
          const optionLabel = (
            <FlexGroupWrapper gap="s" direction="row" alignItems="center">
              <FlexItem grow={false}>
                <Icon type={aclIcons[`${label}`]} />
              </FlexItem>
              <FlexItem>{label}</FlexItem>
            </FlexGroupWrapper>
          );
          return (
            <FlexItem grow={false}>
              <CardContentTopPadding>
                <Card>
                  <CardContentTopPadding>
                    <Radio
                      id={id}
                      label={optionLabel}
                      description={description}
                      checked={checked}
                      onClick={() => {
                        onSelection?.call(this, option);
                      }}
                    />
                  </CardContentTopPadding>
                </Card>
              </CardContentTopPadding>
            </FlexItem>
          );
        })}
      </FlexGroupWrapper>
    </>
  );
};

export default AccountTypeRadioOption;
