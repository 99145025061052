import React, { FC } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import styled from '@emotion/styled';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { useTectonTheme } from '../../Theme/ThemeProvider';
import { CodeLanguage, customSyntaxTheme } from './CodeFormatting';
import { Avatar, CopyButton, FlexGroup, FlexItem, Text } from '.';
import moment from 'moment';

interface DiffViewerCommit {
  code: string;
  commitId: string;
  committedOn: Date;
  committedBy: string;
}

interface DiffViewerProps {
  language?: CodeLanguage;
  oldCommit: DiffViewerCommit;
  newCommit: DiffViewerCommit;
}

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;

  min-width: ${({ theme }) => theme.v1.size['180']}px;
  code {
    width: 50%;
  }

  // Remove borders and styles from general rows
  tr {
    background: none;
    border: 1px solid ${({ theme }) => theme.v1.colors.background.transparent};
  }

  // Remove borders from Diff viewer
  td {
    border: 1px solid ${({ theme }) => theme.v1.colors.background.transparent};
    padding: 0;
  }

  // Hide + or - markers, per design
  [class*='marker'] {
    display: none;
  }

  // Override borders in title row
  tbody {
    tr:first-child {
      border-collapse: collapse;

      td {
        border: 1px solid ${({ theme }) => theme.v1.colors.background.transparent};
      }
    }
  }

  [class$='line'] {
    background-color: ${({ theme }) => theme.v1.colors.background.default} !important;
  }

  // Line Numbers
  [class*='line-number'] {
    text-align: center;
    margin: auto;
    width: 2em;
    opacity: 1;
    cursor: default;
  }

  [class*='-gutter'] {
    width: 3em;
    text-align: right;
    min-width: 0;
    width: 0;
    padding: 0;
    cursor: default;
  }

  // Individual code lines
  td[class*='-content'] {
    padding: ${({ theme }) => theme.v1.size['0']}px ${({ theme }) => theme.v1.size['1']}px;
    width: calc(50% - 3em);
    code {
      span {
        white-space: pre-wrap !important; /* Ensure wrapping */
      }
    }
  }

  // The 'Expand n Lines' button (if used)
  [class*='code-fold'] {
    height: auto;
    * {
      white-space: nowrap;
      overflow: visible;
      display: block;
      width: 0px;
      padding: ${({ theme }) => theme.v1.size['0.5']}px ${({ theme }) => theme.v1.size['1']}px;
      height: auto;
      color: ${({ theme }) => theme.v1.colors.text.link};
    }
  }

  // Styling for new code

  // Override default borders for added / removed words
  [class*='word-diff'] {
    border: none;
    padding: 0;
  }

  [class*='diff-added'] {
    pre {
      color: ${({ theme }) => theme.v1.colors.text.successText};
      font-weight: ${({ theme }) => theme.v1.font.weight.bold};
    }

    [class*='line-number'] {
      opacity: 1;
      background-color: ${({ theme }) => theme.v1.colors.background.success};
      padding: 2px;
    }
  }

  [class*='word-added'] {
    * {
      color: ${({ theme }) => theme.v1.colors.text.successText};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      background-color: ${({ theme }) => theme.v1.colors.background.success};
      border: none;
    }
  }

  // Styling for changed code
  [class*='diff-removed'] {
    pre {
      color: ${({ theme }) => theme.v1.colors.text.dangerText};
      font-weight: ${({ theme }) => theme.v1.font.weight.bold};
    }

    [class*='line-number'] {
      opacity: 1;
      background-color: ${({ theme }) => theme.v1.colors.background.dangerSecondary};
    }
  }

  [class*='word-removed'] {
    * {
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.v1.colors.text.dangerText};
      background-color: ${({ theme }) => theme.v1.colors.background.dangerSecondary};
    }
  }
`;

const TitleAlignment = styled.div<{ padding: string }>`
  padding: 0 ${({ padding }) => padding}; // Offset the title to allow for space around the line numbers in the codeblock
  margin-bottom: ${({ theme }) => theme.v1.size['4']}px;
`;

const TitleWithCopy: FC<{ commit: DiffViewerCommit; label: string; padding?: string }> = ({
  commit,
  label,
  padding = '0em',
}) => {
  return (
    <TitleAlignment padding={padding}>
      <FlexGroup padding="0" direction="column" gapSize="0">
        <FlexGroup padding="0">
          <FlexItem>
            <FlexGroup padding="0" alignItems="center" gapSize="1">
              <Text element={'h4'}>{label}</Text>
              <CopyButton code={commit.code} />
            </FlexGroup>
          </FlexItem>
        </FlexGroup>
        <FlexItem>
          <Text inline>Commit ID</Text>: {commit.commitId}
        </FlexItem>
        <FlexItem>
          <FlexGroup padding="0" alignItems="center" gapSize="4">
            <Text size="xs">{moment(commit.committedOn).format('MM/DD/YYYY hh:mm:ss')}</Text>
            <FlexItem>
              <FlexGroup padding="0" alignItems="center">
                <Avatar name={commit.committedBy} size="xs" />
                <Text size="xs">{commit.committedBy}</Text>
              </FlexGroup>
            </FlexItem>
          </FlexGroup>
        </FlexItem>
      </FlexGroup>
    </TitleAlignment>
  );
};

const DiffViewer: FC<DiffViewerProps> = ({ language = 'python', oldCommit, newCommit }) => {
  const { theme } = useTectonTheme();

  const customTheme = customSyntaxTheme(theme);

  const renderPrismContent = (content: string) => {
    return (
      <SyntaxHighlighter language={language} style={customTheme} showLineNumbers={false} wrapLines>
        {content}
      </SyntaxHighlighter>
    );
  };

  return (
    <Wrapper data-testid="diff-viewer">
      <ReactDiffViewer
        oldValue={oldCommit.code}
        newValue={newCommit.code}
        splitView={true}
        leftTitle={<TitleWithCopy padding="2em" commit={oldCommit} label="Prior Version" />}
        rightTitle={<TitleWithCopy padding="0" commit={newCommit} label="Current Version" />}
        compareMethod={'diffWords' as DiffMethod}
        renderContent={renderPrismContent}
      />
    </Wrapper>
  );
};

export default DiffViewer;
