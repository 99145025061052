import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Avatar, Badge, Button, Checkbox, FlexGroup, FlexItem, Icon, IconType, Text, Tooltip, useTectonTheme } from '.';
import { AvatarProps } from './Avatar';
import { BadgeProps } from './Badge';
import { CheckboxState } from './Checkbox';

type SelectItemSize = 's' | 'm';

export interface SelectItemProps {
  name: React.ReactNode;
  size?: SelectItemSize;
  onClick?: () => void;
  onCheckboxClick?: () => void;
  description?: string;
  selectable?: boolean;
  selected?: boolean;
  onExpand?: () => void;
  activeCount?: number;
  active?: boolean;
  avatar?: AvatarProps;
  icon?: IconType;
  badge?: BadgeProps;
  tooltip?: React.ReactNode;
  shortcut?: string;
  state?: CheckboxState;
  popover?: React.ReactNode;
  renderer?: (name: React.ReactNode) => React.ReactNode;
  passedRef?: React.RefObject<HTMLDivElement>;
}

const Wrapper = styled.div<{ active?: boolean }>`
  padding: ${({ theme }) => theme.v1.size['2']}px;
  border-radius: ${({ theme }) => theme.v1.size['2']}px;
  background-color: ${({ theme, active }) =>
    active ? theme.v1.colors.background.activeFocus : theme.v1.colors.background.empty};
  cursor: pointer;

  user-select: none;

  &:hover {
    background-color: ${({ theme, active }) =>
      active ? theme.v1.colors.background.activeFocus : theme.v1.colors.background.hover};
  }
`;

const SelectItemName = styled.div`
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 0;
  flex-shrink: 1;
  line-height: var(--font-line-height-S, 20px);
  color: ${({ theme }) => theme.v1.colors.text.link};
  font-weight: ${({ theme }) => theme.v1.font.weight.regular};
  font-size: ${({ theme }) => theme.v1.font.size.s};
`;

const SelectItemDescription = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.v1.colors.text.text};
  font-style: italic;
  font-size ${({ theme }) => theme.v1.font.size.xs};
`;

const CenteredItem: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <FlexItem shrink="0" grow="0">
      <FlexGroup padding="0" gapSize="0">
        {children}
      </FlexGroup>
    </FlexItem>
  );
};

const SmallSelectItem = styled.div<{ active: boolean }>`
  width: 100%;
  height: ${({ theme }) => theme.v1.size['8']}px;
  padding: ${({ theme }) => theme.v1.size['1']}px ${({ theme }) => theme.v1.size['2']}px;

  border-radius: ${({ theme }) => theme.v1.size['1.5']}px;
  background-color: ${({ theme, active }) =>
    active ? theme.v1.colors.background.activeFocus : theme.v1.colors.background.empty};
  cursor: pointer;
  color: ${({ theme }) => theme.v1.colors.text.title};
  font-weight: ${({ theme }) => theme.v1.font.weight.medium};

  &:hover {
    background-color: ${({ theme, active }) =>
      active ? theme.v1.colors.background.activeFocus : theme.v1.colors.background.hover};
  }
`;

const SelectItem: FC<SelectItemProps> = ({
  name,
  size = 'm',
  onClick,
  description,
  selectable,
  selected,
  onExpand,
  avatar,
  icon,
  badge,
  tooltip,
  activeCount,
  active,
  state,
  popover,
  onCheckboxClick = () => {},
  renderer = (name: React.ReactNode) => {
    return <Text size="xs">{name}</Text>;
  },
  shortcut,
  passedRef,
}) => {
  const { theme } = useTectonTheme();

  if (size === 's') {
    const showBadge = popover && activeCount !== undefined && activeCount > 0;
    const showPopover = popover && !showBadge;
    return (
      <div ref={passedRef}>
        <FlexGroup padding="0" gapSize="0" css={{ height: '32px' }}>
          <SmallSelectItem active={active ?? false} onClick={onClick}>
            <FlexGroup padding="0" gapSize="2">
              <FlexItem grow="1">
                <FlexGroup padding="0" alignItems="center">
                  <Checkbox
                    hideFocus
                    state={state ?? 'off'}
                    onClick={() => {
                      onCheckboxClick();
                    }}
                    label={undefined}
                  />
                  {icon && <Icon size="xs" icon={icon} type="mono" />}
                  {renderer(name)}
                </FlexGroup>
              </FlexItem>
              {showPopover && (
                <FlexItem grow="0">
                  <FlexGroup padding="0" alignItems="center" gapSize="2">
                    <Icon icon="ChevronRight" size="xs" type="mono" />
                  </FlexGroup>
                </FlexItem>
              )}
              {showBadge && (
                <FlexItem grow="0">
                  <FlexGroup padding="0" alignItems="center" gapSize="2">
                    <Badge label={activeCount} size="s" />
                  </FlexGroup>
                </FlexItem>
              )}
            </FlexGroup>
          </SmallSelectItem>
          <FlexItem grow="0" shrink="1">
            {popover}
          </FlexItem>
        </FlexGroup>
      </div>
    );
  }

  return (
    <Wrapper onClick={onClick} tabIndex={0} role="option" active={active} ref={passedRef} data-testid="select-item">
      <FlexGroup alignItems="space-between" padding="0" gapSize="2">
        {selectable && (
          <FlexItem grow="0" shrink="0">
            <FlexGroup justifyContent="center" alignItems="center" padding="0" gapSize="0">
              <Checkbox
                hideFocus
                state={selected ? 'on' : 'off'}
                onClick={() => {
                  onCheckboxClick();
                }}
                size="m"
                label={undefined}
                noPadding
              />
            </FlexGroup>
          </FlexItem>
        )}
        <FlexItem>
          <FlexGroup direction="column" padding="0" gapSize="0">
            <FlexGroup padding="0" gapSize="0.5" justifyContent="space-between" alignItems="center">
              <FlexGroup alignItems="center" padding="0">
                {icon && (
                  <CenteredItem>
                    {' '}
                    <Icon icon={icon} type="mono" size="xs" />
                  </CenteredItem>
                )}
                {avatar && (
                  <CenteredItem>
                    <Avatar {...avatar} size="xs" />
                  </CenteredItem>
                )}
                <SelectItemName>{renderer(name)}</SelectItemName>
                {badge && (
                  <CenteredItem>
                    {' '}
                    <Badge {...badge} size="xs" />
                  </CenteredItem>
                )}
                {tooltip && (
                  <CenteredItem>
                    <Tooltip
                      trigger={
                        <CenteredItem>
                          <Icon type="mono" icon="Information" size="xs" />
                        </CenteredItem>
                      }
                      content={tooltip}
                    />
                  </CenteredItem>
                )}
              </FlexGroup>
              {shortcut && (
                <CenteredItem>
                  <Text size={'xs'} color={theme.v1.colors.text.subduedText}>
                    {shortcut}
                  </Text>
                </CenteredItem>
              )}
              {onExpand && (
                <div>
                  <Button icon="LargeChevronRight" type="ghost" onClick={onExpand} size="s" />
                </div>
              )}
              {activeCount !== undefined && (
                <div>
                  {activeCount === 0 && <Icon type="mono" size="s" icon="ChevronRight" />}
                  {activeCount > 0 && <Badge label={activeCount} size="xs" />}
                </div>
              )}
            </FlexGroup>

            <SelectItemDescription>{description}</SelectItemDescription>
          </FlexGroup>
        </FlexItem>
      </FlexGroup>
    </Wrapper>
  );
};

export default SelectItem;
