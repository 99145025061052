import { Outlet, useMatch, useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Routes } from '../../../core/routes';

// @tecton
import { Breadcrumbs, HeaderLayout, Subtitle, Tabs, ViewLayout } from '@tecton/ComponentRedesign';
import { useGetEnvironment } from '../../../api/compute';
import { FeatureFlags, useUserSettings } from '../../context/UserSettingsContext';
import { TECTON_DOCUMENTATION_LINKS } from '../application-links';

export const getComputeRoutes = (): Record<string, string> => {
  return {
    Overview: './overview',
    Environments: './environments',
    'Compute Identities': './compute-identities',
  };
};

const ComputeTabs = () => {
  const navigation = useNavigate();
  const { user, serviceAccount, group } = useParams();
  const { featureEnabled, canShowComputeIdentity } = useUserSettings();

  const canShowComputeEnvironment = featureEnabled(FeatureFlags.SHOW_COMPUTE_ENVIRONMENT);
  const isMatchComputeEnvironments = !!useMatch({ path: Routes.computeEnvironments, end: false });
  const isMatchComputeIdentities = !!useMatch({ path: Routes.computeIdentities, end: false });

  const indexRoute = {
    pathName: 'Overview',
    path: `./overview`,
    paramsContext: user,
    isMatch: !!useMatch({
      path: `${Routes.computeOverview}`,
      end: false,
    }),
  };

  const environments = canShowComputeEnvironment
    ? [
        {
          pathName: 'Environments',
          path: `./environments`,
          paramsContext: serviceAccount,
          isMatch: isMatchComputeEnvironments,
        },
      ]
    : [];

  const computeIdentities = canShowComputeIdentity
    ? [
        {
          pathName: 'Compute Identities',
          path: `./compute-identities`,
          paramsContext: group,
          isMatch: isMatchComputeIdentities,
        },
      ]
    : [];

  const pages = [indexRoute, ...environments, ...computeIdentities];

  const selectedPage = pages.find((page) => page.isMatch) ?? indexRoute;

  return (
    <Tabs
      selectedTab={`${selectedPage?.pathName}`}
      tabs={pages.map((page) => {
        return page.pathName;
      })}
      setSelectedTab={(tab: string) => {
        const routes = getComputeRoutes();
        navigation(routes[tab]);
      }}
    />
  );
};

const ComputeOutlet = () => {
  const BREAD_CRUMB_ROOT_PAGE = 'Compute';
  const { environmentId } = useParams();
  const indexRoute = {
    pathName: 'Overview',
    path: `./overview`,
    paramsContext: 'overview',
    isMatch: !!useMatch({
      path: `${Routes.computeOverview}`,
      end: false,
    }),
  };

  const pages = [
    indexRoute,
    {
      pathName: 'Environments',
      path: `./environments`,
      paramsContext: 'environments',
      isMatch: !!useMatch({ path: Routes.computeEnvironments, end: false }),
    },
    {
      pathName: 'Compute Identities',
      path: `./compute-identities`,
      paramsContext: '',
      isMatch: !!useMatch({ path: Routes.computeIdentities, end: false }),
    },
  ];

  const selectedPage = pages.find((page) => page.isMatch) ?? indexRoute;

  const isEnvironmentDetailsPage =
    !!useMatch({ path: Routes.computeEnvironmentDetails, end: false }) && !!environmentId;

  const isEntitiesSelected = !!useMatch({ path: Routes.computeEnvironments, end: false });
  const isComputeIdentitiesSelected = !!useMatch({ path: Routes.computeIdentities, end: false });

  const { data, isLoading } = useGetEnvironment(environmentId ?? '', isEnvironmentDetailsPage);

  const environmentDetail = isLoading ? [] : [{ label: data?.remote_environment?.name ?? '' }];

  const breadcrumbs = (
    <Breadcrumbs
      workspace={<Link to={`${Routes.dataPlatformConfiguration}`}>{BREAD_CRUMB_ROOT_PAGE}</Link>}
      crumbs={[
        {
          label: selectedPage.paramsContext ? (
            <Link to={`./${selectedPage?.path}`}>{selectedPage?.pathName}</Link>
          ) : (
            selectedPage?.pathName
          ),
        },
        ...environmentDetail,
      ]}
    />
  );

  const subtitle = isComputeIdentitiesSelected ? (
    <Subtitle
      description="Compute Identities manage access to compute resources in Tecton."
      learnMoreUrl={TECTON_DOCUMENTATION_LINKS.COMPUTE_IDENTITIES}
    />
  ) : isEntitiesSelected ? (
    <Subtitle description="Entities description" learnMoreUrl={TECTON_DOCUMENTATION_LINKS.COMPUTE_ENTITIES} />
  ) : (
    <></>
  );

  const tabs = <ComputeTabs />;
  const header = <HeaderLayout breadcrumbs={breadcrumbs} tabs={tabs} subtitle={subtitle} />;
  return (
    <>
      <ViewLayout header={header} body={<Outlet />} />
    </>
  );
};

export default ComputeOutlet;
