import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { FlexGroup, Icon, IconType, Loading, Badge } from '.';
import { useTectonTheme } from '../..';
import { IconSize } from './Icon';
import { Theme } from '@emotion/react';
import { BadgeProps, BadgeSize } from './Badge';

export type ButtonSize = 'xs' | 's' | 'm' | 'l';
type ButtonType = 'primary' | 'secondary' | 'ghost';
type ButtonState = 'default' | 'danger' | 'disabled' | 'loading';

export interface ButtonProps {
  label?: React.ReactNode;
  shortcut?: React.ReactNode;
  size?: ButtonSize;
  type?: ButtonType;
  state?: ButtonState;
  icon?: IconType;
  iconLeft?: IconType;
  iconRight?: IconType;
  onClick?: (e?: React.MouseEvent) => void;
  fullWidth?: boolean;
  badge?: BadgeProps;
}

const getColorsByType = (theme: Theme) => {
  const ColorsByType: Record<
    ButtonType,
    Record<
      ButtonState,
      { background: string; hover: string; color: string; border: string; shortcut: string; pressedBackground: string }
    >
  > = {
    primary: {
      default: {
        background: theme.v1.colors.background.primary,
        hover: theme.v1.colors.background.secondary,
        color: theme.v1.colors.text.invertedText,
        border: theme.v1.colors.background.transparent,
        shortcut: theme.v1.colors.text.disabledText,
        pressedBackground: theme.v1.colors.background.primary,
      },
      danger: {
        background: theme.v1.colors.background.dangerPrimary,
        hover: theme.v1.colors.background.dangerPrimaryHover,
        color: theme.v1.colors.text.invertedText,
        border: theme.v1.colors.background.transparent,
        shortcut: theme.v1.colors.text.invertedText,
        pressedBackground: theme.v1.colors.background.dangerPrimary,
      },
      disabled: {
        background: theme.v1.colors.background.disabled,
        hover: theme.v1.colors.background.disabled,
        color: theme.v1.colors.text.disabledText,
        border: theme.v1.colors.background.transparent,
        shortcut: theme.v1.colors.text.disabledText,
        pressedBackground: theme.v1.colors.background.disabled,
      },
      loading: {
        background: theme.v1.colors.background.disabled,
        hover: theme.v1.colors.background.disabled,
        color: theme.v1.colors.text.disabledText,
        border: theme.v1.colors.background.transparent,
        shortcut: theme.v1.colors.text.disabledText,
        pressedBackground: theme.v1.colors.background.disabled,
      },
    },
    secondary: {
      default: {
        background: theme.v1.colors.background.empty,
        hover: theme.v1.colors.background.hover,
        color: theme.v1.colors.text.text,
        border: theme.v1.colors.border.default,
        shortcut: theme.v1.colors.text.disabledText,
        pressedBackground: theme.v1.colors.background.active,
      },
      danger: {
        background: theme.v1.colors.background.empty,
        hover: theme.v1.colors.background.dangerSecondary,
        color: theme.v1.colors.text.dangerText,
        border: theme.v1.colors.border.danger,
        shortcut: theme.v1.colors.text.dangerText,
        pressedBackground: theme.v1.colors.background.dangerSecondaryActive,
      },
      disabled: {
        background: theme.v1.colors.background.empty,
        hover: theme.v1.colors.background.empty,
        color: theme.v1.colors.text.disabledText,
        border: theme.v1.colors.border.default,
        shortcut: theme.v1.colors.text.disabledText,
        pressedBackground: theme.v1.colors.background.empty,
      },
      loading: {
        background: theme.v1.colors.background.empty,
        hover: theme.v1.colors.background.empty,
        color: theme.v1.colors.text.disabledText,
        border: theme.v1.colors.border.default,
        shortcut: theme.v1.colors.text.disabledText,
        pressedBackground: theme.v1.colors.background.empty,
      },
    },
    ghost: {
      default: {
        background: theme.v1.colors.background.transparent,
        hover: theme.v1.colors.background.hover,
        color: theme.v1.colors.text.text,
        border: theme.v1.colors.background.transparent,
        shortcut: theme.v1.colors.text.disabledText,
        pressedBackground: theme.v1.colors.background.active,
      },
      danger: {
        background: theme.v1.colors.background.empty,
        hover: theme.v1.colors.background.dangerSecondary,
        color: theme.v1.colors.text.dangerText,
        border: theme.v1.colors.background.transparent,
        shortcut: theme.v1.colors.text.dangerText,
        pressedBackground: theme.v1.colors.background.dangerSecondaryActive,
      },
      disabled: {
        background: theme.v1.colors.background.empty,
        hover: theme.v1.colors.background.empty,
        color: theme.v1.colors.text.disabledText,
        border: theme.v1.colors.background.transparent,
        shortcut: theme.v1.colors.text.disabledText,
        pressedBackground: theme.v1.colors.background.empty,
      },
      loading: {
        background: theme.v1.colors.background.empty,
        hover: theme.v1.colors.background.empty,
        color: theme.v1.colors.text.disabledText,
        border: theme.v1.colors.background.transparent,
        shortcut: theme.v1.colors.text.disabledText,
        pressedBackground: theme.v1.colors.background.empty,
      },
    },
  };

  return ColorsByType;
};

const ButtonWrapper = styled.div<ButtonProps & { activatedByKeypress: boolean }>(
  ({ theme, size, type, state, fullWidth, label, activatedByKeypress, badge }) => {
    const ColorsByType = getColorsByType(theme);

    const cursorOptions: Record<ButtonState, string> = {
      default: 'pointer',
      danger: 'pointer',
      disabled: 'not-allowed',
      loading: 'wait',
    };

    const buttonSizeMap: Record<ButtonSize, string> = {
      l: `${9 * theme.v1.size[1]}px`,
      m: `${7 * theme.v1.size[1]}px`,
      s: `${5 * theme.v1.size[1]}px`,
      xs: `${3 * theme.v1.size[1]}px`,
    };

    return {
      display: fullWidth ? 'flex' : 'inline-flex',
      height: buttonSizeMap[size!],
      ...(!label && !badge && { width: buttonSizeMap[size!] }), // Icon-only buttons are square
      'flex-direction': 'column',
      'justify-content': 'center',
      'align-items': 'center',
      'flex-shrink': 0,
      'border-radius': theme.v1.radius[8],
      'border-width': '1px',
      'border-style': 'solid',
      'border-color': `${ColorsByType[type!][state!].border}`,
      'background-color': ColorsByType[type!][state!].background,
      color: ColorsByType[type!][state!].color,
      cursor: cursorOptions[state!],
      transition: `background-color ${theme.v1.motion.duration.xfast} ${theme.v1.motion.easing.transition};`,
      opacity: 1,
      '&:hover': {
        'background-color': ColorsByType[type!][state!].hover,
        'text-decoration': 'none',
      },
      '&:active': {
        'background-color': ColorsByType[type!][state!].pressedBackground,
      },
      '&:focus, &:focus-visible': {
        'background-color': ColorsByType[type!][state!].hover,
        border: `1px solid ${theme.v1.colors.border.secondary}`,
      },

      '.shortcut': {
        color: ColorsByType[type!][state!].shortcut,
      },

      ...(activatedByKeypress && {
        'background-color': `${ColorsByType[type!][state!].pressedBackground}`,
      }),
    };
  }
);

const ExtraPadding = styled.div<{ size: ButtonSize }>`
  // Adds additional horizontal padding around label per design
  padding: 0px
    ${({ theme, size }) => {
      const paddingSizeMap: Record<ButtonSize, string> = {
        xs: `${theme.v1.size['1']}px`,
        s: `${theme.v1.size['1']}px`,
        m: `${theme.v1.size['1.5']}px`,
        l: `${theme.v1.size['2']}px`,
      };

      return paddingSizeMap[size];
    }};
`;

const Label = styled.div<{ size: ButtonSize }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: ${({ theme, size }) => {
    const fontSizeMap: Record<ButtonSize, string> = {
      xs: theme.v1.font.size.xxs,
      s: theme.v1.font.size.xxs,
      m: theme.v1.font.size.xs,
      l: theme.v1.font.size.s,
    };

    return fontSizeMap[size];
  }};
`;

const Button: FC<ButtonProps> = ({
  label,
  shortcut,
  size = 'm',
  type = 'primary',
  state = 'default',
  icon,
  iconLeft,
  iconRight,
  onClick,
  fullWidth,
  badge,
}) => {
  const { theme } = useTectonTheme();
  const [activatedByKeypress, setActivatedByKeypress] = useState<boolean>(false);

  const IconSizeMap: Record<ButtonSize, IconSize> = {
    xs: 'xs',
    s: 'xs',
    m: 's',
    l: 'm',
  };

  const BadgeSizeMap: Record<ButtonSize, BadgeSize> = {
    xs: 'xs',
    s: 'xs',
    m: 'xs',
    l: 'm',
  };

  const ColorsByType = getColorsByType(theme);

  const preventFocusOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <ButtonWrapper
      data-testid="button"
      activatedByKeypress={activatedByKeypress}
      size={size}
      type={type}
      state={state}
      onMouseDown={preventFocusOnClick}
      onClick={(e) => {
        state !== 'disabled' && onClick && onClick(e);
      }}
      onKeyDown={(e) => {
        if (e.code === 'Enter') {
          e.preventDefault();
          setActivatedByKeypress(true);
          onClick && onClick();
          setTimeout(() => {
            setActivatedByKeypress(false);
          }, 140); // Matches theme .xfast * 2
        }
      }}
      fullWidth={fullWidth}
      label={label}
      badge={badge}
      tabIndex={state !== 'disabled' && state !== 'loading' ? 0 : undefined}
      role="button"
    >
      <FlexGroup
        css={{
          padding: theme.v1.inlineSizing.padding[size],
          width: 'auto',
          overflow: 'hidden',
        }}
        gapSize={'0'}
        alignItems="center"
        justifyContent="center"
      >
        {iconLeft && (
          <Icon icon={iconLeft} type={'custom'} size={IconSizeMap[size]} color={ColorsByType[type][state].color} />
        )}
        {state === 'loading' && <Loading size="s" />}
        {icon && <Icon icon={icon} type={'mono'} size={IconSizeMap[size]} color={ColorsByType[type][state].color} />}
        {label && (
          <ExtraPadding size={size}>
            <Label size={size}>{label}</Label>
          </ExtraPadding>
        )}
        {badge && <Badge {...badge} size={BadgeSizeMap[size]} />}
        {shortcut && (
          <ExtraPadding size={size}>
            <div className="shortcut">⌘K</div>
          </ExtraPadding>
        )}
        {iconRight && (
          <Icon icon={iconRight} type={'custom'} size={IconSizeMap[size]} color={ColorsByType[type][state].color} />
        )}
      </FlexGroup>
    </ButtonWrapper>
  );
};

export default Button;
