import styled from '@emotion/styled';
import { Card } from '@tecton/ComponentRedesign';
import React, { FC } from 'react';
import { KeyValueList } from './lib/v1';

export interface TectonFCOListItem {
  title: React.ReactNode;
  content: React.ReactNode;
}

interface TectonFCOCardProps {
  title: React.ReactNode;
  items: TectonFCOListItem[];
}

export const CardTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.padding.l};
`;

const TectonFCOCard: FC<TectonFCOCardProps> = ({ title, items }) => {
  return (
    <Card title={title} data-testid="tecton-card">
      <KeyValueList items={items} />
    </Card>
  );
};

export default TectonFCOCard;
