import React, { FC } from 'react';
import { Breadcrumbs, Callout, FlexGroup, FlexItem, Link, Tabs, Text } from '.';
import { BreadcrumbProps } from './Breadcrumbs';
import { CalloutProps } from './Callout';
import { TabsProps } from './Tabs';
import styled from '@emotion/styled';

export interface HeaderLayoutProps {
  breadcrumbs: BreadcrumbProps;
  callout?: CalloutProps;
  tabs?: TabsProps;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  learnMoreLink?: string;
}

const HeaderWrapper = styled.div`
  padding: ${({ theme }) => theme.v1.size['5']}px ${({ theme }) => theme.v1.size['6']}px
    ${({ theme }) => theme.v1.size['4']}px ${({ theme }) => theme.v1.size['6']}px;
`;

const HeaderLayout: FC<HeaderLayoutProps> = ({ breadcrumbs, title, subtitle, callout, tabs, learnMoreLink }) => {
  return (
    <HeaderWrapper data-testid="header-layout">
      <FlexGroup direction="column" padding="0" gapSize="3">
        <FlexItem grow="0" shrink="0">
          <Breadcrumbs {...breadcrumbs} />
        </FlexItem>
        <FlexItem grow="0" shrink="0">
          <FlexGroup padding="0" alignItems="center" gapSize="5">
            <FlexItem shrink="0">
              <Text element="h3">{title}</Text>
            </FlexItem>
            <FlexItem shrink="1">
              <Text>{subtitle}</Text>
            </FlexItem>
            {learnMoreLink && (
              <FlexItem grow="0" shrink="0">
                <Text weight="medium">
                  <Link url={learnMoreLink} name="Learn More" />
                </Text>
              </FlexItem>
            )}
          </FlexGroup>
        </FlexItem>
        {callout && <Callout {...callout} />}
        {tabs && <Tabs {...tabs} />}
      </FlexGroup>
    </HeaderWrapper>
  );
};

export default HeaderLayout;
