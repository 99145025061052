import React, { FC, useRef } from 'react';
import {
  Button,
  Popover,
  IconType,
  SelectItem,
  Select,
  DatePickerRange,
  Avatar,
  FlexGroup,
  Text,
  useTectonTheme,
  RangeSlider,
} from '.';
import {
  getCheckboxStateForGroup,
  handleHierarchicalGroupClick,
  handleHierarchicalSubcategoryClick,
} from './ComboFilterUtils';
import styled from '@emotion/styled';

export interface ComboFilterItemType {
  activeCount: number;
  select: React.ReactNode; // Selector
  clearFilter: () => void;
}

export interface ComboFilterCategory {
  name: string;
  icon: IconType;
  popover?: React.ReactNode;
  activeCount?: number;
  resetCallback: () => void;
}

interface ComboFilterProps {
  label?: React.ReactNode;
  icon?: IconType;
  iconRight?: IconType;
  hasAnyActiveFilters: boolean;
  activeFilterCount: number;
  resetFilters: () => void;
  categories: ComboFilterCategory[];
  selectedCategory: ComboFilterCategory | undefined;
  setSelectedCategory: (_: ComboFilterCategory | undefined) => void;
  disabled?: boolean;
}

const ClearAllButtonWrapper = styled.div`
  padding: ${({ theme }) => theme.v1.size['1']}px 0px;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.v1.size['2']}px;
  padding-bottom: ${({ theme }) => theme.v1.size['2']}px;
  border-bottom: 1px solid ${({ theme }) => theme.v1.colors.border.default};
`;

const ComboFilterItem: FC<{
  item: ComboFilterCategory;
  selectedCategory: ComboFilterCategory | undefined;
  setSelectedCategory: (_: ComboFilterCategory | undefined) => void;
}> = ({ item, selectedCategory, setSelectedCategory }) => {
  const { theme } = useTectonTheme();
  const selectRef = useRef<HTMLDivElement>(null);

  return (
    <SelectItem
      passedRef={selectRef}
      size="s"
      name={item.name}
      icon={item.icon}
      activeCount={item.activeCount}
      active={selectedCategory?.name === item.name}
      state={!item.activeCount ? 'off' : item.activeCount === 0 ? 'off' : 'on'}
      onClick={() => {
        setSelectedCategory(item);
      }}
      onCheckboxClick={() => {
        if (item.activeCount && item.activeCount > 0) {
          item.resetCallback();
        }
      }}
      popover={
        <>
          {selectedCategory?.name === item.name ? (
            <>
              <Popover
                minWidth={theme.v1.size['16']}
                maxWidth={theme.v1.size['192']}
                trigger={<></>}
                body={item.popover}
                isOpen={true}
                setIsOpen={(value) => {
                  if (!value) {
                    setSelectedCategory(undefined);
                  }
                }}
                parentRef={selectRef}
              />
            </>
          ) : (
            <></>
          )}
        </>
      }
    />
  );
};

const ComboFilter: FC<ComboFilterProps> = ({
  label,
  hasAnyActiveFilters,
  activeFilterCount,
  resetFilters,
  categories,
  selectedCategory,
  setSelectedCategory,
  icon,
  iconRight,
  disabled,
}) => {
  return (
    <>
      <div style={{ display: 'contents' }} data-testid="combo-filter">
        <Popover
          disabled={disabled}
          trigger={
            hasAnyActiveFilters ? (
              <Button
                data-testid="combo-filter-button"
                label={label}
                type={'secondary'}
                badge={{ label: activeFilterCount, size: 'xs' }}
                icon={icon}
                state={disabled ? 'disabled' : 'default'}
              />
            ) : (
              <Button
                data-testid="combo-filter-button"
                label={label}
                type={'secondary'}
                iconRight={iconRight}
                icon={icon}
                state={disabled ? 'disabled' : 'default'}
              />
            )
          }
          body={
            <>
              {' '}
              <FlexGroup alignItems="center" justifyContent="flex-start" padding="0">
                <ClearAllButtonWrapper>
                  <Button
                    type="ghost"
                    label={'Reset All'}
                    state={hasAnyActiveFilters ? 'default' : 'disabled'}
                    onClick={resetFilters}
                  />
                </ClearAllButtonWrapper>
              </FlexGroup>
              {categories.map((item) => {
                return (
                  <ComboFilterItem
                    item={item}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                  />
                );
              })}
            </>
          }
        />
      </div>
    </>
  );
};

export const ComboFilterDateSelector: FC<{
  start: Date;
  setStart: (_: Date | null | undefined) => void;
  end: Date;
  setEnd: (_: Date | null | undefined) => void;
  minDate?: Date;
  maxDate?: Date;
}> = ({ start, end, setStart, setEnd, minDate, maxDate }) => {
  console.log('MIN DATE IS', minDate);

  return (
    <>
      <DatePickerRange
        start={start}
        setStart={setStart}
        end={end}
        setEnd={setEnd}
        minDate={minDate ? minDate : new Date(0)}
        maxDate={maxDate ? maxDate : new Date()}
        minLabel="Start"
        maxLabel="End"
      />
    </>
  );
};

export const ComboFilterListSelector: FC<{
  selected: string[];
  setSelected: (selectedArray: string[]) => void;
  items: string[];
  title: string;
  icon?: IconType;
  avatar?: boolean;
  singleSelect?: boolean;
  reset?: () => void;
}> = ({ selected, setSelected, items, title, icon, avatar, singleSelect, reset }) => {
  return (
    <Select
      filterable
      reset={reset}
      groups={[
        {
          title: title,
          items: items.map((name) => {
            return {
              name: name,
              icon: icon,
              size: 's',
              renderer: (name) => {
                if (avatar) {
                  return (
                    <FlexGroup padding="0">
                      <Avatar size="xs" name={name as string} />
                      <Text size="xs">{name}</Text>
                    </FlexGroup>
                  );
                }
                return <Text size="xs">{name}</Text>;
              },
              state: selected.includes(name) ? 'on' : 'off',
              onClick: () => {
                if (singleSelect) {
                  setSelected([name]);
                  return;
                }
                if (selected.includes(name)) {
                  setSelected(selected.filter((existingName) => existingName !== name));
                  return;
                }
                setSelected(selected.concat(name));
              },
            };
          }),
        },
      ]}
    />
  );
};

export type ComboFilterHierarchySubcategoryState = Record<string, boolean>;
export type ComboFilterHierarchyState = Record<string, ComboFilterHierarchySubcategoryState>;

export const ComboFilterHierarchicalList: FC<{
  items: ComboFilterHierarchyState;
  setItems: (_: ComboFilterHierarchyState) => void;
}> = ({ items, setItems }) => {
  return (
    <Select
      filterable
      groups={Object.keys(items).map((category) => {
        return {
          title: category,
          selectable: true,
          state: getCheckboxStateForGroup(items[category]),
          onClick: () => {
            handleHierarchicalGroupClick(category, items, setItems);
          },
          items: Object.keys(items[category]).map((subcategory) => {
            return {
              name: subcategory,
              selectable: true,
              size: 's',
              state: items[category][subcategory] ? 'on' : 'off',
              hideFocus: true,
              onClick: () => {
                handleHierarchicalSubcategoryClick(category, subcategory, items, setItems);
              },
            };
          }),
        };
      })}
    />
  );
};

export const ComboFilterRangeSelector: FC<{
  value: [number, number];
  setValue: (_: [number, number]) => void;
  bounds: [number, number];
}> = ({ value, setValue, bounds }) => {
  return (
    <>
      <FlexGroup direction="column" data-testid="avatar">
        <div style={{ width: '256px' }}>
          <RangeSlider min={bounds[0]} max={bounds[1]} value={value} onChange={setValue} manualInput />
        </div>
      </FlexGroup>
    </>
  );
};

export default ComboFilter;
