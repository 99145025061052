import React, { FC, useState } from 'react';
import Slider, { SliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import styled from '@emotion/styled';
import { RenderedTooltip } from './Tooltip';
import FlexGroup from './FlexGroup';
import FlexItem from './FlexItem';
import { Input, useTectonTheme } from '.';
import { debounce } from 'lodash';

const StyledSliderContainer = styled.div`
  .rc-slider {
    position: relative;
    height: ${({ theme }) => theme.v1.size['2']}px;
    background: ${({ theme }) => theme.v1.colors.background};
    border-radius: ${({ theme }) => theme.v1.size['0.5']}px;
  }

  .rc-slider-rail {
    height: ${({ theme }) => theme.v1.size['1.5']}px;
    background: ${({ theme }) => theme.v1.colors.background.active};
    border-radius: ${({ theme }) => theme.v1.size['2']}px;
  }

  .rc-slider-track {
    background: ${({ theme }) => theme.v1.colors.background.primary};
    height: ${({ theme }) => theme.v1.size['1.5']}px;
    border-radius: 0;
    border-top-left-radius: ${({ theme }) => theme.v1.size['2']}px;
    border-bottom-left-radius: ${({ theme }) => theme.v1.size['2']}px;
  }
`;

type HandleRenderProps = {
  props: {
    style?: React.CSSProperties;
    'aria-valuenow'?: number;
  };
  isActive: boolean;
  setActive: (_: boolean) => void;
  isDragging: boolean;
  manualInput: boolean | undefined;
};

const HandleContainer = styled.div<{ left: string }>`
  position: absolute;
  top: 0px;
  cursor: pointer;
  left: ${({ left }) => left};
  translateX(-50%);
`;

const HandleRender: React.FC<HandleRenderProps> = ({ props, isActive, setActive, manualInput }) => {
  const { theme } = useTectonTheme();

  return (
    <HandleContainer
      left={props.style?.left as string}
      onMouseOver={() => {
        setActive(true);
      }}
      onMouseOut={() => {
        setActive(false);
      }}
    >
      {!manualInput && (
        <div style={{ position: 'absolute', transform: 'translateX(-50%)', top: '-23px' }}>
          <RenderedTooltip content={props['aria-valuenow']} openByDefault numberOnly />
        </div>
      )}

      <svg width={theme.v1.size['4']} height={theme.v1.size['4']} style={{ overflow: 'visible' }}>
        <circle
          cx={theme.v1.size['0']}
          cy={theme.v1.size['2']}
          r={theme.v1.size['2']}
          fill={isActive ? theme.v1.colors.background.hover : theme.v1.colors.background.empty}
          stroke={theme.v1.colors.background.primary}
          strokeWidth="1"
        />
      </svg>
    </HandleContainer>
  );
};

interface RangeSliderProps {
  min: number;
  max: number;
  step?: number;
  onChange: (value: [number, number]) => void;
  manualInput?: boolean;
  value: [number, number];
}

export const RangeSlider: React.FC<RangeSliderProps> = ({
  min,
  max,
  step = 1,
  onChange,
  manualInput = false,
  value,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  const handleChange: SliderProps['onChange'] = debounce((newValue) => {
    if (Array.isArray(newValue)) {
      if (newValue[0] >= newValue[1]) {
        return;
      }
      onChange(newValue as [number, number]);
    }
  }, 50);

  return (
    <StyledSliderContainer>
      <FlexGroup padding="6" direction="column" gapSize="4">
        {manualInput && (
          <FlexItem>
            <FlexGroup justifyContent="space-between">
              <FlexItem grow="0">
                <Input
                  value={value[0]}
                  type="number"
                  onChange={(newValue) => {
                    if (newValue < min) return;
                    onChange([newValue, value[1]]);
                  }}
                  label="Minimum"
                  size="s"
                />
              </FlexItem>
              <FlexItem grow="0">
                <Input
                  value={value[1]}
                  type="number"
                  onChange={(newValue) => {
                    if (newValue > max) return;
                    onChange([value[0], newValue]);
                  }}
                  label="Maximum"
                  size="s"
                />
              </FlexItem>
            </FlexGroup>
          </FlexItem>
        )}
        <Slider
          range
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handleChange}
          allowCross={false}
          handleRender={(props, index) => {
            return (
              <HandleRender
                manualInput={manualInput}
                {...props}
                isActive={activeIndex === index.index}
                setActive={(activeState: boolean) => {
                  if (activeState) return setActiveIndex(index.index);
                  return setActiveIndex(undefined);
                }}
                isDragging={false}
              />
            );
          }}
        />
      </FlexGroup>
    </StyledSliderContainer>
  );
};

interface UISliderProps {
  min: number;
  max: number;
  step?: number;
  onChange: (value: number) => void;
  onChangeEnd: (value: number) => void;
  manualInput?: boolean;
  value: number;
}

export const UISlider: FC<UISliderProps> = ({ min, max, step, onChange, manualInput, value }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleChange: SliderProps['onChange'] = debounce((newValue) => {
    onChange(newValue as number);
  }, 50);

  return (
    <StyledSliderContainer data-testid="slider">
      <FlexGroup padding="6" direction="column" gapSize="4">
        {manualInput && (
          <FlexItem>
            <FlexGroup justifyContent="space-between">
              <FlexItem grow="0">
                <Input
                  value={value}
                  type="number"
                  onChange={(newValue) => {
                    const newValueAsNumber = newValue as number;
                    if (newValueAsNumber < min) return;
                    if (newValueAsNumber > max) return;
                    onChange(newValue as number);
                  }}
                  label="Value"
                  size="s"
                />
              </FlexItem>
            </FlexGroup>
          </FlexItem>
        )}

        <Slider
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handleChange}
          track
          handleRender={(props) => {
            return (
              <HandleRender
                manualInput={manualInput}
                {...props}
                isActive={isActive}
                setActive={setIsActive}
                isDragging={isDragging}
              />
            );
          }}
          onBeforeChange={() => {
            setIsDragging(true);
          }}
          onAfterChange={() => {
            setIsDragging(false);
          }}
        />
      </FlexGroup>
    </StyledSliderContainer>
  );
};
export default UISlider;
