import React, { FC } from 'react';
import { EntityFCO, EntityFCOFields } from '../../../core/types/fcoTypes';
import FCOMetadataCard from '../../@tecton/ComponentRedesign/FCOMetadataCard';
import FCOTagsCard from '../../@tecton/ComponentRedesign/FCOTagsCard';
import TectonFCOCard from '../../@tecton/ComponentRedesign/FCOCard';
import { useTectonTheme } from '../../@tecton/ComponentRedesign/Theme/ThemeProvider';
import styled from '@emotion/styled';
import { ColumnLayout } from '../../@tecton/ComponentRedesign';

interface EntityCardsProps {
  entity: EntityFCO;
}

const EntityConfigCard: FC<EntityCardsProps> = ({ entity }) => {
  const { theme } = useTectonTheme();

  const Monospace = styled.span`
    font-family: ${theme.font.familyCode};
  `;

  const items = [
    {
      title: <>Join Keys</>,
      content: <Monospace>{entity[EntityFCOFields.JOIN_KEYS].join(', ')}</Monospace>,
    },
  ];

  return <TectonFCOCard items={items} title={'Configuration'} />;
};

const EntityCards: FC<EntityCardsProps> = ({ entity }) => {
  return (
    <>
      <ColumnLayout>
        <FCOMetadataCard fco={entity} />
        <FCOTagsCard fco={entity} />
        <EntityConfigCard entity={entity} />
      </ColumnLayout>
    </>
  );
};

export default EntityCards;
