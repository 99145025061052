import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { FC, useState } from 'react';

import {
  useFloating,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useInteractions,
  useHover,
  useFocus,
  Placement,
} from '@floating-ui/react';
import { useTectonTheme } from '../../Theme/ThemeProvider';
import { createPortal } from 'react-dom';

export interface TooltipProps {
  title?: React.ReactNode;
  content: React.ReactNode;
  trigger: React.ReactNode;
  openByDefault?: boolean;
  placement?: Placement;
  delay?: number;
  duration?: number;
  disabled?: boolean;
  offsetSize?: number;
  numberOnly?: boolean;
}

const fadeIn = keyframes`
   from {
       opacity: 0;
   }
   to {
       opacity: 1;
   }
`;

const TooltipContainer = styled.div<{
  openByDefault: boolean;
  delay?: number;
  duration?: number;
  numberOnly?: boolean;
}>`
  display: inline-block;
  background-color: ${({ theme }) => theme.v1.colors.background.secondary};
  color: ${({ theme }) => theme.v1.colors.text.invertedText};
  border-radius: ${({ theme }) => theme.v1.radius[6]};
  min-width: ${({ theme, numberOnly }) => (numberOnly ? 0 : theme.v1.size[16])}px;
  max-width: ${({ theme }) => theme.v1.size[80]}px;
  overflow: hidden;
  font-size: ${({ theme }) => theme.v1.font.size.xs};
  padding: ${({ theme, numberOnly }) =>
    numberOnly ? `${theme.v1.size[0.5]}px ${theme.v1.size[1.5]}px` : `${theme.v1.size[1.5]}px ${theme.v1.size[2]}px`};

  pointer-events: none;
  z-index: ${({ theme }) => theme.v1.layers.tooltip};

  opacity: ${({ openByDefault }) => (openByDefault ? '1' : '0')};

  animation: ${({ theme, openByDefault, delay, duration }) => {
    if (openByDefault) {
      return 'none';
    }

    return css`
      ${fadeIn} ${duration ? duration : theme.v1.motion.duration.xlong} ${delay
        ? delay
        : theme.v1.motion.duration.xlong} forwards
    `;
  }};
`;

const TooltipTitle = styled.div`
  font-weight: ${({ theme }) => theme.v1.font.weight.medium};
`;

const TooltipContent = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledTrigger = styled.span`
  cursor: default;
`;

export const RenderedTooltip: FC<{
  title?: React.ReactNode;
  content: React.ReactNode;
  openByDefault: boolean;
  delay?: number;
  duration?: number;
  numberOnly?: boolean;
}> = ({ title, content, openByDefault, delay, duration, numberOnly }) => {
  return (
    <TooltipContainer openByDefault={openByDefault} delay={delay} duration={duration} numberOnly={numberOnly}>
      {title && <TooltipTitle>{title}</TooltipTitle>}
      <TooltipContent>{content}</TooltipContent>
    </TooltipContainer>
  );
};

const Tooltip: FC<TooltipProps> = ({
  trigger,
  title,
  content,
  openByDefault = false,
  placement = undefined,
  delay,
  duration,
  disabled = false,
  offsetSize = 0,
  numberOnly = false,
}) => {
  const [isOpen, setIsOpen] = useState(openByDefault);

  const { theme } = useTectonTheme();

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen && disabled === false,
    onOpenChange: setIsOpen,
    middleware: [offset(offsetSize), flip({ boundary: 'clippingAncestors' }), shift()],
    placement: placement,
    strategy: 'absolute',
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    role: 'tooltip',
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  if (disabled) {
    return <>{trigger}</>;
  }

  return (
    <>
      <StyledTrigger ref={refs.setReference} {...getReferenceProps()} data-testid="tooltip-trigger">
        {trigger}
      </StyledTrigger>
      {isOpen &&
        createPortal(
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles, zIndex: theme.v1.layers.tooltip }}
            {...getFloatingProps()}
          >
            <TooltipContainer
              openByDefault={openByDefault}
              delay={delay}
              duration={duration}
              numberOnly={numberOnly}
              data-testid="tooltip-body"
            >
              {title && <TooltipTitle>{title}</TooltipTitle>}
              <TooltipContent>{content}</TooltipContent>
            </TooltipContainer>
          </div>,
          document.body
        )}
    </>
  );
};

export default Tooltip;
