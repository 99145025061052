import { EmptyPrompt } from '@tecton/ComponentRedesign';
import { useParams } from 'react-router';
import { RIFT_JOB_GRAPHS } from '../../../feature/graph-configuration';
import MonitoringGraphs from '../monitoring/MonitoringGraphs';
import { getMonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';
import { useGetJobDetails } from '../../../api/jobs';
import { getAttemptTimestamps } from './JobsUtils';

const JobMetrics = () => {
  const { jobId } = useParams();
  const { data: jobDetailData, isLoading } = useGetJobDetails(jobId ?? '');

  if (isLoading) {
    return <EmptyPrompt variant="loading" title={'Job Metrics Loading'} body={<></>} />;
  }

  const workspace = jobDetailData?.fco_locator?.workspace;

  const attemptStatus = jobDetailData?.attempt_details?.attempt_status ?? {};
  const { attempt_created_at: createdAt, duration: attemptDuration } = attemptStatus;

  if (!jobId || !createdAt || !attemptDuration || !workspace) {
    return <EmptyPrompt variant={'storm'} title={'Unable to retrieve job metadata'} body={<></>} />;
  }

  const { startDate, endDate } = getAttemptTimestamps(createdAt, attemptDuration);

  const monitoringDateRange = getMonitoringDateRange({
    option: `custom`,
    startDate,
    endDate,
  });

  return (
    <MonitoringGraphs
      monitoringDateRange={monitoringDateRange}
      graphsData={RIFT_JOB_GRAPHS}
      customGraphRecord={{}}
      workspace={workspace}
      fcoId={jobId}
    />
  );
};

export default JobMetrics;
