import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { FlexGroup, FlexItem, Tooltip, useTectonTheme } from '.';
import { V1Sizes } from '../../Theme/emotion';

type ToggleSize = 's' | 'm' | 'l';

interface ToggleProps {
  state: boolean;
  onClick: () => void;
  label: React.ReactNode;
  size?: ToggleSize;
  disabled?: boolean;
}

const ToggleWrapper = styled.div<{ disabled?: boolean; paddingArray: [V1Sizes, V1Sizes] }>`
  display: inline-block;
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: ${({ theme }) => `${theme.v1.size['2']}px`};
  pointer-events: ${({ disabled }) => (disabled ? 'not-allowed' : 'cursor')};
  user-select: none;
  padding: ${({ theme, paddingArray }) => theme.v1.size[paddingArray[0]]}px
    ${({ theme, paddingArray }) => theme.v1.size[paddingArray[1]]}px;

  ${({ disabled, theme }) =>
    !disabled &&
    `
      &:hover {
        background-color:${theme.v1.colors.background.hover};
            * {
      color: ${theme.v1.colors.text.title};
      font-weight:${theme.v1.font.weight.medium};
    }
      }
    `}
`;

const GroupContainer = styled.g<{ active: boolean; positions: { inactive: number; active: number } }>`
  transform: ${({ active, positions }) => `translate(${active ? positions.active : positions.inactive}px,0px)`};
  transition: transform ${({ theme }) => theme.v1.motion.duration.xfast}
    ${({ theme }) => theme.v1.motion.easing.transition};
`;

const Track = styled.rect`
  transition: fill ${({ theme }) => theme.v1.motion.duration.xfast} ${({ theme }) => theme.v1.motion.easing.transition};
`;

const PathTrack = styled.path`
  transition: fill ${({ theme }) => theme.v1.motion.duration.xfast} ${({ theme }) => theme.v1.motion.easing.transition};
`;

const StyledSvg = styled.svg`
  overflow: visible;
  overflow-clip-margin: none !important;
`;

const ToggleLarge: FC<{ active: boolean; size: ToggleSize; disabled: boolean }> = ({ active, size, disabled }) => {
  const { theme } = useTectonTheme();

  const circlePositions: Record<ToggleSize, { inactive: number; active: number }> = {
    s: { inactive: 5, active: 13 },
    m: { inactive: 9, active: 20 },
    l: { inactive: 10, active: 34 },
  };

  let trackColor = active ? theme.v1.colors.background.primary : theme.v1.colors.background.active;
  if (disabled == true) {
    trackColor = theme.v1.colors.background.disabled;
  }

  const indicatorColor = theme.v1.colors.background.empty;

  if (size === 'l') {
    return (
      <StyledSvg width="44" height="20" viewBox="0 0 44 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Track width="44" height="20" rx="10" fill={trackColor} />
        <GroupContainer active={active} positions={circlePositions['l']}>
          <circle cy="10" r="9.5" fill={indicatorColor} stroke={trackColor} />
        </GroupContainer>
      </StyledSvg>
    );
  }

  if (size === 'm') {
    return (
      <StyledSvg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <PathTrack
          d="M0 8C0 3.58172 3.58172 0 8 0H20C24.4183 0 28 3.58172 28 8C28 12.4183 24.4183 16 20 16H8C3.58172 16 0 12.4183 0 8Z"
          fill={trackColor}
        />
        <GroupContainer active={active} positions={circlePositions['m']}>
          <circle cy="8" r="7.5" fill={indicatorColor} stroke={trackColor} />
        </GroupContainer>
      </StyledSvg>
    );
  }

  return (
    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <PathTrack
        d="M0 5C0 2.23858 2.23858 0 5 0H13C15.7614 0 18 2.23858 18 5C18 7.76142 15.7614 10 13 10H5C2.23858 10 0 7.76142 0 5Z"
        fill={trackColor}
      />
      <GroupContainer active={active} positions={circlePositions['s']}>
        <circle cy="5" r="4" fill={indicatorColor} />
      </GroupContainer>
    </svg>
  );
};

const InnerWrapper = styled.div<{ hasFocus: boolean }>`
  border-radius: ${({ theme }) => `${theme.v1.size['1']}px`};

  border: ${({ theme, hasFocus }) =>
    hasFocus ? `1px solid ${theme.v1.colors.border.focus}` : `1px solid ${theme.v1.colors.background.transparent}`};
`;

const Label = styled.div<{ size: ToggleSize; disabled?: boolean; state: boolean }>`
  color: ${({ theme, disabled }) => (disabled ? theme.v1.colors.text.disabledText : theme.v1.colors.text.link)};
  font-size: ${({ theme, size }) => {
    return size == 's' ? theme.v1.font.size.xxs : size === 'm' ? theme.v1.font.size.s : theme.v1.font.size.m;
  }};
  font-weight: ${({ theme, disabled, state }) =>
    state && !disabled ? theme.font.weight.medium : theme.font.weight.regular};
`;

const OuterWrapper = styled.div`
  display: inline;
  overflow: visible;
`;

const Toggle: FC<ToggleProps> = ({ state, onClick, label, size = 'm', disabled = false }) => {
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  const gapSizeMap: Record<ToggleSize, V1Sizes> = {
    s: '1',
    m: '1.5',
    l: '2',
  };

  const paddingMap: Record<ToggleSize, [V1Sizes, V1Sizes]> = {
    s: ['0.5', '1'],
    m: ['1', '1.5'],
    l: ['1', '2'],
  };

  return (
    <OuterWrapper
      data-testid="toggle"
      tabIndex={disabled ? undefined : 0}
      onKeyDown={(event) => {
        if (disabled) {
          return;
        }

        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          !disabled && onClick();
        }
      }}
      onFocus={() => {
        !disabled && setHasFocus(true);
      }}
      onBlur={() => {
        !disabled && setHasFocus(false);
      }}
    >
      <FlexGroup padding="0" gapSize="0">
        <FlexItem grow="0" shrink={0} overflow="visible">
          <Tooltip
            numberOnly
            trigger={
              <ToggleWrapper
                onClick={() => {
                  if (!disabled) onClick();
                }}
                disabled={disabled}
                paddingArray={paddingMap[size]}
              >
                <InnerWrapper hasFocus={hasFocus}>
                  <FlexGroup alignItems="center">
                    <FlexItem grow={0} overflow="visible">
                      <FlexGroup alignItems="center" padding="0" gapSize={gapSizeMap[size]}>
                        <ToggleLarge active={state} size={size} disabled={disabled} />
                      </FlexGroup>
                    </FlexItem>
                    <Label size={size} state={state} disabled={disabled}>
                      {label}
                    </Label>
                  </FlexGroup>
                </InnerWrapper>
              </ToggleWrapper>
            }
            content={state ? 'On' : 'Off'}
          />
        </FlexItem>
      </FlexGroup>
    </OuterWrapper>
  );
};

export default Toggle;
