import styled from '@emotion/styled';
import { ReactComponent as SearchIcon } from '@svg/search.svg';
import { Button, FlexGroup, FlexGroupWrapper, FlexItem } from '@tecton/ComponentRedesign';
import { Text } from '@tecton/ComponentRedesign/lib/v1';
import { IconTypes } from '@tecton/ComponentRedesign/Icons/Icon';
import { AvailableWorkspaces, Workspace } from '@tecton/ComponentRedesign/SharedComponentTypes';
import React, { FC } from 'react';
import { logEvent } from '../../../utils/analytics-utils';
import {
  CollapsedContentWrapper,
  CollapsedFooterWrapper,
  CollapsedHeaderWrapper,
  CollapsedItemGroupWrapper,
  CollapsedNavWrapper,
  FixedSpacer,
  FooterBlock,
  HeaderBlock,
  MiddleSection,
  MiddleSpacer,
  NavWrapper,
} from './StyledComponents';
import TectonSideNavigationFooter from './TectonSideNavigationFooter';
import TectonSideNavigationHeader from './TectonSideNavigationHeader';
import TectonSideNavigationItem, { TectonSideNavigationItemProps } from './TectonSideNavigationItem';
import TectonSideNavigationSelectedWorkspace from './TectonSideNavigationSelectedWorkspace';
interface TectonSideNavigationProps {
  isPublicFreeTrial: boolean;
  isCollapsed?: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSearchModal: React.Dispatch<React.SetStateAction<boolean>>;
  showSearchModal?: boolean;
  isWorkspaceSelectorOpen?: boolean;
  setIsWorkspaceSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  workspaces: AvailableWorkspaces;
  onWorkspaceSelected: (workspace: Workspace) => void;
  currentWorkspace: Workspace;
  userName: string;
  email: string;
  onBellClick: () => void;
  onLogoutClick: () => void;
  workspaceItems: TectonSideNavigationItemProps[];
  generalItems: TectonSideNavigationItemProps[];
  canShowWorkspaceSelector: boolean;
  searchRef?: React.RefObject<HTMLDivElement>;
}

export const ScrollingBlock = styled.div<{ isPublicFreeTrial: boolean }>`
  background-color: ${({ theme }) => theme.colors.body};
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ isPublicFreeTrial }) => (isPublicFreeTrial ? '64px' : '0px')};
`;

const ItemGroupLabel = styled.div`
  color: ${({ theme }) => theme.v1.colors.text.subduedText};
  padding-left: 2px;
`;

const CollapsedSideNavigation: FC<TectonSideNavigationProps> = ({
  setIsCollapsed,
  onWorkspaceSelected,
  currentWorkspace,
  userName,
  email,
  onBellClick,
  onLogoutClick,
  workspaces,
  setIsWorkspaceSelectorOpen,
  isWorkspaceSelectorOpen,
  workspaceItems,
  generalItems,
  canShowWorkspaceSelector,
  setShowSearchModal,
  searchRef,
}) => {
  return (
    <CollapsedNavWrapper data-testid="sidebar" className="sideNavigation collapsed">
      <CollapsedHeaderWrapper>
        <TectonSideNavigationHeader isCollapsed setIsCollapsed={setIsCollapsed} />
      </CollapsedHeaderWrapper>
      <CollapsedContentWrapper>
        <CollapsedItemGroupWrapper>
          <>
            <TectonSideNavigationItem
              label="Search"
              icon={IconTypes.MAGNIFYING_GLASS}
              onClick={() => {
                setShowSearchModal(true);

                logEvent('Search', '', {
                  action: 'Search button clicked (collapsed)',
                  description: 'Show search modal',
                });
              }}
              isCollapsed
              ref={searchRef}
            />
          </>

          {canShowWorkspaceSelector && (
            <TectonSideNavigationSelectedWorkspace
              workspaces={workspaces}
              setIsWorkspaceSelectorOpen={setIsWorkspaceSelectorOpen}
              isWorkspaceSelectorOpen={isWorkspaceSelectorOpen}
              onWorkspaceSelected={(workspace: Workspace) => {
                setIsWorkspaceSelectorOpen(false);
                onWorkspaceSelected(workspace);
              }}
              currentWorkspace={currentWorkspace}
              isCollapsed
            />
          )}

          {workspaceItems.map((props, index) => (
            <TectonSideNavigationItem {...props} isCollapsed key={`collapsed-workspace-items-${index}`} />
          ))}
        </CollapsedItemGroupWrapper>

        <CollapsedItemGroupWrapper style={{ border: '10x solid green' }}>
          {generalItems.map((props, index) => (
            <TectonSideNavigationItem {...props} isCollapsed key={`collapsed-general-items-${index}`} />
          ))}
        </CollapsedItemGroupWrapper>
      </CollapsedContentWrapper>

      <CollapsedFooterWrapper>
        <TectonSideNavigationFooter
          isCollapsed
          userName={userName}
          email={email}
          onBellClick={onBellClick}
          onLogoutClick={onLogoutClick}
        />
      </CollapsedFooterWrapper>
    </CollapsedNavWrapper>
  );
};

const SearchWrapper = styled.div`
  height: 58px; // This is in spec
  align-items: center;
  display: flex;
`;

const TectonSideNavigation: FC<TectonSideNavigationProps> = ({
  isPublicFreeTrial,
  isCollapsed,
  setIsCollapsed,
  setShowSearchModal,
  showSearchModal,
  workspaces,
  isWorkspaceSelectorOpen,
  setIsWorkspaceSelectorOpen,
  onWorkspaceSelected,
  currentWorkspace,
  userName,
  email,
  onBellClick,
  onLogoutClick,
  workspaceItems,
  generalItems,

  canShowWorkspaceSelector,
  searchRef,
}) => {
  if (isCollapsed) {
    return (
      <CollapsedSideNavigation
        isPublicFreeTrial={isPublicFreeTrial}
        isCollapsed
        setIsCollapsed={setIsCollapsed}
        setShowSearchModal={setShowSearchModal}
        workspaces={workspaces}
        isWorkspaceSelectorOpen={isWorkspaceSelectorOpen}
        setIsWorkspaceSelectorOpen={setIsWorkspaceSelectorOpen}
        onWorkspaceSelected={onWorkspaceSelected}
        currentWorkspace={currentWorkspace}
        userName={userName}
        email={email}
        onBellClick={onBellClick}
        workspaceItems={workspaceItems}
        generalItems={generalItems}
        canShowWorkspaceSelector={canShowWorkspaceSelector}
        onLogoutClick={onLogoutClick}
      />
    );
  }

  return (
    <NavWrapper className="sideNavigation" data-testid="sidebar">
      <HeaderBlock>
        <TectonSideNavigationHeader setIsCollapsed={setIsCollapsed} />
      </HeaderBlock>
      <MiddleSection>
        <ScrollingBlock isPublicFreeTrial={isPublicFreeTrial}>
          <FlexGroup direction="column" justifyContent="spaceBetween">
            <FlexItem grow={true}>
              <div>
                {canShowWorkspaceSelector && (
                  <FlexGroupWrapper direction="column" gap={`none`}>
                    <FlexItem>
                      <SearchWrapper>
                        <Button
                          onClick={() => {
                            setShowSearchModal(true);
                            logEvent('Search', '', {
                              action: 'Search button clicked (non-collapsed)',
                              description: 'Show search modal',
                            });
                          }}
                          hint={'⌘K'}
                          variant={showSearchModal ? 'disabledAction' : 'emptyAction'}
                          label={'Search'}
                          iconLeft={SearchIcon}
                          fullWidth={true}
                          ref={searchRef}
                        />
                      </SearchWrapper>
                    </FlexItem>
                    <FlexItem>
                      <ItemGroupLabel>
                        <Text element="h6">WORKSPACE</Text>
                      </ItemGroupLabel>
                      <SearchWrapper>
                        <TectonSideNavigationSelectedWorkspace
                          workspaces={workspaces}
                          setIsWorkspaceSelectorOpen={setIsWorkspaceSelectorOpen}
                          isWorkspaceSelectorOpen={isWorkspaceSelectorOpen}
                          onWorkspaceSelected={(workspace: Workspace) => {
                            setIsWorkspaceSelectorOpen(false);
                            onWorkspaceSelected(workspace);
                          }}
                          currentWorkspace={currentWorkspace}
                        />
                      </SearchWrapper>
                    </FlexItem>
                  </FlexGroupWrapper>
                )}
                {workspaceItems.map((props, index) => (
                  <TectonSideNavigationItem {...props} key={`workspace-items-${index}`} />
                ))}
              </div>
              <MiddleSpacer />
            </FlexItem>

            <FlexItem grow={false} css={{ paddingBottom: '16px' }}>
              <div>
                <ItemGroupLabel>
                  <Text element="h6">ACCOUNT</Text>
                </ItemGroupLabel>
                {generalItems.map((props, index) => (
                  <TectonSideNavigationItem {...props} key={`general-items-${index}`} />
                ))}
              </div>
              <MiddleSpacer />
            </FlexItem>
            <FlexItem grow={false}></FlexItem>
          </FlexGroup>
        </ScrollingBlock>
        <FixedSpacer />
      </MiddleSection>
      <FooterBlock className="sideNav__footerContainer">
        <TectonSideNavigationFooter
          userName={userName}
          email={email}
          onBellClick={() => {
            return;
          }}
          onLogoutClick={onLogoutClick}
        />
      </FooterBlock>
    </NavWrapper>
  );
};

export default TectonSideNavigation;
