import { useUserSettings } from '../../context/UserSettingsContext';

export interface ComputeIdentity {
  name: string;
  applicationId: string;
  workspace: string;
  isDefault: boolean;
}
[];

export const useGetComputeIdentities = () => {
  const { allWorkspaces } = useUserSettings();

  const identities =
    allWorkspaces?.flatMap((workspace) => {
      return (
        workspace.computeIdentities.flatMap((item) => {
          return {
            name: item.name,
            applicationId: item.id,
            workspace: workspace.name,
            isDefault: item.isDefault,
          };
        }) ?? []
      );
    }) ?? [];

  return identities as ComputeIdentity[];
};
