import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import ReactDOM from 'react-dom';
import FlexGroup from './FlexGroup';
import FlexItem from './FlexItem';
import Text from './Text';
import Button from './Button';
import DragHandle from './DragHandle';
import { useTectonTheme } from '.';

type FlyinLifecycleState = 'entering' | 'entered' | 'exiting' | 'exited';

interface FlyInProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  content: React.ReactNode;
  footer: React.ReactNode;
}

interface FlyinInternalProps extends FlyInProps {
  lifecycleState: FlyinLifecycleState;
  setLifecycleState: React.Dispatch<React.SetStateAction<FlyinLifecycleState>>;
}

const enter = keyframes`
    from {
        left: 100%;
    }

    to {
        left: 0%;
    }
`;

const exit = keyframes`
    to {
        left: 100%;
    }

    from {
        left: 0%;
    }
`;

const FlyinWrapper = styled.div<{ lifecycleState: FlyinLifecycleState }>`
  height: 100%;
  background-color: ${({ theme }) => {
    return theme.v1.colors.background.empty;
  }};

  width: 100%;
  position: relative;

  border-left: ${({ theme }) => {
    return `1px solid ${theme.v1.colors.border.default}`;
  }};

  animation: ${({ theme, lifecycleState }) => {
    if (lifecycleState === 'entering') {
      return css`
        ${enter} ${theme.v1.motion.duration.fast} ${theme.v1.motion.easing.enter} forwards
      `;
    }

    if (lifecycleState === 'exiting') {
      return css`
        ${exit} ${theme.v1.motion.duration.xfast} ${theme.v1.motion.easing.exit} forwards
      `;
    }

    return ``;
  }};
`;

// This prevents lines from growing strangely during the transition in by creating a fixed layer for the content of the Flyin
const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const FlyinFooter = styled.div`
  background-color: ${({ theme }) => theme.v1.colors.background.default};
  padding: 8px 24px;
`;

const FlyinTitleArea = styled.div`
  padding: ${({ theme }) => theme.v1.size['6']}px ${({ theme }) => theme.v1.size['6']}px 0px
    ${({ theme }) => theme.v1.size['6']}px;
`;

const ContentArea = styled.div`
  padding: ${({ theme }) => theme.v1.size['6']}px;
`;

const ExitButtonPosition = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.v1.size['0']}px;
  right: ${({ theme }) => theme.v1.size['3']}px;
`;

const ExitButton: FC<{ setLifecycleState: React.Dispatch<React.SetStateAction<FlyinLifecycleState>> }> = ({
  setLifecycleState,
}) => {
  return (
    <ExitButtonPosition>
      <Button
        type="secondary"
        onClick={() => {
          setLifecycleState('exiting');
        }}
        icon="Expand"
      />
    </ExitButtonPosition>
  );
};

const FlyinInternal: FC<FlyinInternalProps> = ({
  lifecycleState,
  setLifecycleState,
  title,
  subtitle,
  content,
  footer,
  setIsOpen,
}) => {
  const { theme } = useTectonTheme();
  const [width, setWidth] = useState<number>(theme.v1.size['120']);

  return (
    <FlexGroup direction="row" padding="0" gapSize="0" css={{ width: width }} data-testid="flyin-content">
      <DragHandle
        dragCallback={(e) => {
          if (window.innerWidth - e.pageX <= theme.v1.size['180'] && window.innerWidth - e.pageX >= theme.v1.size['96'])
            setWidth(window.innerWidth - e.pageX);
        }}
      />

      <FlyinWrapper
        lifecycleState={lifecycleState}
        onAnimationEnd={() => {
          if (lifecycleState === 'exiting') {
            setIsOpen(false);
            setLifecycleState('entering');
          }
        }}
      >
        <Container>
          <FlexGroup direction="column" alignItems="space-between" gapSize="0" padding="0">
            <FlexItem grow="0" shrink={'0'}>
              <FlexGroup alignItems="flex-start" padding="0">
                <FlexItem grow={'1'}>
                  <FlyinTitleArea>
                    <FlexGroup direction="column" justifyContent="center">
                      <Text element="h3">{title}</Text>
                      {subtitle && <Text color={theme.v1.colors.text.text}>{subtitle}</Text>}
                    </FlexGroup>
                  </FlyinTitleArea>
                </FlexItem>
                <FlexItem>
                  <ExitButton setLifecycleState={setLifecycleState} />
                </FlexItem>
              </FlexGroup>
            </FlexItem>
            <FlexItem grow={'1'} shrink={'1'}>
              <ContentArea>{content}</ContentArea>
            </FlexItem>

            <FlexItem grow="0" shrink={'0'}>
              <FlyinFooter>{footer}</FlyinFooter>
            </FlexItem>
          </FlexGroup>
        </Container>
      </FlyinWrapper>
    </FlexGroup>
  );
};

const FlyIn: FC<FlyInProps> = ({ isOpen, setIsOpen, title, content, footer, subtitle }) => {
  const [lifecycleState, setLifecycleState] = useState<FlyinLifecycleState>('entering');

  const portal = document.getElementById('flyin-portal');
  if (isOpen) {
    if (portal) {
      return ReactDOM.createPortal(
        <FlyinInternal
          data-testid="flyin"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          lifecycleState={lifecycleState}
          setLifecycleState={setLifecycleState}
          title={title}
          subtitle={subtitle}
          content={content}
          footer={footer}
        />,
        portal
      );
    } else {
      console.warn('TectonUI attempted to open a Flyin but no acceptable portal was found.');
      return <></>;
    }
  }

  return <></>;
};

export default FlyIn;
