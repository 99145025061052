import React, { FC, useEffect, useRef, useState } from 'react';
import { IconType, FlexGroup, FlexItem, Icon, Text, Loading, useTectonTheme, Button } from '.';
import styled from '@emotion/styled';
import { IconSize } from './Icon';
import { V1Sizes } from '../../Theme/emotion';
import { ButtonProps } from './Button';

export type InputSize = 's' | 'm' | 'l';
export type InputValidationState = 'success' | 'error';
export type InputType = 'string' | 'number' | 'multiline';

type InputPropsByType =
  | { type: 'string' | 'multiline'; value: string | undefined; onChange: (_: string) => void }
  | { type: 'number'; value: number | undefined; onChange: (_: number) => void };

type SharedInputProps = {
  size?: InputSize;
  icon?: IconType;
  iconRight?: IconType;
  label?: React.ReactNode;
  description?: React.ReactNode;
  shortcut?: string;
  autoFocus?: boolean;
  placeholder?: string;
  onBlur?: () => void;
  validationState?: InputValidationState;
  loading?: boolean;
  disabled?: boolean;
  focusCallback?: () => void;
  button?: ButtonProps;
};

type InputProps = InputPropsByType & SharedInputProps;

const InputWrapper = styled.div<{
  validationState?: InputValidationState;
  focus: boolean;
  loading?: boolean;
  disabled?: boolean;
}>`
  cursor: ${({ loading, disabled }) => {
    return loading ? 'wait' : disabled ? 'not-allowed' : 'pointer';
  }};

  border: ${({ theme, validationState, focus }) =>
    validationState === 'error'
      ? `${theme.v1.size['0.5']}px solid ${theme.v1.colors.icon.danger}`
      : validationState === 'success'
      ? `${theme.v1.size['0.5']}px solid ${theme.v1.colors.icon.success}`
      : focus
      ? `1px solid ${theme.v1.colors.border.primary}`
      : `1px solid ${theme.v1.colors.border.default}`};
  border-radius: ${({ theme }) => `${theme.v1.size['2']}px`};

  background-color: ${({ theme, disabled, loading }) =>
    disabled || loading ? theme.v1.colors.background.disabled : theme.v1.colors.background.empty};

  &:hover {
    background-color: ${({ theme, disabled, loading }) =>
      disabled || loading ? theme.v1.colors.background.disabled : theme.v1.colors.background.hover};

    border: ${({ theme, validationState, focus, loading, disabled }) =>
      validationState === 'error'
        ? `${theme.v1.size['0.5']}px solid ${theme.v1.colors.icon.danger}`
        : validationState === 'success'
        ? `${theme.v1.size['0.5']}px solid ${theme.v1.colors.icon.success}`
        : focus
        ? `1px solid ${theme.v1.colors.border.primary}`
        : loading || disabled
        ? `1px solid ${theme.v1.colors.background.transparent}`
        : `1px solid ${theme.v1.colors.border.secondary}`};
  }

  &:active {
    border: ${({ theme, validationState, focus }) =>
      validationState === 'error'
        ? `${theme.v1.size['0.5']}px solid ${theme.v1.colors.icon.danger}`
        : validationState === 'success'
        ? `${theme.v1.size['0.5']}px solid ${theme.v1.colors.icon.success}`
        : focus
        ? `1px solid ${theme.v1.colors.border.focus}`
        : `1px solid ${theme.v1.colors.border.secondary}`};
  }
`;

const StyledInput = styled.input<{ loading?: boolean; disabled?: boolean }>`
  pointer-events: ${({ loading, disabled }) => (loading || disabled ? 'none' : 'default')};
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.v1.colors.background.transparent};

  /* Hide arrows for Chrome, Safari, Edge, and Opera */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Hide arrows for Firefox */
  -moz-appearance: textfield;
`;

const StyledTextArea = styled.textarea<{ loading?: boolean; disabled?: boolean }>`
  pointer-events: ${({ loading, disabled }) => (loading || disabled ? 'none' : 'default')};
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.v1.colors.background.transparent};
`;

const Label = styled.div<{ size: InputSize }>`
  font-size: ${({ theme, size }) => (size === 'l' ? theme.v1.font.size.s : theme.v1.font.size.xs)};
  font-weight: ${({ theme }) => theme.v1.font.weight.medium};
`;

const NumberArrows: FC<{ value: number | undefined; onChange: (_: number | undefined) => void }> = ({
  value,
  onChange,
}) => {
  const increment = () => {
    value !== undefined ? onChange(value + 1) : onChange(1);
  };

  const decrement = () => {
    value !== undefined ? onChange(value - 1) : onChange(-1);
  };
  return (
    <FlexGroup padding="0" gapSize="0" direction="column">
      <Button type="ghost" icon="LargeChevronUp" onClick={increment} size={'xs'} />
      <Button type="ghost" icon="LargeChevronDown" onClick={decrement} size={'xs'} />
    </FlexGroup>
  );
};

const Input: FC<InputProps> = ({
  size = 'm',
  value,
  icon,
  iconRight,
  label,
  description,
  shortcut,
  onChange,
  autoFocus,
  placeholder,
  onBlur,
  validationState,
  loading,
  disabled,
  focusCallback,
  type = 'string',
  button,
}) => {
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  const { theme } = useTectonTheme();

  useEffect(() => {
    if (inputRef.current) {
      if (autoFocus) {
        inputRef.current.focus();
      }
    }
  }, [autoFocus]);

  const handleFocus = () => {
    setHasFocus(true);
    focusCallback && focusCallback();
  };

  const handleBlur = () => {
    setHasFocus(false);
    onBlur && onBlur();
  };

  const handleClick = () => {
    if (loading || disabled) {
      return;
    }
    inputRef.current?.focus();
  };

  const iconSizeMap: Record<InputSize, IconSize> = {
    s: 'xs',
    m: 's',
    l: 'm',
  };

  const iconHintGapPadding: Record<InputSize, V1Sizes> = {
    s: '1.5',
    m: '2',
    l: '3',
  };

  const flexGroupPadding: Record<InputSize, string> = {
    s: `${theme.v1.size['0.5']}px ${theme.v1.size['1.5']}px`,
    m: `${theme.v1.size['1']}px ${theme.v1.size['2']}px`,
    l: `${theme.v1.size['1.5']}px ${theme.v1.size['3']}px`,
  };

  return (
    <div style={{ display: 'contents' }} data-testid="input">
      <FlexGroup direction="column" padding="0" gapSize="1.5" css={{ height: 'auto' }}>
        {label && <Label size={size}>{label}</Label>}
        <InputWrapper
          validationState={validationState}
          focus={hasFocus}
          onClick={handleClick}
          loading={loading}
          disabled={disabled}
        >
          <FlexGroup gapSize="2" css={{ padding: flexGroupPadding[size] }}>
            <FlexItem grow="0" shrink="0">
              <FlexGroup alignItems="center" padding="0" gapSize={iconHintGapPadding[size]}>
                {icon && <Icon icon={icon} type="mono" size={iconSizeMap[size]} />}
              </FlexGroup>
            </FlexItem>

            <FlexItem grow="1" shrink="1">
              {type === 'multiline' && (
                <StyledTextArea
                  placeholder={placeholder}
                  value={value}
                  onChange={(e) => {
                    (onChange as (_: string) => void)(e.target.value);
                  }}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  ref={inputRef as React.RefObject<HTMLTextAreaElement>}
                  loading={loading}
                  disabled={disabled || loading}
                />
              )}
              {type === 'string' && (
                <StyledInput
                  placeholder={placeholder}
                  value={value}
                  onChange={(e) => (onChange as (_: string) => void)(e.target.value)}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  ref={inputRef as React.RefObject<HTMLInputElement>}
                  loading={loading}
                  disabled={disabled || loading}
                />
              )}
              {type === 'number' && (
                <StyledInput
                  placeholder={placeholder}
                  value={value}
                  type="number"
                  onChange={(e) => {
                    if (e.target.value === '') {
                      (onChange as (_: number | undefined) => void)(undefined);
                      return;
                    }
                    (onChange as (_: number | undefined) => void)(e.target.value as unknown as number);
                  }}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  ref={inputRef as React.RefObject<HTMLInputElement>}
                  loading={loading}
                  disabled={disabled || loading}
                />
              )}
            </FlexItem>
            <FlexItem grow="0" shrink="0">
              <FlexGroup alignItems="center" padding="0">
                {shortcut && (
                  <Text color={theme.v1.colors.text.subduedText} size={size !== 'l' ? 'xs' : undefined}>
                    {shortcut}
                  </Text>
                )}
                {type === 'number' && (
                  <NumberArrows
                    value={value as number | undefined}
                    onChange={onChange as (_: number | undefined) => void}
                  />
                )}
                {loading && <Loading size="m" />}
                {iconRight && <Icon icon={iconRight} size="s" type="mono" />}
                {validationState === 'error' && <Icon type="color" size="s" icon="Warning" />}
                {validationState === 'success' && <Icon type="color" size="s" icon="CheckCircle" />}
                {button && (
                  <Button
                    {...button}
                    size={'s'}
                    onClick={(e) => {
                      e?.stopPropagation();
                      button!.onClick!(e);
                    }}
                  />
                )}
              </FlexGroup>
            </FlexItem>
          </FlexGroup>
        </InputWrapper>
        {description && !validationState && <Text size="xs">{description}</Text>}
        {description && validationState === 'error' && (
          <Text size="xs" color={theme.v1.colors.text.dangerText}>
            {description}
          </Text>
        )}
        {description && validationState === 'success' && (
          <Text size="xs" color={theme.v1.colors.text.successText}>
            {description}
          </Text>
        )}
      </FlexGroup>
    </div>
  );
};

export default Input;
