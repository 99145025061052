import React, { FC, useState } from 'react';
import { FlexGroup, FlexItem, useTectonTheme, Text } from '.';
import styled from '@emotion/styled';
import { V1Sizes } from '../../Theme/emotion';

type RadioSize = 's' | 'm' | 'l';

export interface RadioOption {
  label: React.ReactNode;
  description?: React.ReactNode;
}

interface RadioProps {
  items: RadioOption[];
  selectedItem: RadioOption | undefined;
  setSelectedItem: (item: RadioOption) => void;
  size?: RadioSize;
  card?: boolean;
  disabled?: boolean;
}

const StyledCircle = styled.circle<{ selected: boolean }>`
  transform: scale(${({ selected }) => (selected ? 1 : 0)})
    ${({ selected }) => (selected ? 'translate(10,10)' : 'translate(0,0)')};
  transition: transform ${({ theme }) => theme.v1.motion.duration.xfast}
    ${({ theme }) => theme.v1.motion.easing.transition};
`;

const RadioButton: FC<{ selected: boolean; size: RadioSize; disabled?: boolean; hasFocus?: boolean }> = ({
  selected,
  size,
  disabled,
  hasFocus,
}) => {
  const { theme } = useTectonTheme();

  const baseSize = size === 's' ? theme.v1.size['3'] : theme.v1.size['4'];

  return (
    <svg height={baseSize} width={baseSize} style={{ overflow: 'visible' }}>
      <circle
        r={baseSize / 2}
        cx={baseSize / 2}
        cy={baseSize / 2}
        stroke={hasFocus ? theme.v1.colors.border.focus : theme.v1.colors.border.default}
        strokeWidth={hasFocus ? theme.v1.size['1'] : theme.v1.size['0.5']}
        fill={disabled ? theme.v1.colors.background.disabled : theme.v1.colors.background.empty}
      />
      <g transform={`translate(${baseSize / 2},${baseSize / 2})`}>
        <StyledCircle
          r={baseSize / 2}
          fill={disabled ? theme.v1.colors.background.disabled : theme.v1.colors.background.primary}
          selected={selected}
        />
        <StyledCircle
          r={baseSize / 4 - 1}
          fill={disabled ? theme.v1.colors.text.disabledText : theme.v1.colors.background.empty}
          selected={selected}
        />
      </g>
    </svg>
  );
};

const FlexWrap = styled.div<{
  selected: boolean;
  size: RadioSize;
  disabled?: boolean;
  paddingArray: [V1Sizes, V1Sizes];
}>`
  display: inline-block;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  border-radius: ${({ theme }) => theme.v1.size['2']}px;
  padding: ${({ theme, paddingArray }) => theme.v1.size[paddingArray[0]]}px
    ${({ theme, paddingArray }) => theme.v1.size[paddingArray[1]]}px;

  font-size: ${({ theme, size }) => {
    const fontSizeMap: Record<RadioSize, string> = {
      s: theme.v1.font.size.xs,
      m: theme.v1.font.size.s,
      l: theme.v1.font.size.m,
    };
    return fontSizeMap[size];
  }};

  font-weight: ${({ theme, selected, size }) =>
    selected
      ? theme.v1.font.weight.medium
      : size === 's'
      ? theme.v1.font.weight.regular
      : theme.v1.font.weight.regular};

  color: ${({ theme, selected, disabled }) =>
    disabled ? theme.v1.colors.text.disabledText : selected ? theme.v1.colors.text.title : theme.v1.colors.text.text};

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.v1.colors.background.empty : theme.v1.colors.background.hover};
  }
`;

const CardWrapper = styled.div<{ selected?: boolean; disabled?: boolean; hasFocus?: boolean }>`
  padding: ${({ theme }) => theme.v1.size['6']}px;
  border-radius: ${({ theme }) => theme.v1.size['2']}px;
  border: 1px solid
    ${({ theme, selected }) => (selected ? theme.v1.colors.border.primary : theme.v1.colors.border.default)};
  background-color: ${({ theme, disabled, selected }) =>
    disabled
      ? theme.v1.colors.background.disabled
      : selected
      ? theme.v1.colors.background.default
      : theme.v1.colors.background.empty};
  color: ${({ theme, disabled }) => (disabled ? theme.v1.colors.text.disabledText : theme.v1.colors.text.text)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.v1.colors.background.disabled : theme.v1.colors.background.hover};
  }
`;

const RadioCard: FC<{
  item: RadioOption;
  selected: boolean;
  onClick: () => void;
  disabled: boolean;
  hasFocus?: boolean;
}> = ({ item, selected, onClick, disabled, hasFocus }) => {
  const { theme } = useTectonTheme();

  return (
    <CardWrapper disabled={disabled} onClick={onClick} selected={selected} hasFocus={hasFocus}>
      <FlexGroup padding="0" gapSize="2" alignItems="flex-start">
        <RadioButton selected={selected} size={'m'} disabled={disabled} hasFocus={hasFocus} />
        <div>
          <Text element="h5" color={disabled ? theme.v1.colors.text.disabledText : theme.v1.colors.text.title}>
            {item.label}
          </Text>
          <Text>{item.description}</Text>
        </div>
      </FlexGroup>
    </CardWrapper>
  );
};

const RadioCards: FC<RadioProps> = ({ items, selectedItem, setSelectedItem, disabled }) => {
  const [hasFocus, setHasFocus] = useState<boolean>();
  const [focusedIndex, setFocusedIndex] = useState<number>(0);

  return (
    <RadioWrapper
      hasFocus={hasFocus}
      tabIndex={disabled ? undefined : 0}
      onFocus={() => {
        !disabled && setHasFocus(true);
      }}
      onBlur={() => {
        setHasFocus(false);
      }}
      onKeyDown={(event) => {
        if ((hasFocus && event.key === 'Enter') || (hasFocus && event.key === ' ')) {
          event.preventDefault();
          setSelectedItem(items[focusedIndex]);
        }

        if (hasFocus && event.key === 'ArrowUp') {
          event.preventDefault();
          if (focusedIndex > 0) {
            setFocusedIndex(focusedIndex - 1);
          }
        }

        if (hasFocus && event.key === 'ArrowDown') {
          event.preventDefault();
          if (focusedIndex !== items.length - 1) {
            setFocusedIndex(focusedIndex + 1);
          }
        }
      }}
    >
      <FlexGroup direction="column" padding="0" gapSize="2">
        {items.map((item, cardIndex) => {
          return (
            <RadioCard
              item={item}
              selected={selectedItem ? selectedItem.label === item.label : false}
              onClick={() => {
                !disabled && setSelectedItem(item);
              }}
              disabled={!!disabled}
              hasFocus={hasFocus && cardIndex === focusedIndex}
            />
          );
        })}
      </FlexGroup>
    </RadioWrapper>
  );
};

const RadioWrapper = styled.div<{ hasFocus: boolean | undefined }>`
  border: ${({ theme, hasFocus }) =>
    hasFocus ? `1px solid ${theme.v1.colors.border.focus}` : `1px solid ${theme.v1.colors.background.transparent}`};

  border-radius: ${({ theme }) => `${theme.v1.size['1']}px`};
`;

const Radio: FC<RadioProps> = ({
  items,
  selectedItem,
  setSelectedItem,
  size = 'm',
  card = false,
  disabled = false,
}) => {
  const [hasFocus, setHasFocus] = useState<boolean>();
  const [focusedIndex, setFocusedIndex] = useState<number>(0);
  const gapSizeMap: Record<RadioSize, V1Sizes> = {
    s: '1',
    m: '1.5',
    l: '2',
  };

  const paddingMap: Record<RadioSize, [V1Sizes, V1Sizes]> = {
    s: ['0.5', '1'],
    m: ['1', '1.5'],
    l: ['1', '2'],
  };

  if (card) {
    return (
      <RadioCards items={items} selectedItem={selectedItem} setSelectedItem={setSelectedItem} disabled={disabled} />
    );
  }

  return (
    <RadioWrapper
      data-testid="radio-group"
      tabIndex={disabled ? undefined : 0}
      hasFocus={hasFocus}
      onFocus={() => {
        !disabled && setHasFocus(true);
      }}
      onBlur={() => {
        setHasFocus(false);
      }}
      onKeyDown={(event) => {
        if ((hasFocus && event.key === 'Enter') || (hasFocus && event.key === ' ')) {
          event.preventDefault();
          setSelectedItem(items[focusedIndex]);
        }

        if (hasFocus && event.key === 'Tab') {
          if (event.shiftKey) {
            if (focusedIndex === 0) {
              return;
            }
            if (focusedIndex > 0) {
              event.preventDefault();
              setFocusedIndex(focusedIndex - 1);
              return;
            }
          }
          if (focusedIndex !== items.length - 1) {
            event.preventDefault();
            setFocusedIndex(focusedIndex + 1);
            return;
          }
        }
      }}
    >
      <FlexGroup direction="column" padding="0" gapSize="0">
        {items.map((item, radioIndex) => {
          return (
            <FlexItem grow={0} overflow="visible">
              <FlexWrap
                size={size}
                selected={selectedItem === item}
                onClick={() => {
                  if (disabled) return;
                  setSelectedItem(item);
                }}
                disabled={disabled}
                paddingArray={paddingMap[size]}
              >
                <FlexGroup padding="0" alignItems="middle" gapSize={gapSizeMap[size]}>
                  <FlexItem grow="0" overflow="visible">
                    <FlexGroup padding="0" alignItems="center">
                      <RadioButton
                        data-testid="radio-button"
                        selected={selectedItem === item}
                        size={size}
                        disabled={disabled}
                        hasFocus={hasFocus && focusedIndex === radioIndex}
                      />
                    </FlexGroup>
                  </FlexItem>
                  <FlexItem grow="0">{item.label}</FlexItem>
                </FlexGroup>
              </FlexWrap>
            </FlexItem>
          );
        })}
      </FlexGroup>
    </RadioWrapper>
  );
};

export default Radio;
