import { FC, useState } from 'react';

import CodeDocumentIcon from '@svg/code-document.svg';
import { ButtonEmpty, Code, Flyout } from '@tecton/ComponentRedesign';

const ViewDefinitionFlyout: FC<{ type: any; definition: any }> = ({ type, definition }) => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false);

  return (
    <>
      <ButtonEmpty
        onClick={() => {
          setIsFlyoutOpen(true);
        }}
        iconType={CodeDocumentIcon}
      >
        View Definition
      </ButtonEmpty>
      {isFlyoutOpen && (
        <Flyout
          title={type}
          isOpen={isFlyoutOpen}
          setIsOpen={() => {
            setIsFlyoutOpen(!isFlyoutOpen);
          }}
          content={<Code code={definition.toString()} />}
        />
      )}
    </>
  );
};

export default ViewDefinitionFlyout;
