import styled from '@emotion/styled';
import { FlexGroup, FlexItem, Spacer } from '@tecton';

import { useState, type FC } from 'react';

//Tecton Components
import { SearchInput, Table } from '@tecton/ComponentRedesign';
import { Badge, Empty, useTectonTheme } from '@tecton/ComponentRedesign/lib/v1';
import SelectItem from '@tecton/ComponentRedesign/lib/v1/SelectItem';
import { useGetComputeIdentities } from './compute-utils';

const IDWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
`;

interface ComputeIdentity {
  name: string;
  applicationId: string;
  workspace: string;
  isDefault: boolean;
}

const ComputeIdentitiesContainer: FC = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const { theme } = useTectonTheme();

  const columns = [
    {
      name: 'ID',
      key: 'applicationId',
      field: 'applicationId',
      sortable: true,
      render: (a: unknown, item: ComputeIdentity) => {
        return (
          <IDWrapper>
            {item.applicationId} {item.isDefault && <Badge label="Default" size="s" color="gray" />}
          </IDWrapper>
        );
      },
    },
    {
      name: 'Workspaces',
      key: 'workspace',
      field: 'workspace',
      sortable: true,
      render: (a: unknown, item: ComputeIdentity) => {
        return (
          <>
            <SelectItem
              name={item.workspace}
              avatar={{
                size: 's',
                square: true,
                type: 'icon',
                icon: 'ProductionWorkspace',
                color: 'black',
                backgroundColor: theme.v1.colors.viz['5-yellow'].behindText,
              }}
            />
          </>
        );
      },
    },
  ];

  const identities = useGetComputeIdentities();

  const items = searchValue
    ? identities?.filter((identity) => {
        const includesText = `${identity.name}${identity.applicationId}${identity.workspace}`
          .toLowerCase()
          .includes(searchValue.toLowerCase());

        return includesText;
      })
    : identities;

  const hasIdentities = items.length > 0;

  return (
    <>
      <FlexGroup gutterSize="s" alignItems="center">
        <FlexItem>
          <SearchInput
            placeholder={'Search'}
            value={searchValue}
            fullWidth
            onChange={({ currentTarget }) => {
              setSearchValue(currentTarget.value);
            }}
          />
        </FlexItem>
      </FlexGroup>
      <Spacer size="xs" />
      {hasIdentities ? (
        <Table items={items} columns={columns} layout="auto" />
      ) : (
        <Empty
          placement={'full-page'}
          actions={<></>}
          useCase={'error404'}
          title={'No Compute Identities Found'}
          description={''}
        />
      )}
    </>
  );
};

export default ComputeIdentitiesContainer;
