/* eslint-disable jsx-a11y/no-autofocus */
import React, { FC, useEffect, useState } from 'react';
import { Input, FlexGroup, Text, Icon, Tooltip } from '.';
import { InputValidationState } from './Input';
import styled from '@emotion/styled';

interface EditableInlineFieldProps {
  value: string;
  onInputChange: (_: string) => void;
  isEditing: boolean;
  setIsEditing: (_: boolean) => void;
  validationState?: InputValidationState;
  blurCallback: () => void;
}

export const useEditableInlineFieldProps = ({
  initialValue,
  label,
  onChange,
  validationCallback = () => {
    return undefined;
  },
}: {
  initialValue: string;
  onChange: (_: string) => void;
  label: string;
  validationCallback?: (_: string) => InputValidationState | undefined;
}) => {
  const [defaultValue, setDefaultValue] = useState<string>(initialValue);
  const [fieldValue, setFieldValue] = useState<string>(initialValue);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [validationState, setValidationState] = useState<InputValidationState | undefined>(undefined);

  useEffect(() => {
    validationCallback && setValidationState(validationCallback(fieldValue));
  }, [fieldValue, defaultValue, validationCallback]);

  const blurCallback = () => {
    if (validationCallback(fieldValue) === 'error') {
      setFieldValue(initialValue);
    } else {
      onChange(fieldValue);
    }
  };

  return {
    defaultValue: defaultValue,
    setDefaultValue: setDefaultValue,
    value: fieldValue,
    onInputChange: setFieldValue,
    isEditing: isEditing,
    setIsEditing: setIsEditing,
    label: label,
    validationState: validationState,
    blurCallback: blurCallback,
  };
};

const FieldWrapper = styled.div`
  cursor: pointer;
  display: inline;
  flex-grow: 0;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    text-decoration: underline;
  }
`;

const EditableInlineField: FC<EditableInlineFieldProps> = ({
  value,
  onInputChange,
  isEditing,
  setIsEditing,
  blurCallback = () => {
    return;
  },
  validationState = undefined,
}) => {
  return (
    <div style={{ display: 'contents' }} data-testid="editable-inline-field">
      <Tooltip
        placement="bottom-start"
        offsetSize={4}
        trigger={
          <FlexGroup direction="column" padding="2" gapSize="0" css={{ display: 'inline-flex' }}>
            {!isEditing && (
              <FieldWrapper
                onClick={() => {
                  setIsEditing(true);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    setIsEditing(!isEditing);
                  }
                }}
                tabIndex={0}
              >
                <FlexGroup padding={'0'} gapSize="2" alignItems="center">
                  <Text>{value}</Text>
                  <Icon icon="Edit" type="mono" size={'s'} />
                </FlexGroup>
              </FieldWrapper>
            )}
            {isEditing && (
              <>
                <Input
                  onChange={onInputChange}
                  value={value}
                  autoFocus
                  onBlur={() => {
                    blurCallback();
                    setIsEditing(false);
                  }}
                  type="string"
                  size="s"
                  validationState={validationState}
                />
              </>
            )}
          </FlexGroup>
        }
        title={validationState === 'error' ? 'Invalid Input' : 'Editable Field'}
        content={
          isEditing ? (
            validationState === 'error' ? (
              <>Enter a valid value</>
            ) : (
              <>Enter or click away to save</>
            )
          ) : (
            <>Hit enter or click to edit this field.</>
          )
        }
      />
    </div>
  );
};

export default EditableInlineField;
