import {
  DataFlowEdge,
  DataFlowNode,
  DataFlowTemplateType,
  EdgeTuple,
  FeatureViewNode,
  NodesMapType,
  ODFVNode,
} from './dataFlowTypes';
import { sugiyama, graphConnect, tweakShape, layeringSimplex } from 'd3-dag';
import { STORE_PANEL_HEIGHT } from './helpers';

/**
 * Rank Mapping is used to by the layout algorithm
 * to decide where a node is position left to right
 */
const rankMapping: Record<DataFlowNode['type'], number | undefined> = {
  raw_batch_node: undefined,
  raw_stream_node: undefined,
  data_source: 1,
  feature_view: 100,
  odfv: 400,
  request_data_source: undefined,
  transformation: undefined,
  aggregation: 250,
  embeddingModel: undefined,
  store_wrapper: 200,
  online_store: undefined,
  offline_store: undefined,
  store_input: undefined,
  store_output: undefined,
  feature_service: 500,
  model_inference: undefined,
  model_trainer: undefined,
};

const NODE_SPACING_VERTICAL = 40;
const NODE_SPACING_VERTICAL_DENSE = 8;

const NODE_SPACING_HORIZONTAL = 80;

const ANCHOR_X_SHIFT = 100;

type LayoutConfigs = {
  templateType: DataFlowTemplateType;
  denseMode?: boolean;
};

export const HARDCODED_STORE_INTERNAL_POSITIONS: Record<string, { x: number; y: number }> = {
  online_store: { x: 30, y: 45 },
  offline_store: { x: 30, y: 130 },
  store_input: { x: -4, y: STORE_PANEL_HEIGHT / 2 - 4 },
  store_output: { x: 140 - 4, y: STORE_PANEL_HEIGHT / 2 - 4 },
};

const getEdgeTuplesForSugiyama = (edgesArray: DataFlowEdge[], nodesMap: NodesMapType, configs?: LayoutConfigs) => {
  const edgeTuples: EdgeTuple[] = edgesArray
    .filter((e) => {
      if (configs?.templateType === 'feature_service' || configs?.templateType === 'data_source') {
        // Special case: Request Data Sources in Feature Service Templates
        // We leave them out of the layout.

        const sourceNode = nodesMap[e.source];

        if (!sourceNode) {
          return false;
        }

        return sourceNode.type !== 'request_data_source';
      } else {
        return true;
      }
    })
    .map((e) => {
      return [e.source, e.target];
    });

  return edgeTuples;
};

export const layoutNodePositions = (
  nodesMap: NodesMapType,
  edgesArray: DataFlowEdge[],
  getNodeSize: (node: DataFlowNode) => number[],
  configs?: LayoutConfigs
) => {
  const edgeTuples: EdgeTuple[] = getEdgeTuplesForSugiyama(edgesArray, nodesMap, configs);
  const builder = graphConnect();
  const dag = builder(edgeTuples);
  const rect = tweakShape(() => [200, 10]);
  const verticalGap = configs?.denseMode ? NODE_SPACING_VERTICAL_DENSE : NODE_SPACING_VERTICAL;

  // We parameterize the sugiyama()
  // function, setting:
  // * a node size look up function.
  // * the gap size
  // ... etc.
  const layout = sugiyama()
    // Layering enforces that data sources (hopefully)
    // always come before FV and its internals
    .layering(
      layeringSimplex().rank(({ data }) => {
        const node = nodesMap[data];

        if (node.type === 'feature_view' && node.isAnchor) {
          /**
           * If the node is the anchor output node
           * (i.e. the schema count indicator on the
           * feature view wrapper) - its rank should be
           * behind any aggregations (120) and in front
           * of ODFVs (400)
           *
           * See 'rankMapping' above.
           */
          return 300;
        }

        if (configs?.templateType === 'ondemand_fv') {
          if (node.type === 'request_data_source') {
            return 260;
          }
        }

        return rankMapping[node.type];
      })
    )
    // .coord(coordCenter())
    // Somehow Typescript couldn't find the nodeSize() method, even though
    // it clearly exists in the library, hence this ignore.
    // @ts-ignore
    .nodeSize((node) => {
      const n = nodesMap[node.data];

      if (!n) {
        throw new Error(`Cannot find node with id ${node.data}`);
      }

      return getNodeSize(n);
    })
    .gap([verticalGap, NODE_SPACING_HORIZONTAL])
    .tweaks([rect]);

  // We then use the layout function to to actually
  // layout the dag.
  layout(dag);

  const positionMap: Record<string, { x: number; y: number }> = {};

  const debugNodeIds = new Set<string>(Object.keys(nodesMap));

  let anchorNode: FeatureViewNode | ODFVNode | undefined = undefined;

  // We then iterate over the nodes in the dag
  // and extract their positions.
  for (const sugiyamaNode of dag.nodes()) {
    const id = sugiyamaNode.data;

    const node = nodesMap[id];
    if (node.isAnchor && (node.type === 'feature_view' || node.type === 'odfv')) {
      anchorNode = node;
    }

    const nodeSize = getNodeSize(nodesMap[id]);

    const position: { x: number; y: number } = HARDCODED_STORE_INTERNAL_POSITIONS[id] || {
      x: sugiyamaNode.y - nodeSize[1] / 2,
      y: sugiyamaNode.x - nodeSize[0] / 2,
    };

    debugNodeIds.delete(id);

    positionMap[id] = position;
  }

  // If we have an anchor node, we shift all nodes to the right of it
  // to accommodate the wrapper node.
  if (anchorNode) {
    const originalAnchorX = positionMap[anchorNode.id].x;

    Object.entries(positionMap).forEach(([id, position]) => {
      if (position.x >= originalAnchorX) {
        positionMap[id].x = position.x + ANCHOR_X_SHIFT;
      }
    });
  }

  if (debugNodeIds.size > 0) {
    console.warn(`Not every node has been given a position. ${JSON.stringify([...debugNodeIds])}`);
  }

  return positionMap;
};
