import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useUserSettings } from '../../context/UserSettingsContext';
import { FlexGroup, FlexItem, Tooltip } from '@tecton/ComponentRedesign';

// icons
import { ReactComponent as CollapseIcon } from '@svg/Collapse.svg';
import { ReactComponent as ExpandIcon } from '@svg/Expand.svg';
import { ReactComponent as TectonText } from '@svg/Tecton.svg';
import { ReactComponent as TectonLogo } from '@svg/TectonLogo.svg';

interface TectonSideNavigationHeaderProps {
  isCollapsed?: boolean;
  setIsCollapsed?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CollapsedIconWrapper = styled.div`
  cursor: pointer;
  :hover {
    path,
    rect,
    ellipse {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const FlexIconWrapper = styled(FlexItem)`
  cursor: pointer;
  :hover {
    path,
    ellipse,
    rect {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const FlexHeader = styled(FlexGroup)`
  padding: ${({ theme }) => theme.padding.s};
  margin-bottom: 10px;
  padding-right: ${({ theme }) => theme.padding.xs}; ;
`;

const SideNavigationHeaderCollapsed: FC<TectonSideNavigationHeaderProps> = ({ setIsCollapsed }) => {
  const { workspace } = useUserSettings();

  return (
    <FlexHeader direction="column" alignItems="center" justifyContent="center" responsive={false}>
      <FlexItem className="no-stroke">
        <Link to={`/repo/${workspace}/home/dataflow`}>
          <TectonLogo data-testid="tecton-logo" />
        </Link>
      </FlexItem>
      <FlexItem>
        <Tooltip
          trigger={
            <CollapsedIconWrapper>
              <ExpandIcon
                onClick={() => {
                  setIsCollapsed && setIsCollapsed(false);
                }}
              />
            </CollapsedIconWrapper>
          }
          content={'Expand Navigation'}
          placement="right"
        />
      </FlexItem>
    </FlexHeader>
  );
};

const TectonSideNavigationHeader: FC<TectonSideNavigationHeaderProps> = ({ isCollapsed, setIsCollapsed }) => {
  const { workspace } = useUserSettings();

  if (isCollapsed) return <SideNavigationHeaderCollapsed isCollapsed setIsCollapsed={setIsCollapsed} />;

  return (
    <FlexHeader alignItems="center" justifyContent="center" responsive={false}>
      <FlexItem className="no-stroke">
        <Link to={`/repo/${workspace}/home/dataflow`}>
          <TectonText className="no-stroke" data-testid="tecton-logo" />
        </Link>
      </FlexItem>

      <FlexItem grow={0}>
        <Tooltip
          trigger={
            <FlexIconWrapper>
              <CollapseIcon
                className="NavigationCollapseIcon"
                onClick={() => {
                  setIsCollapsed && setIsCollapsed(true);
                }}
              />
            </FlexIconWrapper>
          }
          content="Collapse Navigation"
          placement="right"
        />
      </FlexItem>
    </FlexHeader>
  );
};

export default TectonSideNavigationHeader;
