import styled from '@emotion/styled';
import React, { FC } from 'react';

interface SplitPanelLayoutProps {
  left: React.ReactNode;
  right: React.ReactNode;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  height: 100%;
  width: 100%;
`;

const Column = styled.div`
  width: 100%;
  max-height: 100%;
  overflow: scroll;
`;

// ALL LAYOUTS ARE WiP and pending final design
const SplitPanelLayout: FC<SplitPanelLayoutProps> = ({ left, right }) => {
  return (
    <Wrapper data-testid="split-panel-layout">
      <Column>{left}</Column>
      <Column>{right}</Column>
    </Wrapper>
  );
};

export default SplitPanelLayout;
