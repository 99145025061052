import React, { FC } from 'react';
import styled from '@emotion/styled';
import { FlexGroup, FlexItem, Icon, Text } from './';
import { useTectonTheme } from '../../Theme/ThemeProvider';
import { V1Sizes } from '../../Theme/emotion';
import { IconSize } from './Icon';

type TagSize = 'xs' | 's' | 'm';

interface TagProps {
  icon?: boolean;
  label: string;
  category?: string;
  size?: TagSize;
}

const TagContainer = styled.div<{ size: TagSize }>`
  display: inline-block;
  border-radius: ${({ theme }) => `${theme.v1.size['8']}px`};
  border: ${({ theme }) => `1px solid ${theme.v1.colors.border.default}`};
  width: auto;
  max-width: 100%;
  height: ${({ theme, size }) => {
    if (size === 'xs') {
      return '20px';
    }
    if (size === 's') {
      return '24px';
    }

    return '28px';
  }};
  background-color: ${({ theme }) => theme.v1.colors.background.default};
`;

const TruncateText = styled.div<{ size: TagSize }>`
  display: block;
  max-width: ${({ theme }) => `${theme.v1.size['32']}px`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${({ theme, size }) => (size === 'xs' ? theme.v1.font.size.xxs : theme.v1.font.size.xs)};
`;

const Tag: FC<TagProps> = ({ icon, label, category, size = 'm' }) => {
  const { theme } = useTectonTheme();

  const IconSizeMap: Record<TagSize, IconSize> = {
    xs: 'xs',
    s: 'xs',
    m: 'm',
  };

  return (
    <TagContainer size={size} data-testid="tag">
      <FlexGroup
        gapSize={theme.v1.inlineSizing.gap[size] as V1Sizes}
        justifyContent="flex-start"
        alignItems="center"
        direction="row"
        css={{ padding: theme.v1.inlineSizing.padding[size] }}
      >
        {icon && (
          <FlexItem shrink="0" grow="0">
            <FlexGroup justifyContent="center" padding="0">
              <Icon icon={'Tag'} type={'custom'} size={IconSizeMap[size]} />
            </FlexGroup>
          </FlexItem>
        )}
        {category && (
          <FlexItem shrink="1" grow="0">
            <Text size="xs" inline>
              <TruncateText size={size}>{category}: </TruncateText>
            </Text>
          </FlexItem>
        )}
        <FlexItem shrink="1" grow="0">
          <Text size="xs" inline weight="medium">
            <TruncateText size={size}>{label}</TruncateText>
          </Text>
        </FlexItem>
      </FlexGroup>
    </TagContainer>
  );
};

export default Tag;
