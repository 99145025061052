import { clone, every, some } from 'lodash';
import { ComboFilterHierarchyState, ComboFilterHierarchySubcategoryState } from './ComboFilter';

export const checkIfHierarchyHasActiveSelection = (items: ComboFilterHierarchyState) => {
  return countHierarchyActiveSelections(items) > 0;
};

export const countHierarchyActiveSelections = (items: ComboFilterHierarchyState) => {
  return Object.keys(items)
    .map((category) => {
      return Object.values(items[category]);
    })
    .flat()
    .filter((active) => active).length;
};

export const getCheckboxStateForGroup = (subcategories: ComboFilterHierarchySubcategoryState) => {
  if (areAllGroupMembersCurrentlyActive(subcategories)) {
    return 'on';
  }

  if (areAllGroupMembersCurrentlyInactive(subcategories)) {
    return 'off';
  }

  return 'indeterminate';
};

export function updateCategoryToNewState(
  newState: boolean,
  category: string,
  items: ComboFilterHierarchyState,
  setItems: (_: ComboFilterHierarchyState) => void
): void {
  const newItems = clone(items);
  Object.keys(newItems[category]).forEach((subcategory) => {
    newItems[category][subcategory] = newState;
  });

  setItems(newItems);
}

export function setAllCategoriesToNewState(
  newState: boolean,
  items: ComboFilterHierarchyState,
  setItems: (_: ComboFilterHierarchyState) => void
): void {
  const newItems = clone(items);
  Object.keys(newItems).forEach((category) => {
    Object.keys(newItems[category]).forEach((subcategory) => {
      newItems[category][subcategory] = newState;
    });
  });

  setItems(newItems);
}

export const handleHierarchicalGroupClick = (
  category: string,
  items: ComboFilterHierarchyState,
  setItems: (_: ComboFilterHierarchyState) => void
) => {
  if (areAllGroupMembersCurrentlyInactive(items[category])) {
    updateCategoryToNewState(true, category, items, setItems);
    return;
  }

  updateCategoryToNewState(false, category, items, setItems);
  return;
};

export const handleHierarchicalSubcategoryClick = (
  category: string,
  subcategory: string,
  items: ComboFilterHierarchyState,
  setItems: (_: ComboFilterHierarchyState) => void
) => {
  const newItems = clone(items);
  newItems[category][subcategory] = !newItems[category][subcategory];
  setItems(newItems);
};

export const areAllGroupMembersCurrentlyActive = (subcategories: ComboFilterHierarchySubcategoryState) => {
  return every(Object.values(subcategories), (value) => value === true);
};

export const areAllGroupMembersCurrentlyInactive = (subcategories: ComboFilterHierarchySubcategoryState) => {
  return every(Object.values(subcategories), (value) => value === false);
};

export const areAnyCategoryMembersCurrentlyActive = (subcategories: ComboFilterHierarchySubcategoryState) => {
  return some(Object.values(subcategories), (value) => value === true);
};
