import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { ExtendedTableColumn } from './Table';
import { FlexGroup, Icon, Popover, Tooltip, useTectonTheme, Text } from '.';
import { SelectItemProps } from './SelectItem';
import PopoverSelect from './SelectPopover';

const HeaderWrapper = styled.div<{ active: boolean; hasActions: boolean }>`
  width: 100%;
  height: 100%;
  padding: 0;
  height: 32px;

  white-space: nowrap;

  background-color: ${({ theme, active }) =>
    active ? theme.v1.colors.background.activeFocus : theme.v1.colors.background.empty};

  .hover-hint {
    opacity: 0;
  }

  &:hover {
    .hover-hint {
      opacity: 1;
    }

    cursor: ${({ hasActions }) => (hasActions ? 'pointer' : 'default')};
    background-color: ${({ theme, active, hasActions }) => {
      if (!hasActions) return theme.v1.colors.background.empty;
      return active ? theme.v1.colors.background.activeFocus : theme.v1.colors.background.hover;
    }};
  }
`;

export interface TableColumnState {
  name: string;
  visible: boolean;
}

interface TableHeaderProps<T> {
  column: ExtendedTableColumn<T>;
}

const TableHeader = <T,>({ column }: TableHeaderProps<T>) => {
  const { theme } = useTectonTheme();
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isColumnsPopoverOpen, setIsColumnPopoverOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isPopoverOpen === false) {
      setIsColumnPopoverOpen(false);
      setIsFilterOpen(false);
    }
  }, [isPopoverOpen]);

  const availableActions = [
    {
      name: (
        <Text size="xs" weight={column.isSortActive && column.sortDirection === 'ascending' ? 'bold' : 'regular'}>
          Sort By (Ascending)
        </Text>
      ),
      icon: 'ArrowUp',
      show: column.sortCallback !== undefined,
      onClick: () => {
        column.sortCallback && column.sortCallback('ascending');
      },
    },
    {
      name: (
        <Text size="xs" weight={column.isSortActive && column.sortDirection === 'descending' ? 'bold' : 'regular'}>
          Sort By (Descending)
        </Text>
      ),
      icon: 'ArrowDown',
      show: column.sortCallback !== undefined,
      onClick: () => {
        column.sortCallback && column.sortCallback('descending');
      },
    },
    {
      name: 'Hide Column',
      icon: 'EyeHide',
      show: column.hideCallback !== undefined,
      onClick: () => {
        column.hideCallback && column.hideCallback();
      },
    },
    {
      name: (
        <>
          <Popover
            trigger={<>Show Columns</>}
            isOpen={isColumnsPopoverOpen}
            placement="left"
            offsetSize={20}
            setIsOpen={setIsColumnPopoverOpen}
            body={column.columnsContent}
          />
        </>
      ),
      icon: 'Eye',
      show: column.columnsContent !== undefined,
      onClick: () => {
        column.showCallback && column.showCallback();
      },
    },
    {
      name: (
        <Popover
          trigger={<div style={{ width: '100%' }}>Filter</div>}
          isOpen={isFilterOpen}
          placement="left"
          offsetSize={20}
          setIsOpen={setIsFilterOpen}
          body={<>{column.filterContent}</>}
        />
      ),
      icon: 'Filters',
      show: column.filterContent !== undefined,
      onClick: () => {
        setIsFilterOpen(!isFilterOpen);
      },
    },
  ].filter((option) => option.show) as SelectItemProps[];

  const justifyContent = column.align === 'right' ? 'flex-end' : column.align === 'center' ? 'center' : 'flex-start';

  return (
    <HeaderWrapper active={isPopoverOpen} hasActions={availableActions.length > 0} data-testid="table-header">
      {availableActions.length > 0 && (
        <>
          <PopoverSelect
            label={column.name?.toString() || 'Label'}
            isOpen={isPopoverOpen}
            setIsOpen={setIsPopoverOpen}
            placement="bottom"
            offsetSize={2}
            trigger={
              <FlexGroup padding="1" justifyContent={justifyContent} alignItems="center" gapSize="0">
                <Text color={theme.v1.colors.text.text} size="xs">
                  {column.name}
                </Text>
                {column.tooltip && (
                  <Tooltip
                    trigger={
                      <FlexGroup alignItems="center">
                        <Icon size="xs" icon="Information" type="mono" />
                      </FlexGroup>
                    }
                    title={column.name}
                    content={column.tooltip}
                    placement="top"
                  />
                )}
                {!column.isSortActive && (
                  <span className="hover-hint">
                    <FlexGroup alignItems="center">
                      <Icon size="xs" type="mono" icon="ChevronDown" />
                    </FlexGroup>
                  </span>
                )}
                {column.isSortActive && (
                  <Icon size="xs" icon={column.sortDirection === 'ascending' ? 'ArrowUp' : 'ArrowDown'} type="mono" />
                )}
              </FlexGroup>
            }
            groups={[
              {
                title: '',
                items: availableActions,
              },
            ]}
          />
        </>
      )}
      {availableActions.length === 0 && (
        <FlexGroup padding="2" justifyContent={justifyContent} alignItems="center">
          <Text color={theme.v1.colors.text.text} size="xs">
            {column.name}
          </Text>
        </FlexGroup>
      )}
    </HeaderWrapper>
  );
};

export default TableHeader;
