import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTectonTheme } from '../../Theme/ThemeProvider';

export type LoadingSize = 's' | 'm' | 'l' | 'xl' | 'xxl';

interface LoadingProps {
  size?: LoadingSize;
}

const rotate = keyframes`
    from {
        transform: rotate(-45deg);
    }
    to {
        transform: rotate(315deg);
    }
`;

const Track = styled.circle`
  fill: none;
  stroke: ${({ theme }) => theme.v1.colors.border.focus};
  stroke-width: 2;
`;

const RotatingPath = styled.path`
  animation: ${({ theme }) => {
    return css`
      ${rotate} ${theme.v1.motion.duration.xlong} ${theme.v1.motion.easing.linear} infinite
    `;
  }}


  fill: ${({ theme }) => theme.v1.colors.background.primary};
`;

const Loading: FC<LoadingProps> = ({ size = 'm' }) => {
  const { theme } = useTectonTheme();

  const sizeMap: Record<LoadingSize, number> = {
    s: theme.v1.size['3'],
    m: theme.v1.size['4'],
    l: theme.v1.size['6'],
    xl: theme.v1.size['8'],
    xxl: theme.v1.size['10'],
  };

  const fullSize = sizeMap[size];
  const midline = fullSize / 2;

  return (
    <svg viewBox={`0 0 ${fullSize} ${fullSize}`} height={fullSize} width={fullSize} data-testid="loading">
      <g transform={`translate(${midline},${midline})`}>
        <g transform={`scale(${fullSize / 40})`}>
          <Track cx="0" cy="0" r="19" />
          <RotatingPath d="m 18,0 h 2 C 20,-11.0457 11.0457,-20 0,-20 v 2 c 9.9411,0 18,8.0589 18,18 z" />
        </g>
      </g>
    </svg>
  );
};

export default Loading;
