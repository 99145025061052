import { max, min } from 'd3';
import { filter, inRange, intersection, isEqual, some, uniq } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AnyFCO, FCOFields, FCOType } from '../../../core/types/fcoTypes';
import { FCOIconAndName } from '../../@tecton/ComponentRedesign';
import {
  AvatarAndName,
  CategoricalSelectorHierarchicalOption,
  ComboFilterChecklist,
  ComboFilterDateRangeSelector,
  ComboFilterDateRangeState,
  ComboFilterHierarchicalChecklist,
  ComboSelectorChecklistOption,
  checkIfComboFilterChecklistHasActiveCategories,
  checkIfComboFilterDateRangeHasActiveCategories,
  countActiveSelectionsForComboFilterChecklist,
  countActiveSelectionsForComboFilterDateRange,
  countActiveSelectionsForHierarchicalComboFilterChecklist,
} from '../../@tecton/ComponentRedesign/lib';
import { TectonDateTimeFormat } from '../../@tecton/ComponentRedesign/utils';
// icons
import { ServerGroupType } from '../../../types/tecton_proto/common/server_group_type';
import FeatureServerGroupLists, { ServerGroupSelectable } from '../monitoring/FeatureServerGroupLists';

export type FCOFilterType = 'Tags' | 'Last Updated By' | 'Last Updated' | 'Owner' | 'Created';

export interface FCOFilterStateType {
  Tags: CategoricalSelectorHierarchicalOption[];
  'Last Updated': ComboFilterDateRangeState;
  'Last Updated By': ComboSelectorChecklistOption[];
  Owner: ComboSelectorChecklistOption[];
  Created: ComboFilterDateRangeState;
}

const dateRangeFromDates = (dates: Date[]) => {
  const minDate: Date = min(dates) ?? new Date();
  const maxDate: Date = max(dates) ?? new Date();

  return {
    default: {
      start: minDate,
      end: maxDate,
    },
    selected: {
      start: minDate,
      end: maxDate,
    },
  };
};

export const FCOHistoryFilterState = (items: AnyFCO[]) => {
  const owners: ComboSelectorChecklistOption[] = uniq(items.map((item) => item[FCOFields.OWNER] ?? '')).map((owner) => {
    return { label: owner };
  });

  const lastModifiers: ComboSelectorChecklistOption[] = uniq(
    items.map((item) => item[FCOFields.LAST_MODIFIED_BY] ?? '')
  ).map((owner) => {
    return { label: owner };
  });

  const lastUpdatedDates: Date[] = items.map((item) => (item[FCOFields.LAST_MODIFIED_DATE] as Date) ?? undefined);
  const createdDates: Date[] = items.map((item) => (item[FCOFields.LAST_MODIFIED_DATE] as Date) ?? undefined);

  return {
    'Last Updated': dateRangeFromDates(lastUpdatedDates),
    'Last Updated By': lastModifiers,
    Owner: owners,
    Created: dateRangeFromDates(createdDates),
  };
};

export const FCOHistoryFilterTypes = (
  filterState: FCOFilterStateType,
  setFilterState: React.Dispatch<React.SetStateAction<FCOFilterStateType>>
) => {
  return {
    'Last Updated By': {
      label: 'Last Updated By',
      numFilters: countActiveSelectionsForComboFilterChecklist(filterState['Last Updated By']),
      hasActiveFilters: checkIfComboFilterChecklistHasActiveCategories(filterState['Last Updated By']),
      numActiveFilters: countActiveSelectionsForComboFilterChecklist(filterState['Last Updated By']),
      filterElement: (
        <ComboFilterChecklist
          items={filterState['Last Updated By']}
          setItems={(items) => {
            setFilterState({
              ...filterState,
              'Last Updated By': items,
            });
          }}
          renderOption={(owner) => {
            return <AvatarAndName name={owner.label} />;
          }}
        />
      ),
    },
    'Last Updated': {
      label: 'Last Updated',
      numFilters: undefined,
      hasActiveFilters: checkIfComboFilterDateRangeHasActiveCategories(filterState['Last Updated']),
      numActiveFilters: countActiveSelectionsForComboFilterDateRange(filterState['Last Updated']),
      filterElement: (
        <ComboFilterDateRangeSelector
          startDate={filterState['Last Updated'].selected.start}
          endDate={filterState['Last Updated'].selected.end}
          setStartDate={(date) => {
            setFilterState({
              ...filterState,
              'Last Updated': {
                ...filterState['Last Updated'],
                selected: {
                  end: filterState['Last Updated'].selected.end,
                  start: date,
                },
              },
            });
          }}
          setEndDate={(date) => {
            setFilterState({
              ...filterState,
              'Last Updated': {
                ...filterState['Last Updated'],
                selected: {
                  start: filterState['Last Updated'].selected.start,
                  end: date,
                },
              },
            });
          }}
        />
      ),
    },
    Owner: {
      label: 'Owner',
      numFilters: filterState['Owner'].length,
      hasActiveFilters: checkIfComboFilterChecklistHasActiveCategories(filterState['Owner']),
      numActiveFilters: countActiveSelectionsForComboFilterChecklist(filterState['Owner']),
      filterElement: (
        <ComboFilterChecklist
          items={filterState['Owner']}
          setItems={(items) => {
            setFilterState({
              ...filterState,
              Owner: items,
            });
          }}
          renderOption={(owner) => {
            return <AvatarAndName name={owner.label} />;
          }}
        />
      ),
    },
    Created: {
      label: 'Created',
      numFilters: undefined,
      hasActiveFilters: checkIfComboFilterDateRangeHasActiveCategories(filterState['Created']),
      numActiveFilters: countActiveSelectionsForComboFilterDateRange(filterState['Created']),
      filterElement: (
        <ComboFilterDateRangeSelector
          startDate={filterState['Created'].selected.start}
          endDate={filterState['Created'].selected.end}
          setStartDate={(date) => {
            setFilterState({
              ...filterState,
              Created: {
                ...filterState['Created'],
                selected: {
                  start: date,
                  end: filterState['Created'].selected.start,
                },
              },
            });
          }}
          setEndDate={(date) => {
            setFilterState({
              ...filterState,
              Created: {
                ...filterState['Created'],
                selected: {
                  start: filterState['Created'].selected.start,
                  end: date,
                },
              },
            });
          }}
        />
      ),
    },
  };
};

export const FCOHistoryTableColumns = [
  {
    name: <>Last Updated By</>,
    key: 'Last Updated By',
    field: FCOFields.LAST_MODIFIED_BY,
    sortable: true,
    render: (_: unknown, item: AnyFCO) => {
      return <AvatarAndName name={item[FCOFields.LAST_MODIFIED_BY]} />;
    },
  },
  {
    name: <>Last Updated</>,
    key: 'Last Updated',
    field: FCOFields.LAST_MODIFIED_DATE,
    sortable: true,
    render: (_: unknown, item: AnyFCO) => {
      return <>{TectonDateTimeFormat(moment(item[FCOFields.LAST_MODIFIED_DATE]))}</>;
    },
  },
  {
    name: <>Owner</>,
    key: 'Owner',
    sortable: true,
    field: FCOFields.OWNER,
    render: (_: unknown, item: AnyFCO) => {
      return (
        <>
          {item[FCOFields.OWNER] === 'n/a' ? (
            <AvatarAndName icon={'NotSet'} name="Not Set" type="icon" />
          ) : (
            <AvatarAndName name={item[FCOFields.OWNER]} />
          )}
        </>
      );
    },
  },
  {
    name: <>Created</>,
    key: 'Created',
    sortable: true,
    field: FCOFields.CREATED_DATE,
    render: (_: unknown, item: AnyFCO) => {
      return <>{TectonDateTimeFormat(moment(item[FCOFields.CREATED_DATE]))}</>;
    },
  },
];

export const SimpleChecklistFilterLogic = <FilterType, FCOType>(
  filterState: FilterType,
  key: keyof FilterType,
  fcoProp: keyof FCOType
) => {
  return (fco: FCOType) => {
    const filterValues = (filterState[key] as [])
      .filter((option: { checked: string }) => option.checked === 'on')
      .map((option: { value: unknown }) => option.value)
      .flat();

    return filterValues.includes(fco[fcoProp]);
  };
};

export const RelatedFCOFilterLogic = <FilterType, FCOType>(
  filterState: FilterType,
  key: keyof FilterType,
  fcoProp: keyof FCOType
) => {
  return (fco: FCOType) => {
    return (
      intersection(
        fco[fcoProp] as unknown[],
        (filterState[key] as { checked: string; value: unknown }[])
          .filter((featureView: { checked: string }) => featureView.checked === 'on')
          .map((option: { value: unknown }) => option.value)
      ).length > 0
    );
  };
};

export const ServerGroupFilterLogic = <FilterType, FCOType>(
  filterState: FilterType,
  key: keyof FilterType,
  fcoProp: keyof FCOType
) => {
  return (fco: FCOType) => {
    // FCO row data and getting the 'serverGroups' property from FCO.
    const fcoServerGroupIds = (fco[fcoProp] as { type: string; id: string }[]).map((fcoSg) => fcoSg.id);

    const filterStateOfServerGroupIds = (filterState[key] as ServerGroupSelectable[])
      .filter((item) => {
        // Only take the checked one from the filters
        return item.checked && fcoServerGroupIds.includes(item.serverGroupId ?? '');
      })
      .map((option) => {
        return option.serverGroupId;
      });

    return intersection(fcoServerGroupIds, filterStateOfServerGroupIds).length > 0;
  };
};

export const RelatedFCOCountFilterLogic = <FilterType, FCOType>(
  filterState: FilterType,
  key: keyof FilterType,
  fcoProp: keyof FCOType
) => {
  return (fco: FCOType) => {
    const entityCount = (fco[fcoProp] as unknown[]).length;
    const filterValues = (filterState[key] as { checked: string; value: unknown }[])
      .filter((option: { checked: string }) => option.checked === 'on')
      .map((option: { value: unknown }) => option.value)
      .flat();

    if (filterValues.includes(entityCount)) {
      return true;
    }

    if (filterValues.includes(11) && entityCount > 10) {
      return true;
    }
    return false;
  };
};

export const TagFilterLogic = (tags: CategoricalSelectorHierarchicalOption[]) => {
  return (fco: AnyFCO) => {
    const onlyValueTags = tags
      .filter((tag) => tag.parent)
      .filter((tag) => tag.checked === 'on')
      .map((match) => {
        return { key: match.parent, value: match.label };
      });

    return (
      filter(fco[FCOFields.TAGS], (fcoTag) => some(onlyValueTags, (valueTag) => isEqual(fcoTag, valueTag))).length > 0
    );
  };
};

export const SharedFCOHistoryFilterLogic = (filterState: FCOFilterStateType) => {
  return {
    'Last Updated By': (fco: AnyFCO) => {
      return filterState['Last Updated By']
        .filter((option: ComboSelectorChecklistOption) => option.checked === 'on')
        .map((option: ComboSelectorChecklistOption) => option.label)
        .includes(fco[FCOFields.LAST_MODIFIED_BY] ?? '');
    },
    'Last Updated': (fco: AnyFCO) => {
      if (!fco[FCOFields.LAST_MODIFIED_DATE]) {
        return false;
      }
      return inRange(
        fco[FCOFields.LAST_MODIFIED_DATE].getTime(),
        filterState['Last Updated'].selected.start.getTime(),
        filterState['Last Updated'].selected.end.getTime()
      );
    },
    Owner: (fco: AnyFCO) => {
      return filterState['Owner']
        .filter((option: ComboSelectorChecklistOption) => option.checked === 'on')
        .map((option: ComboSelectorChecklistOption) => option.label)
        .includes(fco[FCOFields.OWNER] ?? '');
    },
    Created: (fco: AnyFCO) => {
      if (!fco[FCOFields.CREATED_DATE]) {
        return false;
      }
      return inRange(
        fco[FCOFields.CREATED_DATE].getTime(),
        filterState['Created'].selected.start.getTime(),
        filterState['Created'].selected.end.getTime()
      );
    },
  };
};

export const TagsFilterType = (
  filterState: FCOFilterStateType,
  setFilterState: React.Dispatch<React.SetStateAction<FCOFilterStateType>>
) => {
  return {
    hidden: undefined,
    label: 'Tags',
    numFilters: undefined,
    hasActiveFilters: checkIfComboFilterChecklistHasActiveCategories(filterState['Tags']),
    numActiveFilters: countActiveSelectionsForHierarchicalComboFilterChecklist(filterState['Tags']),
    filterElement: (
      <ComboFilterHierarchicalChecklist
        options={filterState['Tags']}
        onChange={(items) => {
          setFilterState({
            ...filterState,
            Tags: items,
          });
        }}
      />
    ),
  };
};

export const SimpleChecklistFilterType = <FilterState,>(
  filterState: FilterState,
  setFilterState: React.Dispatch<React.SetStateAction<FilterState>>,
  key: keyof FilterState,
  renderOption?: (item: unknown) => React.ReactNode
) => {
  return {
    label: key,
    numFilters: countActiveSelectionsForComboFilterChecklist(filterState[key] as ComboSelectorChecklistOption[]),
    hasActiveFilters: checkIfComboFilterChecklistHasActiveCategories(
      filterState[key] as ComboSelectorChecklistOption[]
    ),
    numActiveFilters: countActiveSelectionsForComboFilterChecklist(filterState[key] as ComboSelectorChecklistOption[]),
    filterElement: (
      <ComboFilterChecklist
        items={filterState[key] as ComboSelectorChecklistOption[]}
        setItems={(items) => {
          setFilterState({
            ...filterState,
            [key]: items,
          });
        }}
        renderOption={renderOption}
      />
    ),
  };
};

export const RelatedFCOFilterType = <FilterState,>(
  filterState: FilterState,
  setFilterState: React.Dispatch<React.SetStateAction<FilterState>>,
  key: keyof FilterState,
  fcoType: FCOType
) => {
  return {
    label: key,
    numFilters: 0,
    hasActiveFilters: checkIfComboFilterChecklistHasActiveCategories(
      filterState[key] as ComboSelectorChecklistOption[]
    ),
    numActiveFilters: countActiveSelectionsForComboFilterChecklist(filterState[key] as ComboSelectorChecklistOption[]),
    filterElement: (
      <ComboFilterChecklist
        items={filterState[key] as ComboSelectorChecklistOption[]}
        setItems={(items) => {
          setFilterState({
            ...filterState,
            [key]: items,
          });
        }}
        renderOption={(item) => {
          return (
            <div style={{ pointerEvents: 'none' }}>
              <FCOIconAndName name={item.label} type={fcoType} />
            </div>
          );
        }}
      />
    ),
  };
};

export const ServerGroupFilterType = <FilterState,>(
  filterState: FilterState,
  setFilterState: React.Dispatch<React.SetStateAction<FilterState>>,
  key: keyof FilterState,
  hidden: boolean
) => {
  const [selectedServerGroup, setSelectedServerGroup] = useState<ServerGroupSelectable | undefined>();
  // server group from from filter state (FeatureServiceStateType)
  const serverGroups = filterState[key] as ServerGroupSelectable[];

  useEffect(() => {
    if (serverGroups.every((sg) => sg.checked === false)) {
      setSelectedServerGroup(undefined);
    }
  }, [serverGroups]);

  const onServerGroupSelected = (serverGroup?: ServerGroupSelectable) => {
    setSelectedServerGroup(serverGroup);
    const serverGroupsUpdate = serverGroups.map((sg) => {
      return sg.serverGroupId === serverGroup?.serverGroupId ? { ...sg, checked: true } : { ...sg, checked: false };
    });

    setFilterState({
      ...filterState,
      [key]: serverGroupsUpdate,
    });
  };

  return {
    hidden, // hide filter when flag is not set.
    label: key,
    numFilters: 0,
    hasActiveFilters: !!selectedServerGroup?.serverGroupId,
    numActiveFilters: selectedServerGroup ? 1 : 0,
    filterElement: (
      <FeatureServerGroupLists
        featureServerGroups={
          serverGroups?.filter(
            (serverGroup) => serverGroup.type === ServerGroupType.SERVER_GROUP_TYPE_FEATURE_SERVER_GROUP
          ) ?? []
        }
        transformationServerGroups={
          serverGroups?.filter(
            (serverGroup) => serverGroup.type === ServerGroupType.SERVER_GROUP_TYPE_TRANSFORM_SERVER_GROUP
          ) ?? []
        }
        selectedServerGroup={selectedServerGroup}
        onServerGroupSelected={onServerGroupSelected}
      />
    ),
  };
};

export const RelatedFCOCountFilterType = <FilterState,>(
  filterState: FilterState,
  setFilterState: React.Dispatch<React.SetStateAction<FilterState>>,
  key: keyof FilterState
) => {
  return {
    label: key,
    numFilters: undefined,
    hasActiveFilters: checkIfComboFilterChecklistHasActiveCategories(
      filterState[key] as ComboSelectorChecklistOption[]
    ),
    numActiveFilters: countActiveSelectionsForComboFilterChecklist(filterState[key] as ComboSelectorChecklistOption[]),
    filterElement: (
      <ComboFilterChecklist
        items={filterState[key] as ComboSelectorChecklistOption[]}
        setItems={(items) => {
          setFilterState({
            ...filterState,
            [key]: items,
          });
        }}
      />
    ),
  };
};
