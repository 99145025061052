import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { FlexGroup, NavigationSizeContext, NavigationSizeSetting } from '.';

interface ApplicationLayoutProps {
  banner: React.ReactNode;
  navigation: React.ReactNode;
  content: React.ReactNode;
}

const ApplicationWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.v1.colors.background.default};
  margin: 0;
  padding: 0;
`;

const BannerRow = styled.div`
  width: 100%;
  flex-shrink: 0; /* Prevent shrinking */
`;

const PrimaryApplicationLayout = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const NavigationColumn = styled.div<{ size: NavigationSizeSetting }>`
  flex-shrink: 0;

  user-select: none;
  background-color: ${({ theme }) => theme.v1.colors.background.default};

  @media (max-width: ${({ theme }) => theme.v1.viewports.narrow}px) {
    width: ${({ theme, size }) => (size === 'forceExpanded' ? theme.v1.size['64'] : theme.v1.size['12'])}px;
  }

  @media (min-width: ${({ theme }) => theme.v1.viewports.narrow + 1}px) {
    width: ${({ theme, size }) => (size === 'forceCompressed' ? theme.v1.size['12'] : theme.v1.size['64'])}px;
  }
`;

const MainContent = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ApplicationLayout: FC<ApplicationLayoutProps> = ({ banner, navigation, content }) => {
  const [size, setSize] = useState<NavigationSizeSetting>('auto');

  return (
    <NavigationSizeContext.Provider value={{ size, setSize }}>
      <ApplicationWrapper>
        <FlexGroup direction="column" padding="0" gapSize="0">
          <BannerRow id="tecton-layout-banner">{banner}</BannerRow>
          <PrimaryApplicationLayout>
            <NavigationColumn size={size}>{navigation}</NavigationColumn>
            <MainContent>{content}</MainContent>
          </PrimaryApplicationLayout>
        </FlexGroup>
      </ApplicationWrapper>
    </NavigationSizeContext.Provider>
  );
};

export default ApplicationLayout;
