import styled from '@emotion/styled';
import React, { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import TableRowExpansion from './TableRowExpansion';
import TableHeader from './TableHeader';
import { Empty, useTectonTheme } from '.';
import { EmptyProps } from './Empty';

export interface TableSortType<T> {
  predicate?: (items: T[], direction: 'ascending' | 'descending') => T[];
  direction: 'ascending' | 'descending';
  sortId: keyof T;
}

type TableColumnWidth = 'xs' | 's' | 'm' | 'l' | 'xl';

export interface ExtendedTableColumn<T> extends TableColumn<T> {
  name: string | React.ReactNode;
  sortId?: keyof T;
  tooltip?: string;
  isSortActive?: boolean;
  sortDirection?: 'ascending' | 'descending';
  align?: 'left' | 'center' | 'right';
  sortPredicate?: (items: T[], direction: 'ascending' | 'descending') => T[];
  sortCallback?: (direction: 'ascending' | 'descending') => void;
  hideCallback?: () => void;
  showCallback?: () => void;
  columnsContent?: React.ReactNode;
  filterContent?: React.ReactNode;
  content: (_: T) => React.ReactNode;
  isActive?: boolean;
  setIsActive?: (value: boolean) => void;
  columnWidth?: TableColumnWidth;
}

interface TableProps<T extends { id: string }> {
  items: T[];
  columns: ExtendedTableColumn<T>[];
  rowExpansion?: (_: T) => React.ReactNode;
  frozenFirstColumn?: boolean;
  compressed?: boolean;
  emptyProps?: EmptyProps;
}

const TableWrapper = styled.div<{ frozenFirstColumn?: boolean; compressed?: boolean; expands?: boolean }>`
  user-select: none;
  oveflow: hidden;

  .rdt_TableHead {
    z-index: 5;
  }

  .rdt_TableHeadRow {
    background-color: ${({ theme }) => theme.v1.colors.background.empty};
    position: sticky;
    height: auto;
    border-top: 1px solid ${({ theme }) => theme.v1.colors.border.default};
    min-height: 0px;
    z-index: 3;
  }

  .rdt_TableCol {
    padding: 0;
    z-index: 1;
    white-space: nowrap;
  }

  .rdt_TableCol:first-of-type {
    position: ${({ frozenFirstColumn }) => (frozenFirstColumn ? 'sticky' : 'relative')};
    left: 0;
    background-color: ${({ theme }) => theme.v1.colors.background.default};
    z-index: 2;
  }

  .rdt_TableCell:first-of-type {
    position: ${({ frozenFirstColumn }) => (frozenFirstColumn ? 'sticky' : 'relative')};
    left: 0;
    background-color: ${({ theme }) => theme.v1.colors.background.empty};
    z-index: 1;
  }

  .rdt_TableRow {
    z-index: 1;

    cursor: ${({ expands }) => (expands ? 'pointer' : 'default')};

    background-color: ${({ theme }) => theme.v1.colors.background.empty};
    &:hover {
      background-color: ${({ theme }) => theme.v1.colors.background.hover};

      .rdt_TableCell:first-of-type {
        background-color: ${({ theme }) => theme.v1.colors.background.hover};
      }
    }

    min-height: ${({ theme, compressed }) => (compressed ? '0' : theme.v1.size['12'])}px;
  }

  .rdt_TableCell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: ${({ theme }) => theme.v1.colors.text.text};
    padding: ${({ theme, compressed }) => (compressed ? theme.v1.size['0.5'] : theme.v1.size['2'])}px
      ${({ theme, compressed }) => (compressed ? theme.v1.size['0.5'] : theme.v1.size['2'])}px;

    border-bottom: 1px solid ${({ theme }) => theme.v1.colors.border.default};
    pointer-events: auto;
  }
`;

const CellWrapper = styled.div`
  width: 100%;
  white-space: nowrap;
`;

const Table = <T extends { id: string }>({
  items,
  columns,
  rowExpansion,
  compressed,
  frozenFirstColumn,
  emptyProps,
}: TableProps<T>) => {
  const [currentRowChange, setCurrentRowChange] = useState<string | undefined>(undefined);
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const { theme } = useTectonTheme();

  const handleRowClicked = (row: T) => {
    setCurrentRowChange(row.id);
    const key = row.id;
    const newExpandedRows = new Set(expandedRows);

    if (newExpandedRows.has(key)) {
      newExpandedRows.delete(key); // Collapse the row
    } else {
      newExpandedRows.add(key); // Expand the row
    }
    setExpandedRows(newExpandedRows);
  };

  const isRowExpanded: (row: T) => boolean = (row: T) => {
    return expandedRows.has(row.id);
  };

  const columnWidthMap: Record<TableColumnWidth, string> = {
    s: `${theme.v1.size['24']}px`,
    xs: `${theme.v1.size['40']}px`,
    m: `${theme.v1.size['48']}px`,
    l: `${theme.v1.size['64']}px`,
    xl: `${theme.v1.size['96']}px`,
  };

  const transformedColumns = columns.map((column, index) => {
    return {
      ...column,
      name: <TableHeader column={column} />,
      id: 'column_' + index,
      width: column.columnWidth ? columnWidthMap[column.columnWidth] : 'm',
      cell: (row: T) => <CellWrapper onClick={() => handleRowClicked(row)}>{column.content(row)}</CellWrapper>,
    };
  });

  return (
    <>
      <TableWrapper
        compressed={compressed}
        frozenFirstColumn={frozenFirstColumn}
        expands={rowExpansion !== undefined}
        data-testid="table-wrapper"
      >
        <DataTable
          data-testid="table"
          noDataComponent={<>{emptyProps && <Empty {...emptyProps} />}</>}
          data={items}
          responsive={false}
          columns={transformedColumns}
          fixedHeader={true}
          expandableRows={rowExpansion !== undefined}
          expandableRowsHideExpander={true}
          expandableRowsComponent={(row) => {
            if (rowExpansion === undefined) return <></>;

            return (
              <TableRowExpansion
                data={row.data}
                content={rowExpansion!}
                id={row.data.id}
                isOpening={currentRowChange === row.data.id}
                isClosing={!isRowExpanded(row.data)}
              />
            );
          }}
          onRowClicked={rowExpansion && handleRowClicked}
          expandableRowExpanded={isRowExpanded}
          keyField="id"
          onRowMouseEnter={(row, event) => {}}
          onRowMouseLeave={() => {}}
        />
      </TableWrapper>
    </>
  );
};

export default Table;
