import styled from '@emotion/styled';
import { AttributesList, Panel } from '@tecton';
import { EnabledDisabledBadge } from '@tecton/ComponentRedesign';
import React from 'react';
import { DataFlowNode } from '../dataFlowTypes';

const HoverContentWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Panel paddingSize="none" hasBorder={false}>
      <>{children}</>
    </Panel>
  );
};

const HoverDetail = styled.div`
  color: #999;
`;

const HoverContent = ({ node }: { node: DataFlowNode }) => {
  switch (node.type) {
    case 'embeddingModel':
      return (
        <>
          {node?.inputColumns && (
            <HoverContentWrapper>
              <HoverDetail>Input: {node?.inputColumns}</HoverDetail>
            </HoverContentWrapper>
          )}
        </>
      );

    case 'feature_view':
      return (
        <HoverContentWrapper>
          {(node?.modelCount ?? 0) > 0 && (
            <HoverDetail>
              {node.modelCount} {(node?.modelCount ?? 0) > 1 ? 'Models' : 'Model'}
            </HoverDetail>
          )}
          {(node?.featuresCount ?? 0) > 0 && (
            <HoverDetail>
              {node.featuresCount} {(node?.featuresCount ?? 0) > 1 ? 'Features' : 'Feature'}
            </HoverDetail>
          )}
        </HoverContentWrapper>
      );

    case 'feature_service':
      return (
        <HoverContentWrapper>
          <AttributesList
            compressed
            listItems={[
              {
                title: 'Online Serving',
                description: <EnabledDisabledBadge enabled={node.isOnlineServingEnabled ?? false} />,
              },
            ]}
          />
        </HoverContentWrapper>
      );

    default:
      return <></>;
  }
};

export default HoverContent;
