import moment from 'moment-timezone';
import React, { FC, useState } from 'react';
import {
  FCOFields,
  FeatureServiceFCO,
  FeatureServiceFCOFields,
  FeatureViewFCO,
  FeatureViewFCOFields,
  OnlineStoreType,
} from '../../../core/types/fcoTypes';
import { CustomGraph } from '../../../feature/feature-views/graph-types';
import {
  FEATURE_SERVICE_GRAPHS,
  FEATURE_SERVICE_GRAPHS_FOR_ODFV_ONLY,
  FILTERABLE_PANEL_LABEL,
} from '../../../feature/graph-configuration';
import { useUserSettings } from '../../context/UserSettingsContext';
import {
  MonitoringDateRange,
  calculateStep,
  calculateUnit,
  getDefaultMonitoringDateRange,
  getMonitoringDateRangeByTimeComboOption,
} from '../../monitoring/DateRange-Dropdown-util';
import MonitoringGraphs from '../monitoring/MonitoringGraphs';
import { TitleText } from '../monitoring/StyledComponents';
import OnlineStoreLatency from './OnlineStoreLatency';
import ReliabilitySLO30Days from './ReliabilitySLO30Days';

// @tecton
import { CardGridLayout, FlexGroupWrapper, FlexItem, FullWidthCard } from '@tecton/ComponentRedesign';
import TectonTimeComboSelector, {
  TimeComboDateRange,
  TimeComboOptionType,
} from '@tecton/ComponentRedesign/lib/TimeComboSelector';
import { filterMonitoringTimeComboOptionCallback } from '../monitoring/monitoring-utils';

interface FeatureServiceDetailMonitoringProps {
  featureService: FeatureServiceFCO;
  featureViews: FeatureViewFCO[];
}

// This is in the Feature View Details page -> monitoring tab.
const FeatureServiceDetailMonitoring: FC<FeatureServiceDetailMonitoringProps> = ({ featureService, featureViews }) => {
  const { workspace } = useUserSettings();
  const defaultMonitoringDateRange: MonitoringDateRange = getDefaultMonitoringDateRange();
  const [monitoringDateRange, setMonitoringDateRange] = useState<MonitoringDateRange>(defaultMonitoringDateRange);
  const [selectedTimeComboOption, setSelectedTimeComboOption] = useState<TimeComboOptionType>('1Day');
  const [dateRange, setDateRange] = useState<TimeComboDateRange>({
    startDate: moment().local().subtract(24, `hours`),
    endDate: moment().local(),
  });

  const relatedFvs = featureViews.filter((fv) => {
    return featureService[FeatureServiceFCOFields.ALL_FEATURE_VIEWS].includes(fv[FCOFields.ID]);
  });

  const hasDynamoStoreType = relatedFvs
    .map((fv) => {
      return fv[FeatureViewFCOFields.ONLINE_STORE_TYPE];
    })
    .includes(OnlineStoreType.DYNAMO);

  const filteredGraphData = FEATURE_SERVICE_GRAPHS.filter((graphRowData) => {
    // Hide get feature-bach-graph if it's Dynamo DB
    const filterForRedis = [FILTERABLE_PANEL_LABEL.GET_FEATURES_BATCH.toString()];
    const filterForDynamoDB = [FILTERABLE_PANEL_LABEL.FEATURE_SERVICE_DYNAMO_GRAPHS.toString()];

    // Remove FEATURE_SERVICE_DYNAMO_GRAPHS graphs for redesign since we are rendering it as custom
    if (graphRowData.panelLabel === FILTERABLE_PANEL_LABEL.FEATURE_SERVICE_DYNAMO_GRAPHS) {
      return false;
    }

    if (filterForRedis.includes(graphRowData.panelLabel)) {
      const hasRedis = relatedFvs
        .map((fv) => {
          return fv[FeatureViewFCOFields.ONLINE_STORE_TYPE];
        })
        .includes(OnlineStoreType.REDIS);

      // Hide get feature bach graph if it's Dynamo DB
      return hasRedis;
    }

    // Show dynamoDB if FS has it.
    if (filterForDynamoDB.includes(graphRowData.panelLabel)) {
      const hasDynamoStoreType = relatedFvs
        .map((fv) => {
          return fv[FeatureViewFCOFields.ONLINE_STORE_TYPE];
        })
        .includes(OnlineStoreType.DYNAMO);

      // Show get Dynamo DB graphs if some FV has it
      return hasDynamoStoreType;
    }

    if (graphRowData.panelLabel === FILTERABLE_PANEL_LABEL.TECTON_SERVING_CACHE) {
      return featureService[FeatureServiceFCOFields.IS_CACHING_ENABLED];
    }

    return true;
  });

  const customGraphRecord: Record<string, React.ReactNode> = {
    [CustomGraph.FEATURE_SERVICE_GLOBAL_SLO_RELIABILITY]: (
      <FlexItem grow={2}>
        {
          <ReliabilitySLO30Days
            featureServiceId={featureService[FCOFields.ID]}
            monitoringDateRange={monitoringDateRange}
          />
        }
      </FlexItem>
    ),
  };

  return (
    <>
      <CardGridLayout>
        <FullWidthCard>
          <TectonTimeComboSelector
            limitMinDateInMonths={2}
            filterOptions={filterMonitoringTimeComboOptionCallback}
            dateRange={dateRange}
            setDateRange={setDateRange}
            initialOption={selectedTimeComboOption}
            onCustomDateSelection={(startDate, endDate) => {
              const startTime = () => startDate.valueOf();
              const endTime = () => endDate.valueOf();
              if (endDate) {
                const step = calculateStep(startDate, endDate);
                const unit = calculateUnit(startDate, endDate);

                const customDateRange: MonitoringDateRange = {
                  startTime: startTime,
                  endTime: endTime,
                  requestId: `${startTime()}${endTime()}`,
                  step: step,
                  unit: unit,
                };

                setMonitoringDateRange(customDateRange);
                setSelectedTimeComboOption('custom');
              }
            }}
            onComboSelection={(timeComboOption) => {
              setSelectedTimeComboOption(timeComboOption);
              const monitoringDateRangeOption = getMonitoringDateRangeByTimeComboOption(timeComboOption);
              setMonitoringDateRange(monitoringDateRangeOption);
            }}
          />
        </FullWidthCard>
      </CardGridLayout>
      <FlexGroupWrapper direction="column" gap="xl">
        <FlexItem>
          <FlexGroupWrapper direction="column" gap="xxl">
            <FlexItem grow={false}>
              <MonitoringGraphs
                monitoringDateRange={monitoringDateRange}
                graphsData={filteredGraphData}
                customGraphRecord={customGraphRecord}
                workspace={workspace ?? ''}
                fcoId={featureService[FCOFields.ID] ?? ''}
              />
            </FlexItem>
            {hasDynamoStoreType && (
              <>
                <FlexItem grow={false}>
                  <FlexGroupWrapper gap={'m'}>
                    <FlexItem>
                      <TitleText>Online Store Latency</TitleText>
                    </FlexItem>
                    <FlexItem>
                      <OnlineStoreLatency relatedFvs={relatedFvs} monitoringDateRange={monitoringDateRange} />
                    </FlexItem>
                  </FlexGroupWrapper>
                </FlexItem>
              </>
            )}
            <FlexItem grow={false}>
              <MonitoringGraphs
                monitoringDateRange={monitoringDateRange}
                graphsData={FEATURE_SERVICE_GRAPHS_FOR_ODFV_ONLY}
                customGraphRecord={customGraphRecord}
                workspace={workspace ?? ''}
                fcoId={featureService[FCOFields.ID] ?? ''}
              />
            </FlexItem>
          </FlexGroupWrapper>
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default FeatureServiceDetailMonitoring;
