/* eslint-disable jsx-a11y/no-autofocus */
import React, { ChangeEventHandler, FC } from 'react';
import styled from '@emotion/styled';
import { EuiFieldText } from '@elastic/eui';
import { FormRow } from '@tecton/ComponentRedesign';

type FormInputVariant = 'text' | 'number' | 'password';

interface FieldTextProps {
  error?: boolean;
  filled?: boolean;
  disabled?: boolean;
  label?: React.ReactNode;
  message?: React.ReactNode;
  errorText?: React.ReactNode;
  variant?: FormInputVariant;
  placeholder?: string;
  value?: number | string | undefined;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  fullWidth?: boolean;
  compressed?: boolean;
  testId?: string;
}

const StyledFieldText = styled(EuiFieldText)<{ error: boolean; filled: boolean }>`
    border-radius: ${({ theme }) => theme.border.radius.medium};
    font-size: ${({ theme }) => theme.font.fontSizes.s};
    padding: ${({ theme }) => theme.padding.xs} ${({ theme }) => theme.padding.m};
    margin: ${({ theme }) => theme.padding.xs} 0px;
    border: ${({ theme, error }) => (error ? theme.colors.danger : theme.border.thin)};

    color: ${({ theme, filled }) => (filled ? theme.colors.text : theme.colors.subduedText)}};
    font-weight: ${({ theme, filled }) => (filled ? theme.font.weight.medium : theme.font.weight.regular)};

    ::placeholder {
      color: ${({ theme }) => theme.colors.subduedText};
    }


    .euiFieldText {

      line-height:${({ theme }) => theme.font.lineHeights.s};
      color: ${({ theme }) => theme.colors.title};
      :focus {

        border: ${({ theme }) => theme.border.thin};
        outline: none;

        transition: none;
        box-shadow:none;
        background-image:none;
        background-size:none; background-color:none;

      }
    }
  `;

const FormInput: FC<FieldTextProps> = ({
  error,
  filled,
  disabled,
  label,
  message,
  errorText,
  variant = 'text',
  placeholder,
  value,
  onChange = () => {
    return;
  },
  autoFocus,
  onFocus,
  onBlur,
  fullWidth,
  compressed,
  testId,
}) => {
  return (
    <FormRow label={label} error={!!error} errorText={errorText} message={message} fullWidth={fullWidth}>
      <StyledFieldText
        error={!!error}
        filled={!!filled}
        disabled={disabled}
        placeholder={placeholder}
        type={variant}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        onFocus={onFocus}
        onBlur={onBlur}
        data-testid={testId || 'form-input'}
        fullWidth={fullWidth}
        compressed={compressed}
      />
    </FormRow>
  );
};

export default FormInput;
