import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { Routes } from '../../../core/routes';
import AccountTypeIconName from './AccountTypeIconName';
import { SimpleGroup } from './PrincipalDetailsAccountType';

import {
  ButtonEmpty,
  ButtonIcon,
  FlexGrid,
  FlexGroup,
  FlexGroupWrapper,
  FlexItem,
  Popover,
} from '@tecton/ComponentRedesign';

// @svg
import GroupIcon from '@svg/group.svg';

interface PopoverGroupAppliedDetailsProps {
  groups: SimpleGroup[];
  accountType: string;
  onRelatedStatusClicked?: VoidFunction;
}

const PopoverAccountTypeDetails: FC<PopoverGroupAppliedDetailsProps> = ({
  groups,
  accountType,
  onRelatedStatusClicked,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const assignmentSourcesRow = groups.map((assignmentSource, index) => {
    return (
      <>
        <FlexGroup justifyContent="spaceBetween" gutterSize="s" alignItems="center" key={index}>
          <FlexItem grow={false}>
            <ButtonEmpty
              iconType={GroupIcon}
              onClick={() => {
                navigate(`${Routes.accountsAndAccessGroups}/${assignmentSource.groupId}`);
              }}
            >
              {assignmentSource.groupName}
            </ButtonEmpty>
          </FlexItem>
        </FlexGroup>
      </>
    );
  });

  const popoverContent = assignmentSourcesRow?.map((item, key) => {
    return (
      <FlexGrid columns={1} gutterSize="s" key={key}>
        <FlexItem grow>{item}</FlexItem>
      </FlexGrid>
    );
  });

  if (groups.length === 0) {
    <></>;
  }

  return (
    <>
      <FlexGroupWrapper gap="xs" direction="row" alignItems="center">
        <FlexItem>
          <AccountTypeIconName
            accountType={accountType}
            onAccountTypeRowClicked={() => {
              onRelatedStatusClicked?.call(this);
            }}
          />
        </FlexItem>
        <FlexItem>
          {popoverContent.length > 0 && (
            <Popover
              placement="bottom"
              data-testid="applied-details-items-popover"
              title={'Account Type applied via group'}
              trigger={
                <>
                  {popoverContent.length > 0 && (
                    <FlexItem
                      onClick={(event) => {
                        event.stopPropagation();
                        setIsOpen(!isOpen);
                      }}
                    >
                      <ButtonIcon iconType={GroupIcon} size="xs" />
                    </FlexItem>
                  )}
                </>
              }
              isOpen={isOpen}
              body={<>{popoverContent}</>}
              setIsOpen={() => {
                setIsOpen(!isOpen);
              }}
            />
          )}
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default PopoverAccountTypeDetails;
