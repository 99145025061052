import styled, { CSSObject } from '@emotion/styled';
import React, { FC } from 'react';
import { V1Sizes } from '../../Theme/emotion';

type FlexDirection = React.CSSProperties['flexDirection'];
type AlignItems = React.CSSProperties['alignItems'];
type JustifyContent = React.CSSProperties['justifyContent'];

interface FlexGroupProps {
  children: React.ReactNode;
  direction?: FlexDirection;
  gapSize?: V1Sizes;
  padding?: V1Sizes;
  alignItems?: AlignItems;
  justifyContent?: JustifyContent;
  css?: CSSObject;
}

const Wrapper = styled.div<FlexGroupProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  gap: ${({ theme, gapSize }) => `${theme.v1.size[gapSize as V1Sizes]}px`};
  width: 100%;
  height: 100%;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  padding: ${({ theme, padding }) => `${theme.v1.size[padding as V1Sizes]}px`};
  ${({ css }) => css};
`;

const FlexGroup: FC<FlexGroupProps> = ({
  children,
  direction = 'row',
  gapSize = '1',
  padding = '1',
  alignItems = 'stretch',
  justifyContent = 'flex-start',
  css = {},
}) => {
  return (
    <Wrapper
      direction={direction}
      gapSize={gapSize}
      padding={padding}
      alignItems={alignItems}
      justifyContent={justifyContent}
      css={css}
    >
      {children}
    </Wrapper>
  );
};

export default FlexGroup;
