import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

const expandAnimation = keyframes`
  from {
    transform: scale(1,0) translateY(-100%);
  }

  to {
    transform: scale(1,1) translateY(0%);
  }
`;

const collapseAnimation = keyframes`
  from {
    transform: scale(1,1);
  }

  to {
    transform: scale(1,0);
  }
`;

interface TableRowExpansionProps<T extends { id: string }> {
  id: string;
  data: T;
  content: (_: T) => React.ReactNode;
  isOpening: boolean;
  isClosing: boolean;
}

const ExpandWrapper = styled.div<{ animate: boolean; isClosing: boolean }>`
  overflow: hidden;

  animation: ${({ theme, animate, isClosing }) => {
    if (isClosing) {
      return css`
        ${collapseAnimation} ${theme.v1.motion.duration.xfast} ${theme.v1.motion.easing.exit} forwards
      `;
    }
    if (animate) {
      return css`
        ${expandAnimation} ${theme.v1.motion.duration.fast} ${theme.v1.motion.easing.enter} forwards
      `;
    }
  }};
`;

const TableRowExpansion = <T extends { id: string }>({
  data,
  content,
  isOpening,
  isClosing,
}: TableRowExpansionProps<T>) => {
  return (
    <ExpandWrapper animate={isOpening} isClosing={isClosing} data-testid="table-row-expansion">
      {content(data)}
    </ExpandWrapper>
  );
};

export default TableRowExpansion;
